export const validateEmail = (email: string) => {
  const emailRule = new RegExp(
    "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,})+$"
  );

  const isValid = emailRule.test(email);

  return isValid;
};

export const validatePassword = (newPassword: string) => {
  const upperAndLowercaseRule = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).*$").test(
    newPassword
  )
    ? 1
    : 0;

  const lengthRule = new RegExp(".{8,}").test(newPassword) ? 1 : 0;

  const numberAndSymbolRule = new RegExp("(?=.*\\d)(?=.*[\\W_]).+").test(
    newPassword
  )
    ? 1
    : 0;

  const result = upperAndLowercaseRule + lengthRule + numberAndSymbolRule;

  return result === 3;
};
