import styled from "styled-components";

interface ErrorContainerProps {
  visible: boolean;
}

export const ErrorContainer = styled.div<ErrorContainerProps>`
  padding: 10px 14px;
  max-width: 90%;

  background: #2c2c2c;
  border-radius: 80px;

  position: absolute;
  bottom: 40px;

  transition: 0.25s ease-in-out;
  opacity: ${({ visible }) => !visible && 0};
  visibility: ${({ visible }) => !visible && "hidden"};
`;

export const ErrorText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #ff5050;
`;
