import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/favorite-line.svg";

import { Container, StyledLink } from "./styles";
import { useProduct, useTryOn } from "../../contexts";

const BottomTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { imageList } = useTryOn();
  const { product } = useProduct();

  const homeNavigation = () => {
    if (imageList.length) {
      navigate("/result");
    } else {
      if (product) {
        navigate(`/try/${product.id}`);
      } else {
        navigate(`/try`);
      }
    }
  };

  return (
    <Container>
      {product && (
        <StyledLink
          onClick={homeNavigation}
          active={
            location.pathname === "/" ||
            location.pathname === "/result" ||
            location.pathname === "/product" ||
            location.pathname.includes("/try")
          }
          disabled={
            location.pathname === "/" ||
            location.pathname === "/result" ||
            location.pathname.includes("/try")
          }
        >
          <HomeIcon stroke={"#fff"} />
        </StyledLink>
      )}

      <StyledLink
        onClick={() => navigate("/discovery")}
        active={location.pathname === "/discovery"}
        disabled={location.pathname === "/discovery"}
      >
        <SearchIcon stroke={"#fff"} />
      </StyledLink>

      <StyledLink
        onClick={() => navigate("/saved")}
        active={location.pathname === "/saved"}
        disabled={location.pathname === "/saved"}
      >
        <FavoriteIcon width={24} stroke={"#fff"} />
      </StyledLink>

      <StyledLink
        onClick={() => navigate("/account")}
        active={location.pathname.includes("/account")}
        disabled={location.pathname === "/account"}
      >
        <UserIcon stroke={"#fff"} />
      </StyledLink>
    </Container>
  );
};

export default BottomTab;
