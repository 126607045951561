import React, { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { ActionButton, Header, Wrapper } from "../../components";

import EmailIcon from "../../assets/email.svg";
import InvisibleIcon from "../../assets/invisible.svg";
import VisibleIcon from "../../assets/visible.svg";

import {
  ButtontText,
  Container,
  DarkText,
  EmailIconImage,
  ErrorContainer,
  ErrorText,
  Form,
  Input,
  InputContainer,
  LightText,
  Title,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { getUserDoc } from "../../services";
import { useUser } from "../../contexts";

const SigninWithEmail: React.FC = () => {
  const auth = getAuth();
  const { setUser, saveAccessToken } = useUser();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const navigateDiscovery = () => {
    navigate("/discovery");
  };

  const navigateToSignup = () => {
    navigate("/signup");
  };

  const navigateToForgotPassword = () => {
    navigate("/forgot-password");
  };

  const signIn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoggingIn(true);

    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;

        const userDoc = await getUserDoc(user.uid);

        if (userDoc) {
          setUser({
            avatar: userDoc.avatar,
            displayName: userDoc.displayName,
            email: userDoc.email,
            username: userDoc.username,
            uid: userDoc.uid,
          });
        }

        saveAccessToken(await user.getIdToken(), user.uid);
        navigateDiscovery();
        setLoggingIn(false);
      })
      .catch((error) => {
        setLoggingIn(false);
        console.log(error.message);
        if (error.message === "Firebase: Error (auth/user-not-found).") {
          setErrorMessage(
            "We couldn’t find an account matching the email and password. Please check and try again."
          );

          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 5000);
        }

        if (error.message === "Firebase: Error (auth/invalid-email).") {
          setErrorMessage("Please enter a valid email address");

          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 5000);
        }
        if (error.message === "Firebase: Error (auth/wrong-password).") {
          setErrorMessage("Invalid password");

          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 5000);
        }
      });
  };

  const togglePasswordVisibility = () => {
    setVisiblePassword((visible) => !visible);
  };

  return (
    <Wrapper>
      <Header back />

      <Container>
        <Title>Log in with Email</Title>

        <Form onSubmit={signIn}>
          <InputContainer>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="Email"
            />
          </InputContainer>

          <InputContainer>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={visiblePassword ? "text" : "password"}
              placeholder="Password"
            />
            <img
              src={visiblePassword ? VisibleIcon : InvisibleIcon}
              alt=""
              onClick={togglePasswordVisibility}
            />
          </InputContainer>

          <ActionButton
            type="submit"
            loading={loggingIn}
            disabled={!email && !password}
          >
            <EmailIconImage src={EmailIcon} />
            Log in with Email
          </ActionButton>
        </Form>

        <ButtontText onClick={navigateToForgotPassword}>
          Forgot password
        </ButtontText>

        <LightText>
          Not a member? <span onClick={navigateToSignup}>Join now</span>
        </LightText>

        <DarkText>
          Questions? Contact <span>Support</span>
        </DarkText>
      </Container>

      <ErrorContainer visible={showAlert}>
        <ErrorText>{errorMessage}</ErrorText>
      </ErrorContainer>
    </Wrapper>
  );
};

export default SigninWithEmail;
