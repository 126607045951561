import React, { SyntheticEvent, useRef, useState } from "react";

import {
  SlideContainer,
  IndexIndicator,
  Slide,
  StyledImage,
  ImageContainer,
  Spinner,
  FavoriteButton,
  FavoriteLineButton,
  LoadingContainer,
  TapAlert,
} from "./styles";
import { Saved } from "../../interfaces/TryOn";
import { getImageUrl } from "../../services";
import Loading from "../Loading";
import { AsyncImage } from "..";

interface CarouselProps {
  slides: {
    result: string;
    original: string;
    loading?: boolean;
  }[];
  activeIndex: number;
  loading: boolean;
  onSave: (
    images: {
      original: string;
      result: string;
    },
    shareIndex: number
  ) => void;
  userSavedPhotos: Saved[];
  resultId: string;
  onDeleteSavePhoto: ({
    shareIndex,
    shareId,
  }: {
    shareIndex: number;
    shareId: string;
  }) => void;
  originalList: File[];
}

const Carousel = ({
  slides,
  activeIndex,
  onSave,
  userSavedPhotos,
  resultId,
  onDeleteSavePhoto,
  loading,
  originalList,
}: CarouselProps) => {
  const slideRef = useRef<any>(0);
  const placeholder = Array.from(Array(originalList.length).keys());
  const [showOriginal, setShowOriginal] = useState<{ [key: number]: boolean }>(
    {}
  );

  const handleShowOriginal = (index: number) => {
    const aux = { ...showOriginal };
    if (aux[index]) {
      aux[index] = !aux[index];
    } else {
      aux[index] = true;
    }
    setShowOriginal({ ...aux });
  };

  const handleSave = (
    images: { original: string; result: string },
    index: number,
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    onSave(images, index);
    e.stopPropagation();
  };

  const handleRemove = (
    index: number,
    e: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => {
    onDeleteSavePhoto({ shareId: resultId, shareIndex: index });
    e.stopPropagation();
  };

  const retryImage = async (
    event: SyntheticEvent<HTMLImageElement, Event>,
    imageName: string
  ) => {
    const response = await getImageUrl(imageName);

    if ((event.nativeEvent.target as HTMLImageElement)?.src) {
      (event.nativeEvent.target as HTMLImageElement).src = response;
    }
  };

  console.log(URL.createObjectURL(originalList[0]));

  return (
    <SlideContainer>
      <IndexIndicator>
        {activeIndex + 1}/{originalList.length}
      </IndexIndicator>
      <Slide index={activeIndex}>
        {placeholder.map((_, index) => (
          <ImageContainer
            key={_}
            onClick={() => handleShowOriginal(activeIndex)}
          >
            {loading && (
              <LoadingContainer>
                <Loading transparent />
              </LoadingContainer>
            )}
            {slides[index] && !slides[index].loading ? (
              <>
                <AsyncImage
                  show={!showOriginal[activeIndex] && !loading}
                  ref={slideRef}
                  src={slides[index].result}
                  fetching={loading}
                />
                <AsyncImage
                  show={showOriginal[activeIndex] || loading}
                  ref={slideRef}
                  src={slides[index].original}
                  fetching={loading}
                />
                {!userSavedPhotos.some(
                  (photo) =>
                    photo.shareId === resultId && photo.shareIndex === index
                ) ? (
                  <FavoriteLineButton
                    onClick={(e) =>
                      handleSave(
                        {
                          original: slides[index].original,
                          result: slides[index].result,
                        },
                        index,
                        e
                      )
                    }
                  />
                ) : (
                  <FavoriteButton onClick={(e) => handleRemove(index, e)} />
                )}
              </>
            ) : (
              originalList && (
                <StyledImage
                  show={true}
                  ref={slideRef}
                  src={URL.createObjectURL(originalList[0])}
                  fetching={loading}
                />
              )
            )}

            {!loading && (
              <TapAlert>
                <span>Tap to toggle</span>
              </TapAlert>
            )}
          </ImageContainer>
        ))}
      </Slide>
    </SlideContainer>
  );
};

export default Carousel;
