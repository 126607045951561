import React from "react";
import { BackIcon, CloseButton, Container, Row, Title } from "./styles";

import Logo from "../../assets/logo.png";
import DirectionIcon from "../../assets/chevron-right.svg";
import { useNavigate } from "react-router-dom";

interface HeaderProps {
  title?: string;
  back?: boolean;
  onBack?: () => void;
}

const Header = ({ title, back, onBack }: HeaderProps) => {
  const navigate = useNavigate();

  const goBack = () => {
    if (!!onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };
  const navigateToLandingPage = () => {
    window.open("https://www.stylar.com/");
  };

  return (
    <Container>
      {back ? (
        <Row clickable onClick={goBack}>
          <BackIcon src={DirectionIcon} alt="" />

          <Title>Back</Title>
        </Row>
      ) : (
        <Row>
          <img src={Logo} alt="" onClick={navigateToLandingPage} />

          <Title>{title}</Title>
        </Row>
      )}
    </Container>
  );
};

export default Header;
