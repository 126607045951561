import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 40px;

  background: #222222;
`;

export const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin: 0;
  padding: 0;

  color: #ffffff;
`;

export const SocialButtonsContainer = styled.div`
  width: 100%;
  padding-top: 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 8px;
`;

export const SocialButton = styled.button`
  width: 100%;
  max-width: 352px;
  height: 40px;

  background: #111111;
  border-radius: 4px;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;

  img {
    margin-right: 12px;
  }
`;

export const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;

  padding: 14px 16px 14px 0px;

  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  flex: 1;
  border: 1px solid #4d4d4d;
`;

export const DividerText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #4d4d4d;
`;

export const EmailIconImage = styled.img`
  width: 18px;
  height: 18px;

  margin-right: 12px;
`;

export const ButtontText = styled.span`
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  color: #ffffff;

  padding-top: 24px;

  opacity: 0.5;

  cursor: pointer;
`;
export const LightText = styled.span`
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  color: #ffffff;

  padding-top: 24px;

  opacity: 0.5;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const DarkText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;

  color: #ffffff;

  opacity: 0.5;

  position: absolute;
  bottom: 40px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;
