import styled from "styled-components";

interface ModalWrapperProps {
  visible: boolean;
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);

  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  padding: 16px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;

  background: #222222;
  border-radius: 8px;

  padding: 16px 32px;

  > h1 {
    font-size: 32px;
  }

  @media (max-width: 425px) {
    padding: 16px 16px;
  }
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  margin: 0;
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: #ffffff;

  padding-top: 8px;
  padding-bottom: 16px;
`;
