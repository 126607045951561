import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import { UserInterfaces } from "../interfaces";
import { getProfileTryOnPhotos, listProfileTryOnPhotos } from "../services";

interface UserProps {
  children: ReactNode;
}

interface UserPropsContextProps {
  user: UserInterfaces.User;
  setUser: Dispatch<SetStateAction<UserInterfaces.User>>;
  userAvatar: string;
  setUserAvatar: Dispatch<SetStateAction<string>>;
  accessToken: string;
  saveAccessToken: (token: string, uid: string) => void;
  logout: () => void;
  userTryOnImages: UserInterfaces.UserTryOnImage[];
  setUserTryOnImages: Dispatch<SetStateAction<UserInterfaces.UserTryOnImage[]>>;
  fetchingImages: boolean;
  getProfilePhotos: () => Promise<UserInterfaces.UserTryOnImage[]>;
}

const UserContext = createContext({} as UserPropsContextProps);

function UserProvider({ children }: UserProps) {
  const [fetchingImages, setFetchingImages] = useState(true);
  const [accessToken, setAccessToken] = useState("");
  const [user, setUser] = useState<UserInterfaces.User>({
    username: "",
    displayName: "",
    email: "",
    uid: "",
  });
  const [userAvatar, setUserAvatar] = useState("");
  const [userTryOnImages, setUserTryOnImages] = useState<
    UserInterfaces.UserTryOnImage[]
  >([]);

  const logout = () => {
    localStorage.removeItem("accessToken");
    setUser({
      username: "",
      displayName: "",
      email: "",
      uid: "",
      avatar: null,
    });
    setUserTryOnImages([]);
    setUserAvatar("");
    setAccessToken("");
  };

  const saveAccessToken = (token: string, uid: string) => {
    setAccessToken(token);
    localStorage.setItem("accessToken", JSON.stringify({ token, uid: uid }));
  };

  const getProfilePhotos = async () => {
    const userPhotos: UserInterfaces.UserTryOnImage[] = [];

    if (userTryOnImages.length === 0) {
      setFetchingImages(true);
    }

    const response = await listProfileTryOnPhotos(user.uid);

    if (response.items.length) {
      await Promise.all(
        response.items.map(async (imageRef) => {
          const imagePath = await getProfileTryOnPhotos(imageRef.fullPath);

          userPhotos.push({ path: imagePath, name: imageRef.name });
        })
      );

      userPhotos.sort((a, b) => Number(a.name) - Number(b.name));

      if (userPhotos.length) {
        setUserTryOnImages(userPhotos);
      }
    }
    setFetchingImages(false);

    return userPhotos;
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        accessToken,
        saveAccessToken,
        logout,
        userAvatar,
        setUserAvatar,
        userTryOnImages,
        setUserTryOnImages,
        fetchingImages,

        getProfilePhotos,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

function useUser() {
  const context = useContext(UserContext);

  return context;
}

export { UserProvider, useUser };
