import React from "react";
import { ActionButton, Header, Wrapper } from "../../components";

import GoogleIcon from "../../assets/google-logo.svg";
import FacebookIcon from "../../assets/facebook-logo.svg";
import AppleIcon from "../../assets/apple-logo.svg";
import EmailIcon from "../../assets/email.svg";

import {
  Container,
  DarkText,
  Divider,
  DividerContainer,
  DividerText,
  EmailIconImage,
  LightText,
  SocialButton,
  SocialButtonsContainer,
  Title,
} from "./styles";
import { useNavigate } from "react-router-dom";
import {
  appleSignin,
  facebookSignin,
  getUserDoc,
  googleSignin,
  setUserDoc,
} from "../../services";
import { useUser } from "../../contexts";

const SignUp: React.FC = () => {
  const { setUser, saveAccessToken } = useUser();
  const navigate = useNavigate();

  const navigateToSignin = () => {
    navigate("/signin");
  };

  const navigateToSignupWithEmail = () => {
    navigate("/signup/email");
  };

  const navigateToOnboarding = () => {
    navigate("/onboarding");
  };

  const socialLogin = async (socialMedia: "google" | "facebook" | "apple") => {
    try {
      let user = null;

      switch (socialMedia) {
        case "apple":
          user = await appleSignin();
          break;
        case "facebook":
          user = await facebookSignin();
          break;
        case "google":
          user = await googleSignin();
          break;
      }

      if (user) {
        const userDoc = await getUserDoc(user.uid);

        if (userDoc) {
          setUser({
            avatar: userDoc.avatar,
            displayName: userDoc.displayName,
            email: userDoc.email,
            username: userDoc.username,
            uid: userDoc.uid,
          });
        } else {
          const defaultUser = {
            avatar: null,
            displayName: user.displayName || "",
            email: user.email || "",
            username:
              user.displayName?.split(" ")[0].toLocaleLowerCase() + user.uid,
            uid: user.uid,
          };
          await setUserDoc(defaultUser);
          setUser(defaultUser);
        }
        saveAccessToken(await user.getIdToken(), user.uid);
        navigateToOnboarding();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Wrapper>
      <Header />

      <Container>
        <Title>Welcome to your Virtual Fitting Room</Title>

        <SocialButtonsContainer>
          <SocialButton onClick={() => socialLogin("google")}>
            <img src={GoogleIcon} />
            Continue with Google
          </SocialButton>

          <SocialButton onClick={() => socialLogin("facebook")}>
            <img src={FacebookIcon} />
            Continue with Facebook
          </SocialButton>

          {/* <SocialButton onClick={appleSignin}>
            <img src={AppleIcon} />
            Continue with Apple
          </SocialButton> */}
        </SocialButtonsContainer>

        <DividerContainer>
          <Divider />
          <DividerText>or</DividerText>
          <Divider />
        </DividerContainer>

        <ActionButton onClick={navigateToSignupWithEmail}>
          <EmailIconImage src={EmailIcon} />
          Sign up with Email
        </ActionButton>

        <LightText>
          Already a member? <span onClick={navigateToSignin}>Log in</span>
        </LightText>

        <DarkText>
          Questions? Contact <span>Support</span>
        </DarkText>
      </Container>
    </Wrapper>
  );
};

export default SignUp;
