import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ActionButton, Header, Wrapper } from "../../components";

import BadExample1 from "../../assets/FullBodyExamples/bad1.png";
import BadExample2 from "../../assets/FullBodyExamples/bad2.png";
import BadExample3 from "../../assets/FullBodyExamples/bad3.png";
import GoodExample1 from "../../assets/FullBodyExamples/good1.png";
import GoodExample2 from "../../assets/FullBodyExamples/good2.png";
import GoodExample3 from "../../assets/FullBodyExamples/good3.png";

import {
  CarouselNavButton,
  CarouselNavContainer,
  Container,
  Description,
  ExampleImage,
  ImageContainer,
  ImageTag,
  Row,
  SubTitle,
  Title,
} from "./styles";

const ReferenceTips: React.FC = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(0);
  const content = [
    {
      subTitle: "Full Body",
      description:
        "Use a clear, full-body image of yourself. Try to avoid using photos with objects in the foreground that may occlude parts of your body from view.",
      badImageSource: BadExample1,
      goodImageSource: GoodExample1,
    },
    {
      subTitle: "Single Person",
      description:
        "For best results, use a clear photo of just yourself as the main subject. Avoid photos where there are multiple people in the foreground and background.",
      badImageSource: BadExample2,
      goodImageSource: GoodExample2,
    },
    {
      subTitle: "Form Fitting Clothes",
      description:
        "For best results, use photos of yourself wearing form-fitting clothes. If you are trying on shoes, avoid photos with oversized pants that occlude the feet area.",
      badImageSource: BadExample3,
      goodImageSource: GoodExample3,
    },
  ];

  const nextIndex = () => {
    if (activeIndex < 2) {
      setActiveIndex((index) => index + 1);
    } else {
      navigate(-1);
    }
  };

  return (
    <Wrapper>
      <Header back />

      <Container>
        <Title>Reference image tips</Title>

        <Row>
          <ImageContainer>
            <ExampleImage src={content[activeIndex].badImageSource} alt="" />

            <ImageTag>Bad 👎</ImageTag>
          </ImageContainer>
          <ImageContainer>
            <ExampleImage src={content[activeIndex].goodImageSource} alt="" />
            <ImageTag isGood>Good 👍</ImageTag>
          </ImageContainer>
        </Row>

        <SubTitle>{content[activeIndex].subTitle}</SubTitle>

        <Description>{content[activeIndex].description}</Description>

        <CarouselNavContainer>
          {Array.from(Array(3)).map((_, index) => (
            <CarouselNavButton
              onClick={() => setActiveIndex(index)}
              active={index === activeIndex}
            />
          ))}
        </CarouselNavContainer>

        <ActionButton onClick={nextIndex}>
          {activeIndex === 2 ? "Done" : "Next"}
        </ActionButton>
      </Container>
    </Wrapper>
  );
};

export default ReferenceTips;
