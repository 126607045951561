import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px;
  margin-bottom: 60px;

  background: #222222;
`;

export const Title = styled.h1`
  text-align: center;

  width: 100%;
  font-weight: 800;
  font-size: 23px;
  line-height: 28px;

  margin: 0;
  padding-bottom: 16px;

  color: #ffffff;
`;

export const Description = styled.span`
  text-align: center;

  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  margin: 0;

  color: #ffffff;
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
