import React from "react";
import { ErrorContainer, ErrorText } from "./styles";

interface ErrorTipProps {
  visible: boolean;
  message: string;
}

const ErrorTip = ({ visible, message }: ErrorTipProps) => {
  return (
    <ErrorContainer visible={visible}>
      <ErrorText>{message}</ErrorText>
    </ErrorContainer>
  );
};

export default ErrorTip;
