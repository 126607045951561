import React from "react";

import { Spinner } from "../../../../components";

import { UserInterfaces } from "../../../../interfaces";

import WhiteCheckIcon from "../../../../assets/white-check.svg";

import {
  ButtonContainer,
  Container,
  DarkButton,
  DarkTitle,
  Frame,
  GalleryImage,
  ImagesContainer,
  InputPlaceholder,
  SelectImageButton,
  UserImagesTitleContainer,
} from "./styles";

interface UserImagesProps {
  fetching: boolean;
  images: UserInterfaces.UserTryOnImage[];
  checkedItems: {
    [key: number]: boolean;
  };
  checkImage: (key: number) => void;
  checkAllImages: () => void;
}

const UserImages = ({
  fetching,
  images,
  checkedItems,
  checkImage,

  checkAllImages,
}: UserImagesProps) => {
  return (
    <Container>
      <UserImagesTitleContainer>
        <DarkTitle>My Try On Photos</DarkTitle>

        <button onClick={checkAllImages}>Select All</button>
      </UserImagesTitleContainer>

      {fetching ? (
        <Spinner />
      ) : (
        <ImagesContainer empty={images.length === 0}>
          {Array.from(Array(6).keys()).map((i) =>
            !!images[i] ? (
              <Frame key={i} onClick={() => checkImage(i)}>
                <GalleryImage src={images[i].path} alt="" />

                <SelectImageButton checked={checkedItems?.[i]}>
                  {checkedItems?.[i] && <img src={WhiteCheckIcon} alt="" />}
                </SelectImageButton>
              </Frame>
            ) : (
              <Frame key={i} />
            )
          )}
        </ImagesContainer>
      )}
    </Container>
  );
};

export default UserImages;
