import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  flex: 1;

  background: #fff;

  display: grid;
  grid-template-columns: repeat(3, 116px);
  justify-content: center;
  align-items: center;
  gap: 8px;
  overflow-y: scroll;
`;

export const ProductImage = styled.img`
  width: 116px;
  height: 116px;

  border-radius: 16px;

  cursor: pointer;
  transition: 200ms;

  :hover {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  }
`;
