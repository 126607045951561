import React, { useEffect, useState } from "react";
import { Wrapper } from "../../components";
import ProductHeader from "./components/ProductHeader";

import { Container, ProductImage } from "./styles";
import { useProduct } from "../../contexts";
import { ProductInterfaces } from "../../interfaces";
import { useNavigate } from "react-router-dom";

const Demo = () => {
  const navigate = useNavigate();
  const { getProducts, productList, setProduct } = useProduct();
  const [selectedProduct, setSelectedProduct] =
    useState<ProductInterfaces.Product | null>(null);

  const navigateToShare = (product: ProductInterfaces.Product) => {
    setProduct(product);
    navigate(`/share/${product.id}/start`);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Wrapper>
      <ProductHeader product={selectedProduct} />
      <Container>
        {productList.map((product) => (
          <ProductImage
            src={product.icon}
            alt=""
            onMouseOver={() => setSelectedProduct(product)}
            onMouseOut={() => setSelectedProduct(null)}
            onClick={() => navigateToShare(product)}
          />
        ))}
      </Container>
    </Wrapper>
  );
};

export default Demo;
