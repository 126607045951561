import React, { useCallback, useEffect, useState } from "react";
import { Container, Title, TitleContainer } from "./styles";
import { useTryOn } from "../../contexts";

// import { Container } from './styles';

interface LoadingProps {
  transparent?: boolean;
}

const Loading = ({ transparent }: LoadingProps) => {
  const messages = [
    "Generating your fit",
    "we’ll make sure it's lit",
    "fashionably late, we admit",
    "wrapping up the last bit",
  ];
  const [index, setIndex] = useState(1);
  const [isTicking, setIsTicking] = useState(false);
  const { currentTask } = useTryOn();

  useEffect(() => {
    setIsTicking(currentTask.status === "STARTED");
  }, [currentTask]);

  // timer
  useEffect(() => {
    if (!isTicking) return;
    const animation = setInterval(() => {
      setIndex((prevIndex) => Math.min(messages.length, prevIndex + 1));
    }, 2000);
    return () => clearInterval(animation);
  }, [isTicking, messages.length]);

  return (
    <Container transparent={!!transparent}>
      <TitleContainer>
        {currentTask.status === "PENDING" ? (
          <Title primary={false}>
            {currentTask.queue === 0
              ? "You're next in line..."
              : "Position in queue: " + currentTask.queue}
          </Title>
        ) : (
          [
            messages.slice(0, index).map((message, n) => (
              <Title primary={n === index - 1} key={message}>
                {(currentTask.status ? message : "Uploading") +
                  (n === index - 1 ? "..." : ",")}
              </Title>
            )),
          ]
        )}
      </TitleContainer>
    </Container>
  );
};

export default Loading;
