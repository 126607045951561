import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useTryOn, useUser } from "../contexts";
import { getUserAvatarStorage, getUserDoc } from "../services";
import {
  Home,
  Product,
  Result,
  Error,
  Discovery,
  FullScreen,
  SignUp,
  SignIn,
  SignUpWithEmail,
  SigninWithEmail,
  Account,
  EditProfile,
  Onboarding,
  ReferenceTips,
  ShareCover,
  ShareHome,
  ShareResult,
  ForgotPassword,
  ChangePassword,
  EditProfilePassword,
  Saved,
  Demo,
} from "../pages";

export const Router: React.FC = () => {
  const { user, setUserAvatar, setUser, getProfilePhotos } = useUser();
  const { setShowMode } = useTryOn();
  const [fetchingToken, setFetchingToken] = useState(true);

  useEffect(() => {
    const getLoggedUser = async () => {
      try {
        setFetchingToken(true);

        const data = localStorage.getItem("accessToken");
        if (data) {
          const parsedData = JSON.parse(data);

          if (parsedData.token && parsedData.uid) {
            const { uid } = parsedData;
            const user = await getUserDoc(uid);
            const avatar = await getUserAvatarStorage(uid);
            if (avatar) {
              setUserAvatar(avatar);
            }
            if (user) {
              setUser({
                uid,
                username: user.username,
                email: user.email,
                displayName: user.displayName,
              });
            }
          }
        }
        setFetchingToken(false);
      } catch (e) {
        setFetchingToken(false);
        console.log(e);
      }
    };

    getLoggedUser();
  }, []);

  useEffect(() => {
    if (user.uid) {
      const initialize = async () => {
        const response = await getProfilePhotos();

        if (response.length) {
          setShowMode("multiple");
        } else {
          setShowMode("single");
        }
      };

      initialize();
    }
  }, [user]);

  return (
    <BrowserRouter>
      {!fetchingToken && (
        <Routes>
          {user.uid ? (
            <>
              <Route path="/try/:productId" element={<Home />} />
              <Route
                path="/try"
                element={<Navigate to="/discovery" replace />}
              />
              <Route path="/account" element={<Account />} />
              <Route path="/result" element={<Result />} />
              <Route path="/account/edit" element={<EditProfile />} />
              <Route
                path="/account/edit/password"
                element={<EditProfilePassword />}
              />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/saved" element={<Saved />} />
            </>
          ) : (
            <>
              <Route path="*" element={<Navigate to="/signin" replace />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signin/email" element={<SigninWithEmail />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signup/email" element={<SignUpWithEmail />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/discovery" replace />} />
          <Route path="/product" element={<Product />} />

          <Route path="/error" element={<Error />} />
          <Route path="/discovery" element={<Discovery />} />
          <Route path="/fullscreen" element={<FullScreen />} />
          <Route path="/examples" element={<ReferenceTips />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/share/:productId" element={<ShareCover />} />
          <Route path="/share/:productId/start" element={<ShareHome />} />
          <Route path="/share/:productId/result" element={<ShareResult />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};
