import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import { Link } from "react-router-dom";

const imageMaxHeight = 380;
const imageMaxWidth = 352;

interface SwitchButtonProps {
  visible: boolean;
}

interface SlideProps {
  index: number;
  imageWidth: number;
}

interface IndexIndicatorProps {
  activeIndex: boolean;
}

interface ActionButtonProps {
  largeImage?: boolean;
}

interface AlertContainerProps {
  visible: boolean;
}

interface ActionContainerProps {
  visible: boolean;
}

export const SlideContainer = styled.div`
  min-height: 350px;

  position: relative;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow-x: hidden;
`;

export const Slide = styled.div<SlideProps>`
  position: relative;

  display: flex;
  justify-content: flex-start;

  gap: ${({ imageWidth }) => 2 + (imageMaxHeight - imageWidth) * 0.5}px;
  padding-left: ${({ imageWidth }) =>
    10 + (imageMaxHeight - imageWidth) * 0.5}px;

  transform: translateX(
    -${({ index, imageWidth }) => ((imageMaxHeight - imageWidth) * 0.5 + imageWidth + 2) * index}px
  );

  background: #191919;

  border-radius: 8px;
  z-index: 0;
`;

export const Info = styled.img`
  width: 25px;
  height: 25px;

  position: absolute;
  bottom: 14px;
  right: 38px;

  opacity: 1;
  z-index: 2;
`;

export const StyledImage = styled.img`
  display: block;

  min-height: ${imageMaxHeight}px;
  max-height: ${imageMaxHeight}px;

  min-width: auto;
  max-width: ${imageMaxWidth}px;

  border-radius: 8px;

  overflow: hidden;

  object-fit: cover;
`;

export const SwitchImageLeftButton = styled.a<SwitchButtonProps>`
  width: 40px;
  height: 40px;

  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  visibility: ${({ visible }) => !visible && "hidden"};

  cursor: pointer;

  opacity: 0.5;

  transition: 0.25s;

  img {
    transform: rotate(180deg);

    width: 12px;
    height: 20px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const SwitchImageRightButton = styled.a<SwitchButtonProps>`
  width: 40px;
  height: 40px;

  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.5;

  visibility: ${({ visible }) => !visible && "hidden"};

  transition: 0.25s;

  cursor: pointer;

  img {
    width: 12px;
    height: 20px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const IndexIndicatorContainer = styled.div`
  position: absolute;

  display: flex;
  gap: 8px;

  width: 100%;
  justify-content: center;

  bottom: 16px;
  z-index: 0;
`;

export const IndexIndicator = styled.div<IndexIndicatorProps>`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #fff;

  opacity: ${({ activeIndex }) => (activeIndex ? 1 : 0.25)};
  z-index: 0;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  padding-top: 24px;

  background: #222222;

  overflow: auto;

  .carousel-indicators button {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    border: 0;
  }

  .carousel-control-next-icon span {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 100px;
  }
`;

export const Title = styled.h1`
  width: 100%;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  margin: 0px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 8px;

  color: #ffffff;
`;

export const Description = styled.span`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 16px;

  color: #ffffff;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const ActionContainer = styled.div<ActionContainerProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  padding-top: 16px;
  padding-bottom: 24px;
  overflow: visible;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const FullScreenButton = styled.a`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;

  background: rgba(51, 51, 51, 0.5);
  border-radius: 100px;

  cursor: pointer;

  img {
    width: 35px;
    height: 35px;
  }

  &:hover {
    background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%), #000000;
  }
`;

export const ActionButton = styled.a<ActionButtonProps>`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;

  background: rgba(51, 51, 51, 0.5);
  border-radius: 100px;

  cursor: pointer;

  img {
    width: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
    height: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
  }

  &:hover {
    background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%), #000000;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  max-width: 200px;
  text-align: center;

  color: #d9d9d9;
  z-index: 2;
`;

export const AlertContainer = styled.div<AlertContainerProps>`
  max-width: 112px;
  padding: 10px 14px;

  background: #2c2c2c;
  border-radius: 80px;

  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;

  transition: 0.25s ease-in-out;
  opacity: ${({ visible }) => !visible && 0};
  visibility: ${({ visible }) => !visible && "hidden"};

  z-index: 11;
`;

export const AlertText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`;
