import styled from "styled-components";

interface TitleProps {
  primary?: boolean;
}

interface ContainerProps {
  transparent: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  padding: 40px;

  background: ${({ transparent }) => (transparent ? "transparent" : "#222222")};
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.h1<TitleProps>`
  font-weight: 800;
  font-size: 18px;
  line-height: 27px;
  text-align: left;

  margin: 0;

  color: #ffffff;

  opacity: ${({ primary }) => (primary ? 1 : 0.25)};
  transition: 0.5s;

  @media (max-width: 425px) {
    font-size: 14px;
  }
`;
