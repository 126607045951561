import styled from "styled-components";

interface FrameProps {
  empty?: boolean;
}

interface ImagesContainerProps {
  empty: boolean;
}

interface GalleryImageProps {
  isLoading: boolean;
}

export const Container = styled.div`
  height: 100%;
  width: 100%;

  flex: 1;

  padding: 24px;

  overflow: auto;
`;

export const ProfileContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
`;

export const InfoContainer = styled.div`
  flex: 1;
  width: 100%;
  padding-left: 16px;

  padding: 5px 16px;
`;

export const UsernameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const PictureFrame = styled.div`
  width: 64px;
  height: 64px;
  left: 0px;
  top: 0px;

  background: #a2a2a2;
  border: 4px solid #111111;
  border-radius: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const ProfilePicture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProfileIcon = styled.img`
  opacity: 0.25;
`;

export const DisplayName = styled.span`
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;

  color: #ffffff;
`;

export const Username = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  opacity: 0.5;
  max-width: 65%;
  text-overflow: ellipsis;
`;

export const EditButton = styled.button`
  padding: 7px 11px;
  gap: 8px;

  width: 80px;
  height: 27px;

  background: rgba(255, 255, 255, 0.05);
  border: none;
  border-radius: 80px;

  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;

  color: #ffffff;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  opacity: 0.1;

  margin: 24px 0px;
`;

export const DarkTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  opacity: 0.5;
`;

export const AddIcon = styled.img`
  width: 20px;
  height: 20px;

  margin-left: calc(50% - 10px);
  margin-top: calc(50% - 10px);

  opacity: 0;

  transition: 0.25s;
`;

export const Frame = styled.div<FrameProps>`
  position: relative;
  cursor: ${({ empty }) => empty && "pointer"};
  overflow: visible;

  width: 112px;
  height: 112px;

  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: 0.25s;

  display: flex;

  :hover {
    background: rgba(0, 0, 0, 0.5);

    ${AddIcon} {
      opacity: 0.25;
      margin: auto;
    }
  }
`;

export const ImagesContainer = styled.div<ImagesContainerProps>`
  position: relative;
  overflow: visible;
  width: 100%;

  padding-top: 16px;

  display: grid;
  justify-content: center;
  gap: 8px;

  grid-template-columns: repeat(3, 112px);

  ${Frame} {
    opacity: ${({ empty }) => empty && 0.25};
  }
`;

export const DropContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  padding: 0 25%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const DropDarkText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;

  opacity: 0.5;
`;

export const DropText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #ffffff;

  opacity: 0.75;
`;

export const RemoveImageButton = styled.button`
  width: 16px;
  height: 16px;
  border-radius: 16px;

  position: absolute;
  top: -8px;
  right: -8px;

  background: #ffffff;
  border: none;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    min-width: 10px;
    min-height: 10px;
    background: transparent;
  }
`;

export const GalleryImage = styled.img<GalleryImageProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;

  background: rgba(0, 0, 0, 0.25);

  border-radius: 8px;

  cursor: pointer;

  opacity: ${({ isLoading }) => isLoading && 0.25};
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;
