import { doc, getDoc } from "firebase/firestore";
import { firestore } from "./firebase";
import { TryOnInterfaces } from "../interfaces";

export const getShareInfo = async (
  id: string
): Promise<TryOnInterfaces.Share | false> => {
  const docSnap = await getDoc(doc(firestore, "shares", id));

  if (docSnap.exists()) {
    return { ...docSnap.data(), id: docSnap.id } as TryOnInterfaces.Share;
  } else {
    return false;
  }
};
