import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
} from "firebase/auth";
import { api } from "./api";

const auth = getAuth();
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const appleProvider = new OAuthProvider("apple.com");

export const googleSignin = () =>
  signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      return result.user;
    })
    .catch((error) => {
      console.log(error);
    });

export const facebookSignin = () =>
  signInWithPopup(auth, facebookProvider)
    .then(async (result) => {
      return result.user;
    })
    .catch((error) => {
      console.log(error);
    });

export const appleSignin = () =>
  signInWithPopup(auth, appleProvider)
    .then((result) => {
      return result.user;
    })
    .catch((error) => {
      console.log(error);
    });

export const sendPasswordRecoveryEmail = (email: string) => {
  return fetch(`/send-custom-verification-email`, {
    method: "Post",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userEmail: email,
    }),
  });
};
