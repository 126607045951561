import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { ActionButton, BottomTab, Header, Wrapper } from "../../components";

import UserIcon from "../../assets/user-default.png";
import CloseIcon from "../../assets/black-close.svg";
import PlusIcon from "../../assets/add.svg";

import {
  AddIcon,
  Container,
  DarkTitle,
  DisplayName,
  Divider,
  DropContainer,
  DropDarkText,
  DropText,
  EditButton,
  Frame,
  GalleryImage,
  ImagesContainer,
  InfoContainer,
  PictureFrame,
  ProfileContainer,
  ProfileIcon,
  ProfilePicture,
  RemoveImageButton,
  Spinner,
  Username,
  UsernameContainer,
} from "./styles";
import { useNavigate } from "react-router-dom";
import heic2any from "heic2any";
import { useProduct, useTryOn, useUser } from "../../contexts";
import {
  removeProfileTryOnPhoto,
  storeProfileTryOnPhoto,
} from "../../services";

const Account = () => {
  const navigate = useNavigate();
  const { setSelectedImages, setShowMode } = useTryOn();
  const {
    user,
    userAvatar,
    userTryOnImages,
    setUserTryOnImages,
    getProfilePhotos,
    fetchingImages,
  } = useUser();
  const { product } = useProduct();

  const [loadingIndex, setLoadingIndex] = useState(-1);

  const navigateToEditProfile = () => {
    navigate("/account/edit");
  };

  const removeImage = async (index: number) => {
    setLoadingIndex(index);

    await removeProfileTryOnPhoto({
      uid: user.uid,
      fileName: userTryOnImages[index].name,
    });

    const auxImages = [...userTryOnImages];

    auxImages.splice(index, 1);

    setUserTryOnImages([...auxImages]);
    setLoadingIndex(-1);
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (userTryOnImages.length < 6) {
        acceptedFiles.forEach((file: any) => {
          const reader = new FileReader();

          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          reader.onload = () => {
            if (file.type === "image/heic") {
              // setUploading(true);
              const fileURL = URL.createObjectURL(file);

              fetch(fileURL)
                .then((res) =>
                  res.blob().then((blob) =>
                    heic2any({
                      blob,
                      toType: "image/jpeg",
                    }).then((result) => {
                      const blobResult = result as Blob;
                      const fileName = `${Date.now()}`;
                      uploadTryOnImage({
                        fileName,
                        image: blobResult,
                      });
                      setUserTryOnImages((prevState) => [
                        ...prevState,
                        {
                          path: URL.createObjectURL(blobResult),
                          name: fileName,
                        },
                      ]);
                    })
                  )
                )
                .catch((e) => {
                  console.log(e);
                });
            } else {
              const fileName = `${Date.now()}`;
              uploadTryOnImage({
                fileName,
                image: file,
              });
              setUserTryOnImages((prevImages) => [
                ...prevImages,
                { path: URL.createObjectURL(file), name: fileName },
              ]);
            }
          };
          reader.readAsArrayBuffer(file);
        });
      }
    },
    [userTryOnImages.length]
  );

  const uploadTryOnImage = async ({
    fileName,
    image,
  }: {
    fileName: string;
    image: File | Blob;
  }) => {
    try {
      await storeProfileTryOnPhoto({
        uid: user.uid,
        fileName,
        image,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const { getRootProps, open } = useDropzone({
    onDrop,
    maxFiles: 6,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/heif": [".heif"],
      "image/heic": [".heic"],
    },
  });

  const selectImage = async (index: number) => {
    setLoadingIndex(index);
    const selectedImage = await fetch(userTryOnImages[index].path).then((res) =>
      res.blob()
    );

    setSelectedImages(() => [...[selectedImage]]);
    setShowMode("single");
    setLoadingIndex(-1);

    if (product) {
      navigate(`/try/${product.id}`);
    } else {
      navigate(`/try`);
    }
  };

  useEffect(() => {
    getProfilePhotos();
  }, []);

  return (
    <Wrapper>
      <Header title="Account" />

      <Container>
        <ProfileContainer>
          <PictureFrame>
            {userAvatar ? (
              <ProfilePicture src={userAvatar} alt="" />
            ) : (
              <ProfileIcon src={UserIcon} alt="" />
            )}
          </PictureFrame>
          <InfoContainer>
            <DisplayName>{user.displayName}</DisplayName>
            <UsernameContainer>
              <Username>@{user.username}</Username>
              <EditButton onClick={navigateToEditProfile}>
                Edit Profile
              </EditButton>
            </UsernameContainer>
          </InfoContainer>
        </ProfileContainer>

        <Divider />

        <DarkTitle>My Try On Photos</DarkTitle>

        <ImagesContainer empty={userTryOnImages.length === 0}>
          <>
            {Array.from(Array(6).keys()).map((i) =>
              !!userTryOnImages[i] ? (
                <Frame key={i}>
                  {!!userTryOnImages[i] && (
                    <>
                      <GalleryImage
                        src={userTryOnImages[i].path}
                        alt=""
                        onClick={() => selectImage(i)}
                        isLoading={loadingIndex === i}
                      />
                      {loadingIndex === i && <Spinner />}
                      <RemoveImageButton onClick={() => removeImage(i)}>
                        <img src={CloseIcon} alt="" />
                      </RemoveImageButton>
                    </>
                  )}
                </Frame>
              ) : (
                <Frame key={i} empty {...getRootProps()}>
                  <AddIcon src={PlusIcon} alt="" />
                </Frame>
              )
            )}
            {fetchingImages ? (
              <Spinner />
            ) : (
              userTryOnImages.length === 0 && (
                <DropContainer {...getRootProps()}>
                  <DropDarkText>Drag Images Here</DropDarkText>
                  <DropText>- or -</DropText>
                  <ActionButton onClick={open}>
                    Select from computer
                  </ActionButton>
                </DropContainer>
              )
            )}
          </>
        </ImagesContainer>
      </Container>
      <BottomTab />
    </Wrapper>
  );
};

export default Account;
