import styled from "styled-components";

interface StyledImageProps {
  show: boolean;
  fetching?: boolean;
}

export const StyledImage = styled.img<StyledImageProps>`
  height: 100%;
  width: auto;
  object-fit: cover;
  transition: 0.5s;

  position: absolute;
  bottom: 0;
  top: 0;

  opacity: ${({ show }) => (show ? 1 : 0)};
  filter: ${({ fetching }) => fetching && "blur(8px)"};

  cursor: pointer;
  z-index: 1;

  overflow: hidden;

  background: rgba(0, 0, 0, 0.25);
`;

export const LoadingContainer = styled.div`
  height: 100%;
  width: auto;
  object-fit: cover;
  transition: 0.5s;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 0;
  top: 0;
`;
