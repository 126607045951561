import React, { useCallback, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import RegenerateIcon from "../../assets/regenerate.svg";
import EditIcon from "../../assets/edit.svg";
import DirectionIcon from "../../assets/chevron-right.svg";
import FullScreenIcon from "../../assets/fullscreen.svg";
import ShareIcon from "../../assets/share.svg";

import {
  Header,
  Loading,
  BottomTab,
  Wrapper,
  ShareDropdown,
  Modal,
  Carousel,
} from "../../components";

import {
  useOnboarding,
  useProduct,
  useSaveResult,
  useSaved,
  useTryOn,
  useUser,
} from "../../contexts";

import {
  Container,
  ActionButton,
  ActionContainer,
  CustomTooltip,
  SwitchImageRightButton,
  SwitchImageLeftButton,
  FullScreenButton,
  AlertContainer,
  AlertText,
} from "./styles";

const Result: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { selectedImages } = useTryOn();
  const { resultId } = useSaveResult();
  const [index, setIndex] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { savePhoto, userSavedPhotos, removePhoto } = useSaved();
  const [shareVisible, setShareVisible] = useState(false);
  const { user } = useUser();
  const { onboarding, disableOnboarding } = useOnboarding();

  const {
    imageList,
    setImageList,
    fetchingResult,
    getResult,
    getSingleResult,
  } = useTryOn();
  const { product } = useProduct();

  const url = `${window.location.origin}/fullscreen?id=${resultId}`;

  const prevSlide = () => {
    if (index > 0) setIndex((i) => i - 1);
  };

  const nextSlide = () => {
    setIndex((i) => i + 1);
  };

  const onSave = (
    images: { original: string; result: string },
    shareIndex: number
  ) => {
    if (product && user) {
      setAlertMessage("Image saved");

      setShowAlert(true);

      setTimeout(() => setShowAlert(false), 3000);

      savePhoto({
        product,
        uid: user.uid,
        shareId: resultId,
        images,
        shareIndex,
      });
    }
  };

  const onDeleteSavePhoto = ({
    shareIndex,
    shareId,
  }: {
    shareIndex: number;
    shareId: string;
  }) => {
    removePhoto({ shareId, shareIndex });
  };

  const handleCopyLink = () => {
    setAlertMessage("Link Copied!");

    setShowAlert(true);

    setTimeout(() => setShowAlert(false), 3000);
    navigator.clipboard.writeText(url);
  };

  const getData = useCallback(async () => {
    try {
      if (product) {
        const { id } = product;

        ReactGA.event({
          category: "user",
          action: "get_results",
        });

        await getResult(id);
      }
    } catch (e) {
      console.log(e);
      ReactGA.event({
        category: "error",
        action: "get_results_error",
      });
      navigate("/error", { state });
    }
  }, [getResult, navigate, product, state]);

  const getSingleData = async () => {
    try {
      if (product) {
        const { id } = product;

        ReactGA.event({
          category: "user",
          action: "get_results",
        });

        await getSingleResult(id, index);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const navigateToHome = () => {
    ReactGA.event({
      category: "user",
      action: "use_new_image",
    });
    setImageList([]);
    navigate(`/try/${product?.id}`);
  };

  const navigateToFullScreen = async () => {
    await localStorage.setItem("imageList", JSON.stringify(imageList));
    await localStorage.setItem("productId", JSON.stringify(product?.id));
    window.open("/fullscreen", "_blank", "noreferrer");
  };

  const toggleShareDropdown = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShareVisible((value) => !value);
  };

  useEffect(() => {
    if (
      imageList.length === 0 &&
      !fetchingResult &&
      selectedImages.length > imageList.length
    ) {
      getData();
    }
  }, [selectedImages]);

  console.log(fetchingResult);

  return (
    <Wrapper>
      <Header title="Virtual Try On" />

      <Container>
        <Carousel
          slides={imageList.map(
            (image) =>
              image && {
                result: image.result,
                original: image.original,
                loading: image.regenerating,
              }
          )}
          originalList={selectedImages}
          activeIndex={index}
          onSave={onSave}
          onDeleteSavePhoto={onDeleteSavePhoto}
          userSavedPhotos={userSavedPhotos}
          resultId={resultId}
          loading={fetchingResult}
        />

        <CustomTooltip id="result-tooltip" />
        <CustomTooltip id="save-tooltip" />
        <CustomTooltip id="edit-tooltip" />
        <CustomTooltip id="share-tooltip" />

        <ActionContainer visible={!fetchingResult && !!imageList.length}>
          <SwitchImageLeftButton visible={index > 0} onClick={prevSlide}>
            <img src={DirectionIcon} alt="" />
          </SwitchImageLeftButton>

          <FullScreenButton
            onClick={navigateToFullScreen}
            data-tooltip-id="edit-tooltip"
            data-tooltip-content="Full Screen Mode"
            data-tooltip-place="top"
          >
            <img
              src={FullScreenIcon}
              alt=""
              style={{ width: 35, height: 35 }}
            />
            <CustomTooltip id="edit-tooltip" />
          </FullScreenButton>

          <ActionButton
            data-tooltip-id="edit-tooltip"
            data-tooltip-content="Edit Reference Image"
            data-tooltip-place="top"
            onClick={navigateToHome}
          >
            <img src={EditIcon} alt="" />
          </ActionButton>

          <ActionButton
            onClick={getSingleData}
            data-tooltip-id="result-tooltip"
            data-tooltip-content="Regenerate"
            data-tooltip-place="top"
          >
            <img src={RegenerateIcon} alt="" />
          </ActionButton>

          <ActionButton
            data-tooltip-id="share-tooltip"
            data-tooltip-content="Share"
            data-tooltip-place="top"
            onClick={toggleShareDropdown}
            largeImage
          >
            <img src={ShareIcon} alt="" />
          </ActionButton>

          <SwitchImageRightButton
            visible={index < selectedImages.length - 1}
            onClick={nextSlide}
          >
            <img src={DirectionIcon} alt="" />
          </SwitchImageRightButton>

          <ShareDropdown
            onClose={() => setShareVisible(false)}
            visible={shareVisible}
            id={resultId}
            medias={imageList.map((image) => image && image.resultURL)}
            onLinkCopied={handleCopyLink}
            allowDownload
          />
        </ActionContainer>
      </Container>

      <BottomTab />

      <AlertContainer visible={showAlert}>
        <AlertText>{alertMessage}</AlertText>
      </AlertContainer>

      <Modal
        visible={!fetchingResult && onboarding && !!imageList.length}
        onClickButton={disableOnboarding}
        title="Looking good!"
        description="You’re all set. Generate, save, and share your outfits with Stylar. Have fun exploring and trying new things on – the world is your oyster!"
        buttonTitle="View results"
      />
    </Wrapper>
  );
};

export default Result;
