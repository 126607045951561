import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 40px;

  background: #222222;
`;

export const Title = styled.h1`
  width: 100%;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  margin: 0px;
  padding-top: 24px;
  padding-bottom: 8px;

  color: #ffffff;
`;

export const Description = styled.span`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  padding-bottom: 16px;

  color: #ffffff;
`;

export const ImageContainer = styled.div`
  width: 100%;
  align-self: flex-start;
  position: relative;
  display: flex;
`;

export const ProductContainer = styled.div`
  width: 160px;
  height: 160px;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;

  border-radius: 16px;
  border: 0;

  background: #fff;
`;

export const ProductImage = styled.img``;

export const BrandContainer = styled.div`
  width: 72px;
  height: 72px;

  border-radius: 16px;
  border: none;

  background: #000;

  overflow: hidden;

  position: absolute;
  top: 16px;
  left: 12px;
`;

export const Brand = styled.img`
  width: 72px;
  height: 72px;
`;

export const StoreContainer = styled.div`
  width: 72px;
  height: 72px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 16px;
  border: 0;

  overflow: hidden;

  background: #d4d1c8;

  position: absolute;
  bottom: 16px;
  right: 16px;
`;

export const Store = styled.img``;

export const RelativeContainer = styled.div`
  flex: 1;
  position: relative;
`;

export const SwitchImageLeftButton = styled.a`
  width: 48px;
  height: 48px;

  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 12px;
  top: calc(30% - 24px);

  cursor: pointer;

  img {
    transform: rotate(180deg);
  }
`;

export const SwitchImageRightButton = styled.a`
  width: 48px;
  height: 48px;

  background: rgba(0, 0, 0, 0.5);
  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 12px;
  top: calc(30% - 24px);

  cursor: pointer;
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
`;
