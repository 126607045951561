import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import heic2any from "heic2any";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import HumanModel from "../../assets/human-model.svg";

import {
  ActionButton,
  BottomTab,
  Header,
  ProductHeader,
  Wrapper,
} from "../../components";

import {
  Container,
  Description,
  InputPlaceholder,
  Title,
  UploadContainer,
  ExampleNavigation,
  ActionButtonContainer,
  CheckboxContainer,
  TabContainer,
  TabItem,
  BackgroundImage,
} from "./styles";

import { useProduct, useTryOn, useUser } from "../../contexts";
import { getProduct, storeProfileTryOnPhoto } from "../../services";
import { UserInterfaces } from "../../interfaces";
import SelectedImage from "./components/SelectedImage";
import UserImages from "./components/UserImages";

const Home = () => {
  const { user, userTryOnImages, fetchingImages } = useUser();
  const {
    selectedImages,
    setSelectedImages,
    setImageList,
    showMode,
    setShowMode,
    checkedImagesIndex,
    setCheckedImagesIndex,
  } = useTryOn();
  const { product, setProduct } = useProduct();
  const [uploading, setUploading] = useState(false);
  const [fetching, setFetching] = useState(true);

  const [loadingUrlToFile, setLoadingUrlToFile] = useState(false);

  const [saveImageOnProfile, setSaveImageOnProfile] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { productId } = useParams();

  const showUploadComponent =
    showMode === "single" && !uploading && !selectedImages.length;

  const showSelectedImageComponent =
    showMode === "single" && !uploading && !!selectedImages.length;

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          if (file.type === "image/heic") {
            setUploading(true);
            const fileURL = URL.createObjectURL(file);

            fetch(fileURL)
              .then((res) =>
                res.blob().then((blob) =>
                  heic2any({
                    blob,
                    toType: "image/jpeg",
                  }).then((result) => {
                    setUploading(false);

                    setSelectedImages([result]);
                  })
                )
              )
              .catch((e) => {
                console.log(e);
              });
          } else {
            setSelectedImages([file]);
          }
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [setSelectedImages]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/heif": [".heif"],
      "image/heic": [".heic"],
    },
    maxFiles: 1,
    multiple: false,
  });

  const removeImage = () => {
    setSelectedImages([]);
  };

  const uploadTryOnImage = async (image: File | Blob) => {
    try {
      const fileName = `${Date.now()}`;

      await storeProfileTryOnPhoto({
        uid: user.uid,
        fileName,
        image,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSelectedImagesUrl = async () => {
    setLoadingUrlToFile(true);

    const images: UserInterfaces.UserTryOnImage[] = [];

    userTryOnImages.forEach((item, index) => {
      if (!!checkedImagesIndex[index]) images.push(item);
    });

    const files: any[] = [];

    await Promise.all(
      images.map(async (image, index) => {
        if (image) {
          const response = await fetch(image.path);
          const blob = await response.blob();

          files.push({ blob, index });
        }
      })
    );

    files.sort((a, b) => a.index - b.index);

    setSelectedImages(files.map((file) => file.blob));

    setLoadingUrlToFile(false);
  };

  const sendImage = async () => {
    if (product) {
      setImageList([]);

      if (showMode === "multiple") {
        await fetchSelectedImagesUrl();
      }

      if (showMode === "single" && saveImageOnProfile) {
        await uploadTryOnImage(selectedImages[0]);
      }

      navigate("/result");
    }
  };

  const navigateToExamples = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate("/examples");
  };

  const checkImage = (key: number) => {
    if (checkedImagesIndex?.[key]) {
      const auxObj = { ...checkedImagesIndex };
      delete auxObj[key];
      setCheckedImagesIndex(auxObj);
    } else {
      setCheckedImagesIndex((prevState) => ({ ...prevState, [key]: true }));
    }
  };

  const checkAllImages = () => {
    setCheckedImagesIndex({
      ...Array.from(Array(userTryOnImages.length).keys()).map(() => true),
    });
  };

  useEffect(() => {
    const selectProduct = async (productId: string) => {
      try {
        setFetching(true);
        const response = await getProduct(productId);

        if (response.data) setProduct(response.data);
        setFetching(false);
      } catch (e) {
        setFetching(false);
        console.log(e);
      }
    };

    if (!productId) {
      setFetching(false);
      return;
    }
    if (!location.state) {
      location.state = {};
    }
    location.state.productId = productId;

    if (productId || location.state.productId) {
      selectProduct(productId || location.state.productId);
    } else {
      setFetching(false);
    }
  }, [location, productId, setProduct]);

  return (
    <Wrapper>
      <Header title="Virtual Try On" />

      <ProductHeader fetching={fetching} product={product} />

      <TabContainer>
        <TabItem
          onClick={() => setShowMode("single")}
          active={showMode === "single"}
        >
          Upload photo
        </TabItem>

        <TabItem
          onClick={() => setShowMode("multiple")}
          active={showMode === "multiple"}
        >
          Use Pre-saved Image
        </TabItem>
      </TabContainer>

      <Container>
        {showMode === "multiple" ? (
          <UserImages
            fetching={fetchingImages}
            images={userTryOnImages}
            checkedItems={checkedImagesIndex}
            checkImage={checkImage}
            checkAllImages={checkAllImages}
          />
        ) : (
          <>
            {uploading && <InputPlaceholder>Loading image...</InputPlaceholder>}

            {showSelectedImageComponent && (
              <SelectedImage
                selectedImage={selectedImages[0]}
                onClick={removeImage}
              />
            )}

            {showUploadComponent && (
              <UploadContainer {...getRootProps()}>
                <input
                  {...getInputProps()}
                  accept="image/jpeg, image/png, image/jpg, image/heif, .heic, .heif"
                />
                <Title>Drag Image Here or Click to Upload</Title>
                <Description>
                  Use a full body picture with head to toe in frame for best
                  results.{" "}
                  <ExampleNavigation onClick={navigateToExamples}>
                    See Examples
                  </ExampleNavigation>
                </Description>

                <BackgroundImage src={HumanModel} />
              </UploadContainer>
            )}

            {userTryOnImages.length <= 5 && (
              <CheckboxContainer>
                <input
                  type="checkbox"
                  id="saveImage"
                  onClick={() => setSaveImageOnProfile((value) => !value)}
                />
                <label htmlFor="saveImage">Save image for future try ons</label>
              </CheckboxContainer>
            )}
          </>
        )}
      </Container>

      <ActionButtonContainer>
        <ActionButton
          disabled={
            (!selectedImages?.length &&
              !Object.keys(checkedImagesIndex).length) ||
            !product
          }
          loading={loadingUrlToFile}
          onClick={sendImage}
        >
          Try it on
        </ActionButton>
      </ActionButtonContainer>
      <BottomTab />
    </Wrapper>
  );
};

export default Home;
