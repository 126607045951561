import React from "react";

import SneakerExample from "../../assets/sneaker.png";

import {
  Card,
  Description,
  InfoContainer,
  ItemImage,
  Store,
  Title,
} from "./styles";

interface ItemCardProps {
  title: string;
  description: string;
  image: string;
  store: string;
  url: string;
}

const ItemCard = ({ title, description, image, store, url }: ItemCardProps) => {
  return (
    <Card href={url} target="_blank">
      <ItemImage src={image} alt="" />
      <InfoContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Store>{store}</Store>
      </InfoContainer>
    </Card>
  );
};

export default ItemCard;
