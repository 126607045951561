export const getMonthName = (index: number) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  if (index >= 0 && index <= 11) {
    return months[index];
  } else {
    return "";
  }
};
