import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* align-items: center; */

  position: relative;

  padding: 24px;

  background: #222222;
`;

export const ProductName = styled.span`
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  text-align: center;

  padding: 0px 8px;

  color: #ffffff;
  visibility: hidden;

  position: absolute;
`;

export const Frame = styled.div`
  position: relative;
  cursor: pointer;
  overflow: visible;

  width: 112px;
  height: 112px;

  background: rgba(0, 0, 0, 0.25);

  border-radius: 16px;
  transition: 0.25s;

  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    outline: 4px solid #6117ff;
    border-radius: 16px;

    img {
      opacity: 0.25;
      backdrop-filter: blur(1px);
      transition: 0.25s;
    }
    ${ProductName} {
      visibility: visible;
    }
  }
`;

export const ImagesContainer = styled.div`
  position: relative;
  overflow: visible;
  width: 100%;
  height: 240px;

  padding-top: 8px;

  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const ProductImage = styled.img`
  width: auto;
  max-width: 112px;
  height: 112px;
  object-fit: cover;

  background: rgba(0, 0, 0, 0.25);

  border-radius: 16px;
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;

  margin: 0;
  padding-top: 40px;
  padding-bottom: 8px;
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
`;

export const DarkTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  padding-top: 40px;

  opacity: 0.5;
`;

export const DarkButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  cursor: pointer;
  align-self: center;

  color: #ffffff;

  background: transparent;
  border: none;
  position: absolute;
  bottom: 40px;

  opacity: 0.5;
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;
