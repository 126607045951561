import { Tooltip } from "react-tooltip";
import styled, { keyframes } from "styled-components";

interface ButtonProps {
  largeImage?: boolean;
}

interface ImageContainerProps {
  fetching: boolean;
}

interface ImageProps {
  show: boolean;
}

interface AlertContainerProps {
  visible: boolean;
}

interface ActionContainerProps {
  visible: boolean;
}

interface ButtonProps {
  disabled?: boolean;
  inverted?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 24px;
  overflow: visible;
`;

export const ProductContainer = styled.div`
  width: 100%;
  min-height: 112px;

  padding: 16px 26px;

  display: flex;
  align-items: center;
`;

export const ProductImage = styled.img`
  min-width: 60px;
  width: 60px;
  height: 60px;

  border-radius: 8px;
`;

export const ProductInfo = styled.div`
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1,
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
`;

export const ProductName = styled.h1`
  color: #fff;

  font-size: 13px;

  font-weight: 700;
  line-height: normal;

  margin: 0;

  color: #ffffff;

  @media (max-width: 425px) {
    font-size: 12px;
  }
`;

export const ProductDescription = styled.span`
  overflow: visible;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  /* identical to box height */

  padding-top: 8px;

  color: #ffffff;

  @media (max-width: 425px) {
    font-size: 14px;
  }
`;

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;

  left: 0;
  z-index: 2;
`;

export const ActionContainer = styled.div<ActionContainerProps>`
  width: 100%;
  max-width: 300px;

  display: flex;
  justify-content: space-around;
  gap: 24px;
  align-items: center;

  padding: 16px 0px;
  overflow: visible;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: 0.5s;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  position: relative;
  overflow: visible;

  @media (max-width: 425px) {
    position: initial;
  }

  > span {
    color: #fff;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;

    padding-top: 4px;
  }
`;
export const DownloadContainer = styled.a`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  position: relative;
  overflow: visible;

  @media (max-width: 425px) {
    position: initial;
  }

  > span {
    color: #fff;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;

    padding-top: 4px;
  }
`;

export const Button = styled.a<ButtonProps>`
  width: 40px;

  height: 40px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;

  background: ${({ inverted }) =>
    inverted ? "#FFC704" : "rgba(51, 51, 51, 0.5)"};
  border-radius: 100px;

  cursor: pointer;
  text-decoration: none;

  opacity: ${({ disabled }) => disabled && 0.5};
  pointer-events: ${({ disabled }) => disabled && "none"};

  img {
    width: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
    height: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
  }

  &:hover {
    background: ${({ inverted }) =>
      !inverted &&
      "linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%), #000000"};
  }

  span {
    color: #fe0501;
    font-weight: 800;
  }
`;

export const ImageContainer = styled.div<ImageContainerProps>`
  width: 100%;
  height: 100%;
  flex: 1;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: rgba(0, 0, 0, 0.25);

  img {
    filter: ${({ fetching }) => fetching && "blur(8px)"};
  }
`;

export const Image = styled.img<ImageProps>`
  height: 100%;
  max-height: 100%;
  width: 100%;
  object-fit: contain;

  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;

  z-index: 1;

  cursor: pointer;

  transition: 0.5s;
  opacity: ${({ show }) => (show ? 1 : 0)};
`;

export const AlertContainer = styled.div<AlertContainerProps>`
  max-width: 112px;
  padding: 10px 14px;

  background: #2c2c2c;
  border-radius: 80px;

  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;

  transition: 0.25s ease-in-out;
  opacity: ${({ visible }) => !visible && 0};
  visibility: ${({ visible }) => !visible && "hidden"};

  z-index: 11;
`;

export const AlertText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  margin: auto;
`;

export const ActionButtonContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 16px 8px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
`;

export const TapAlert = styled.div`
  position: absolute;
  margin: auto;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 6px 32px;
  opacity: 1;

  animation: ${fadeOut} 3s ease-in-out forwards;

  span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
  }
`;

export const ImageActionContainer = styled.button`
  border: 0;
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  align-items: center;
  gap: 8px;

  z-index: 999;

  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #000000;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 41px;
  cursor: pointer;

  transition: 0.1s;

  :hover {
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #ffffff;
    opacity: 0.5;
  }
`;

export const SubActionsContainer = styled.div<ActionContainerProps>`
  width: 100%;
  max-width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  opacity: ${({ visible }) => (visible ? 1 : 0)};
`;

export const HomeButton = styled.button`
  height: 40px;
  width: 40%;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.25);

  background: #1c1c1f;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  color: #d9d9d9;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const DownloadButton = styled.button`
  height: 40px;
  width: 60%;
  border-radius: 4px;
  border: none;
  background: linear-gradient(267deg, #7000ff 0%, #6117ff 100%);

  display: inline-flex;
  justify-content: center;
  align-items: center;

  color: #d9d9d9;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const CustomTooltip = styled(Tooltip)`
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  max-width: 200px;
  text-align: center;

  color: #d9d9d9;
  z-index: 2;
`;
