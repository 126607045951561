import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  display: flex;
  flex-direction: column;

  width: 300px;

  background: #191919;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.5);
  border-radius: 8px;

  z-index: 10;

  * {
    overflow: visible;
    text-decoration: none;
  }

  > img {
    width: 40px;
    height: 40px;
    margin: auto;
  }

  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #ffffff;
  }

  @media (max-width: 425px) {
    min-width: 100vw;
    width: 100%;

    padding-bottom: 16px;

    margin: auto;
    bottom: 0;

    span {
      text-align: center;
      font-size: 11px;

      padding: 0;
      line-height: 19px;
    }
    button > span {
      padding-top: 8px;
      display: block;
    }

    img,
    svg {
      width: 48px;
      height: 48px;
    }

    button {
      min-width: 70px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div,
  a {
    display: flex;
    align-items: center;
  }

  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 425px) {
    button {
      width: 100%;
      text-align: left;
      padding: 12px 24px !important; // to override the library's 0px padding
    }
  }

  /* @media (max-width: 425px) {
    gap: 12px;
    min-width: 100vw;
    width: 100%;
    flex-direction: row;
    padding-right: 0;

    margin: auto;
    bottom: 0;
  } */
`;

export const Button = styled.div`
  width: 40px;
  height: 40px;
  padding-top: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%), #000000;
  border-radius: 40px;

  img {
    width: 35px;
    height: 35px;
    margin: auto;
  }

  @media (max-width: 425px) {
    img {
      width: 40px;
      height: 40px;
      margin: auto;
    }
  }
`;

export const SaveImageButton = styled.a`
  width: 40px;
  height: 40px;
  padding-top: 2px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%), #000000;
  border-radius: 20px;

  img {
    width: 35px;
    height: 35px;
    margin: auto;
  }
`;

export const CopyLinkButton = styled.div`
  cursor: pointer;
  @media (min-width: 425px) {
    width: 100%;
    text-align: left;
    padding: 12px 24px;
  }

  @media (max-width: 425px) {
    min-width: 70px;
    display: flex;
    flex-direction: column;

    padding: 0px 4px;

    div {
      width: 48px;
      height: 48px;
      margin: auto;
    }

    span {
      padding-top: 8px;
    }
  }
`;

export const AnchorButton = styled.a`
  @media (min-width: 425px) {
    width: 100%;
    text-align: left;
    padding: 12px 24px;
  }

  @media (max-width: 425px) {
    min-width: 70px;
    padding: 0px 4px;

    display: flex;
    flex-direction: column;

    div {
      width: 48px;
      height: 48px;
      margin: auto;
    }

    span {
      padding-top: 8px;
    }
  }
`;

export const Title = styled.h1`
  padding: 0;
  margin: 0;

  width: 100%;

  color: #fff;

  font-size: 12px;

  font-weight: 800;
  line-height: normal;

  text-align: center;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  border: 0;
  padding: 0;

  width: 16px;
  height: 16px;

  background: transparent;

  img {
    width: 75%;
    height: 75%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DropdownHeader = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  padding: 16px 16px 16px 16px;

  @media (min-width: 426px) {
    display: none;
  }
`;

export const ResponsiveText = styled.span`
  @media (max-width: 425px) {
    display: none;
  }
`;

export const ButtonTitle = styled.span`
  padding-left: 16px;
`;
