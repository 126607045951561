import styled from "styled-components";

interface ErrorContainerProps {
  visible: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 40px;

  background: #222222;
`;

export const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin: 0;
  padding: 0;

  color: #ffffff;
`;

export const EmailIconImage = styled.img`
  width: 18px;
  height: 18px;

  margin-right: 12px;
`;

export const LightText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  padding-top: 24px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ButtontText = styled.span`
  color: #fff;
  text-align: center;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  color: #ffffff;

  padding-top: 24px;

  opacity: 0.5;

  cursor: pointer;
`;

export const DarkText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;

  color: #ffffff;

  opacity: 0.5;

  position: absolute;
  bottom: 40px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Form = styled.form`
  width: 100%;

  padding-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;

  background: #111111;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 4px;

  outline: none;

  :focus-within {
    border: 1px solid #ffffff;

    img {
      opacity: 1;
    }
  }
  img {
    min-width: 24px;
    height: 24px;
    padding-right: 16px;

    margin: auto;

    opacity: 0.25;

    cursor: pointer;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;

  background: #111111;
  border: none;
  outline: none;

  /* &:focus {
    border: 1px solid #ffffff;
  } */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;

  padding: 12px 12px 12px 16px;
`;

export const ErrorContainer = styled.div<ErrorContainerProps>`
  max-width: 318px;
  padding: 10px 14px;

  background: #2c2c2c;
  border-radius: 80px;

  position: absolute;
  bottom: 40px;

  transition: 0.25s ease-in-out;
  opacity: ${({ visible }) => !visible && 0};
  visibility: ${({ visible }) => !visible && "hidden"};
`;

export const ErrorText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  display: flex;
  align-items: center;

  color: #ff5050;
`;
