import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import heic2any from "heic2any";
import { useNavigate, useParams } from "react-router-dom";
import { ActionButton, Header, ProductHeader, Wrapper } from "../../components";

import CloseIcon from "../../assets/close.svg";
import FullBody1 from "../../assets/FullBodyExamples/full-body1.png";
import FullBody2 from "../../assets/FullBodyExamples/full-body2.png";
import FullBody3 from "../../assets/FullBodyExamples/full-body3.png";
import FullBody4 from "../../assets/FullBodyExamples/full-body4.png";
import FullBody5 from "../../assets/FullBodyExamples/full-body5.png";

import {
  ActionContainer,
  Container,
  DarkText,
  Description,
  ExampleNavigation,
  ImageAction,
  ImageContainer,
  ImagesRow,
  InputPlaceholder,
  SelectedImageContainer,
  Title,
  UploadContainer,
  Image,
  ButtonContainer,
} from "./styles";

import { useProduct, useTryOn } from "../../contexts";
import { getProduct } from "../../services";

const ShareHome = () => {
  const { productId } = useParams();
  const { setSelectedImages, selectedImages, resetTryOn } = useTryOn();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState<boolean>(false);

  const { product, setProduct } = useProduct();

  const onDrop = useCallback((acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        if (file.type === "image/heic") {
          const fileURL = URL.createObjectURL(file);

          fetch(fileURL)
            .then((res) =>
              res.blob().then((blob) =>
                heic2any({
                  blob,
                  toType: "image/jpeg",
                }).then((result) => {
                  setSelectedImages([result]);
                })
              )
            )
            .catch((e) => {
              console.log(e);
            });
        } else {
          setSelectedImages([file]);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg"],
      "image/jpg": [".jpg"],
      "image/heif": [".heif"],
      "image/heic": [".heic"],
    },
    maxFiles: 1,
    multiple: false,
  });

  const removeImage = () => {
    setSelectedImages([]);
  };

  const navigateToResult = () => {
    if (product) {
      navigate(`/share/${product.id}/result`);
    }
  };

  const navigateToExamples = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    navigate("/examples");
  };

  const pickImage = async (path: string) => {
    const response = await fetch(path);
    const blob = await response.blob();
    const file = new File([blob], "selectedImage", { type: "image/png" });

    setSelectedImages([file]);
  };

  const navigateToCover = () => {
    resetTryOn();

    if (product) {
      navigate(`/share/${product.id}`);
    }
  };

  const selectProduct = async (productId: string) => {
    try {
      setFetching(true);
      const response = await getProduct(productId);

      if (response.data) setProduct(response.data);
      setFetching(false);
    } catch (e) {
      setFetching(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (!product && productId) {
      selectProduct(productId);
    }
  }, [product, productId]);

  return (
    <Wrapper>
      <Header back title="Stylar" />

      <ProductHeader
        fetching={fetching}
        product={product}
        disabled
        // goBackAction={navigateToCover}
      />

      <Container>
        <ImageContainer>
          {!!selectedImages[0] ? (
            <SelectedImageContainer>
              <ActionContainer>
                <ImageAction onClick={removeImage}>
                  <img src={CloseIcon} alt="" />
                </ImageAction>
              </ActionContainer>
              <Image src={`${URL.createObjectURL(selectedImages[0])}`} alt="" />
            </SelectedImageContainer>
          ) : (
            <UploadContainer {...getRootProps()}>
              <Title>Select photo</Title>
              <Description>
                Use a full body picture with head to toe in frame for best
                results.{" "}
                <ExampleNavigation onClick={navigateToExamples}>
                  See Examples
                </ExampleNavigation>
              </Description>

              <InputPlaceholder>
                <input
                  {...getInputProps()}
                  accept="image/jpeg, image/png, image/jpg, image/heif, .heic, .heif"
                />
                Tap to Upload
              </InputPlaceholder>
            </UploadContainer>
          )}
        </ImageContainer>
      </Container>

      <DarkText>Or use a sample image below:</DarkText>
      <ImagesRow>
        <img src={FullBody1} alt="" onClick={() => pickImage(FullBody1)} />
        <img src={FullBody2} alt="" onClick={() => pickImage(FullBody2)} />
        <img src={FullBody3} alt="" onClick={() => pickImage(FullBody3)} />
        <img src={FullBody4} alt="" onClick={() => pickImage(FullBody4)} />
        <img src={FullBody5} alt="" onClick={() => pickImage(FullBody5)} />
      </ImagesRow>

      <ButtonContainer>
        <ActionButton disabled={!selectedImages[0]} onClick={navigateToResult}>
          Try it on
        </ActionButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default ShareHome;
