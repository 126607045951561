import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Header, ActionButton, BottomTab, Wrapper } from "../../components";

import {
  RelativeContainer,
  Brand,
  Container,
  Description,
  ImageContainer,
  ProductImage,
  Store,
  Title,
  BrandContainer,
  StoreContainer,
  ProductContainer,
  Spinner,
} from "./styles";

import { useProduct } from "../../contexts";
import { getProduct } from "../../services";

const Product: React.FC = () => {
  const { product, setProduct } = useProduct();
  const [fetching, setFetching] = useState(true);

  const { state } = useLocation();
  const navigate = useNavigate();

  const navigateToResult = () => {
    navigate("/result", {
      state: { ...state, product },
    });
  };

  const selectProduct = async (productId: string) => {
    try {
      setFetching(true);
      const response = await getProduct(productId);

      if (response.data) setProduct(response.data);
      setFetching(false);
    } catch (e) {
      setFetching(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (state.productId) selectProduct(state.productId);
  }, [state.productId]);

  return (
    <Wrapper>
      <Header title="Virtual Try On" />
      <Container>
        {fetching ? (
          <Spinner />
        ) : (
          <ImageContainer>
            <ProductContainer>
              <ProductImage src={product?.icon} />
            </ProductContainer>
            <RelativeContainer>
              <BrandContainer>
                <Brand src={product?.brand_icon} />
              </BrandContainer>

              <StoreContainer>
                <Store src={product?.site_icon} />
              </StoreContainer>
            </RelativeContainer>
          </ImageContainer>
        )}

        <Title>{product?.collection}</Title>

        <Description>{product?.name}</Description>

        <ActionButton disabled={fetching} onClick={navigateToResult}>
          Continue
        </ActionButton>
      </Container>
      <BottomTab />
    </Wrapper>
  );
};

export default Product;
