import { createContext, useContext, useEffect, useState } from "react";
import { useTryOn } from "./TryOnContext";
import { useProduct } from "./ProductContext";
import { useUser } from "./UserContext";
import { saveTryOnImages, updateTryOnImages } from "../services";
import { ProductInterfaces } from "../interfaces";
import { useRandomizer } from "./RandomizerContext";

interface SaveResultContextProps {
  savingResult: boolean;
  resultId: string;
}

const SaveResultContext = createContext({} as SaveResultContextProps);

const SaveResultProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    selectedImages,
    imageList,
    fetchingResult,
    pendingSaving,
    updating,
    setUpdating,
    setPendingSaving,
  } = useTryOn();
  const { modifierId, modifierName } = useRandomizer();
  const { product } = useProduct();
  const { user } = useUser();

  const [savingResult, setSavingResult] = useState(false);
  const [resultId, setResultId] = useState("");

  useEffect(() => {
    const saveResult = async ({
      product,
      user,
      modifierId,
      modifierName,
    }: {
      product: ProductInterfaces.Product;
      user: {
        uid: string;
        displayName: string;
      };
      modifierId?: number | null;
      modifierName?: string;
    }) => {
      try {
        const { id, name, collection } = product;

        const document = await saveTryOnImages({
          uid: user.uid,
          displayName: user.displayName || "",
          productId: id,
          productName: name,
          collectionName: collection,
          modifierId,
          modifierName,
          images: imageList.map((image) => ({
            result: image.result,
            original: image.original,
          })),
        });

        localStorage.setItem("resultId", document.id);
        setPendingSaving(0);
        setResultId(document.id);
        console.log(document.id);
      } catch (e) {
        console.log(e);
      }
    };
    if (
      !fetchingResult &&
      selectedImages.length > 0 &&
      selectedImages.length === imageList.length &&
      !!product &&
      !!user &&
      pendingSaving > 0 &&
      pendingSaving === imageList.length
    ) {
      if (!imageList.some((image) => !image)) {
        if (updating) {
          updateTryOnImages({
            documentId: resultId,
            images: imageList.map((image) => ({
              result: image.result,
              original: image.original,
            })),
          });
          setPendingSaving(0);
          setUpdating(false);
        } else {
          saveResult({
            product,
            user: {
              displayName: user.displayName || "",
              uid: user.uid,
            },
            modifierId: modifierId ?? null,
            modifierName,
          });
        }
      }
    }
  }, [
    product,
    user,
    selectedImages,
    imageList,
    fetchingResult,
    modifierId,
    modifierName,
    pendingSaving,
    updating,
    setUpdating,
    setPendingSaving,
    resultId,
  ]);

  return (
    <SaveResultContext.Provider value={{ resultId, savingResult }}>
      {children}
    </SaveResultContext.Provider>
  );
};

const useSaveResult = () => {
  const context = useContext(SaveResultContext);

  return context;
};

export { useSaveResult, SaveResultProvider };
