import React, { useEffect, useState } from "react";
import { Header, Wrapper } from "../../components";

import {
  Container,
  DarkButton,
  DarkTitle,
  Description,
  Frame,
  ImagesContainer,
  ProductImage,
  ProductName,
  Spinner,
  Title,
} from "./styles";
import { useOnboarding, useUser } from "../../contexts";
import { api } from "../../services";
import { ProductInterfaces } from "../../interfaces";
import { useNavigate } from "react-router-dom";

const Onboarding = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [productList, setProductList] = useState<ProductInterfaces.Product[]>(
    []
  );
  const [fetching, setFetching] = useState(true);
  const { disableOnboarding, enableOnboarding } = useOnboarding();

  const getProducts = async () => {
    try {
      setFetching(true);

      const sneakers = (await api.get("all-products")).data;
      if (sneakers) setProductList(sneakers);

      setFetching(false);
    } catch (e) {
      setFetching(false);
      console.log(e);
    }
  };

  const navigateToHome = (productId: string) => {
    enableOnboarding();
    navigate(`/try/${productId}`);
  };

  const skipToHome = () => {
    disableOnboarding();
    navigate(`/try/${productList[9].id}`);
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Wrapper>
      <Header title="Getting started" />
      <Container>
        <Title>Welcome, {user.displayName?.split(" ")[0]}!</Title>
        <Description>
          You can use us to virtually try things on. Let’s start with some fresh
          kicks.
        </Description>

        <DarkTitle>Select a pair to try on:</DarkTitle>

        <ImagesContainer>
          {fetching ? (
            <Spinner />
          ) : (
            [...productList.slice(1, 6), productList[9]]
              .reverse()
              .map((product: ProductInterfaces.Product) => (
                <Frame
                  key={product.id}
                  onClick={() => {
                    navigateToHome(product.id);
                  }}
                >
                  <ProductImage src={product.icon} alt="" />
                  <ProductName>
                    {product.collection} - {product.name}
                  </ProductName>
                </Frame>
              ))
          )}
        </ImagesContainer>

        <DarkButton onClick={skipToHome}>Skip Tutorial</DarkButton>
      </Container>
    </Wrapper>
  );
};

export default Onboarding;
