import React, { useEffect, useRef, useState } from "react";
import { ActionButton } from "../../../../components";

import { Container, Form, InputContainer } from "./styles";
import { validateEmail } from "../../../../utils";
import { setLead } from "../../../../services";

interface EmailFormProps {
  visible: boolean;
  onClose: () => void;
}

const EmailForm = ({ visible, onClose }: EmailFormProps) => {
  const [value, setValue] = useState<string>("");
  const [validEmail, setValidEmail] = useState<boolean>(false);
  const ref = useRef<any>();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (validateEmail(e.target.value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await setLead(value);
      await localStorage.setItem("userRegistered", JSON.stringify(value));

      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClose();
      }
    };

    if (visible) {
      document.addEventListener("click", checkIfClickedOutside);
    }
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [onClose, visible]);

  return (
    <Container hidden={!visible}>
      <Form action="" onSubmit={handleSubmit} ref={ref}>
        <h1>Please enter email to continue</h1>

        <InputContainer>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g opacity="0.25" clipPath="url(#clip0_257_14167)">
              <path
                d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_257_14167">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>

          <input
            type="text"
            placeholder="Email"
            value={value}
            onChange={handleInput}
          />

          {validEmail && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clipPath="url(#clip0_257_14199)">
                <path
                  d="M8.00004 1.33337C4.32004 1.33337 1.33337 4.32004 1.33337 8.00004C1.33337 11.68 4.32004 14.6667 8.00004 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00004C14.6667 4.32004 11.68 1.33337 8.00004 1.33337ZM6.66671 11.3334L3.33337 8.00004L4.27337 7.06004L6.66671 9.44671L11.7267 4.38671L12.6667 5.33337L6.66671 11.3334Z"
                  fill="#6DE28D"
                />
              </g>
              <defs>
                <clipPath id="clip0_257_14199">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
        </InputContainer>

        <ActionButton disabled={!validEmail} type="submit">
          Continue
        </ActionButton>
      </Form>
    </Container>
  );
};

export default EmailForm;
