import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  ActionButton,
  Header,
  Loading,
  ProductHeader,
  ShareDropdown,
  Wrapper,
} from "../../components";
import EmailForm from "./components/EmailForm";

import {
  useProduct,
  useTryOn,
  useRandomizer,
  useSaveResult,
} from "../../contexts";

import {
  getImagesResult,
  getShareInfo,
  listImagesResultLength,
} from "../../services";

import RegenerateIcon from "../../assets/regenerate.svg";
import CloseIcon from "../../assets/close.svg";
import ShareIcon from "../../assets/share.svg";
import EditIcon from "../../assets/edit.svg";
import FullscreenIcon from "../../assets/fullscreen.svg";

import {
  ActionContainer,
  Button,
  ButtonContainer,
  Container,
  ImageContainer,
  LoadingContainer,
  Image,
  AlertContainer,
  AlertText,
  Spinner,
  ActionButtonContainer,
  ImageWrapper,
  TapAlert,
  ImageActionContainer,
  SubActionsContainer,
  HomeButton,
  DownloadButton,
  CustomTooltip,
} from "./styles";
import ReactGA from "react-ga4";

const ShareResult = () => {
  const navigate = useNavigate();
  const { productId } = useParams();

  const [shareInfo, setShareInfo] = useState<any>();
  const [originalSharedImage, setOriginalSharedImage] = useState("");
  const [resultSharedImage, setresultSharedImage] = useState("");
  const { product, selectProduct, fetchingProduct } = useProduct();
  const { resultId } = useSaveResult();
  const {
    getResult,
    fetchingResult,
    imageList,
    selectedImages,
    setSelectedImages,
    getRandomResult,
    resetTryOn,
  } = useTryOn();
  const {
    randomizer,
    enableRandomizer,
    modifierId,
    modifierName,
    handleModifierId,
    handleModifierName,
  } = useRandomizer();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fetchingSharedImage, setFetchingSharedImage] = useState(false);
  const [savingResult, setSavingResult] = useState(true);
  const [showOriginal, setShowOriginal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [shareVisible, setShareVisible] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const location = useLocation();
  const shareId = new URLSearchParams(location.search).get("id");
  const url = `${window.location.origin}/fullscreen?id=${resultId}`;

  const getRandomData = async (modifierId?: number) => {
    try {
      setSavingResult(true);
      setShowOriginal(false);
      ReactGA.event({
        category: "user",
        action: "get_results_random",
      });

      if (productId) {
        const result = await getRandomResult(productId, modifierId);

        if (result) {
          handleModifierId(result[0].modifierId);
          handleModifierName(result[0].modifierName);
        }
      }
    } catch (e) {
      console.log(e);
      navigate("/error");
    }
  };

  const getData = async () => {
    try {
      setSavingResult(true);
      setShowOriginal(false);
      ReactGA.event({
        category: "user",
        action: "get_results",
      });

      if (productId) {
        await getResult(productId);
      }
    } catch (e) {
      console.log(e);
      navigate("/error");
    }
  };

  const toggleShowOriginal = () => {
    setShowOriginal((value) => !value);
  };

  const toggleShareDropdown = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShareVisible((value) => !value);
  };

  const editImage = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    setSelectedImages([]);
    ReactGA.event({
      category: "user",
      action: "use_new_image",
    });
    navigate(`/share/${productId}/start`);
  };

  const handleCopyLink = async (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    try {
      ReactGA.event({
        category: "user",
        action: "copy_link",
      });
      e.stopPropagation();
      if (resultId || shareId) {
        navigator.clipboard.writeText(url);

        setAlertMessage("Link Copied!");

        setShowAlert(true);

        setTimeout(() => setShowAlert(false), 3000);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getSharedImages = async () => {
    try {
      setFetchingSharedImage(true);
      if (shareId) {
        const length = await listImagesResultLength(shareId);

        const shareData = await getShareInfo(shareId);

        if (shareData) {
          setShareInfo(shareData);
        }

        if (shareData && !shareData.originalImage && !shareData.resultImage) {
          await Promise.all(
            Array.from(Array(length).keys()).map(async (i) => {
              const { original, result } = await getImagesResult(shareId, i);

              setOriginalSharedImage(original);
              setresultSharedImage(result);
            })
          );
        } else if (shareData) {
          setOriginalSharedImage(shareData.originalImage);
          setresultSharedImage(shareData.resultImage);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFetchingSharedImage(false);
    }
  };

  const tryItYourself = () => {
    var action = "try_it_yourself";
    if (!!shareId && !!shareInfo.modifierId) {
      enableRandomizer();
      handleModifierId(shareInfo.modifierId);
      action = "try_it_yourself_random";
    }
    ReactGA.event({
      category: "user",
      action: action,
    });

    navigate(`/share/${productId}/start`);
  };

  const handleRegenerate = () => {
    if (randomizer) {
      if (modifierId) {
        getRandomData(modifierId);
      } else {
        getRandomData();
      }
    } else {
      getData();
    }
  };

  const handleSurprise = () => {
    ReactGA.event({
      category: "user",
      action: "try_it_yourself",
    });

    handleModifierId(null);
    enableRandomizer();

    getRandomData();
  };

  const handleCustomName = (): string => {
    if (modifierName) {
      return modifierName;
    }
    if (shareInfo?.modifierName) {
      return shareInfo.modifierName;
    }
    return "";
  };

  const navigateToCover = () => {
    resetTryOn();

    if (product) {
      navigate(`/share/${product.id}`);
    }
  };

  const navigateToHome = () => {
    navigate("/demo");
  };

  const navigateToFullScreen = async () => {
    await localStorage.setItem("imageList", JSON.stringify(imageList));
    await localStorage.setItem("productId", JSON.stringify(product?.id));
    window.open("/fullscreen", "_blank", "noreferrer");
  };

  useEffect(() => {
    if (!!shareId) {
      getSharedImages();

      ReactGA.event({
        category: "user",
        action: "open_share_link",
      });
    } else {
      if (randomizer) {
        getRandomData();
      } else {
        getData();
      }
    }
  }, [shareId, product]);

  useEffect(() => {
    setSavingResult(false);
  }, [product, fetchingResult, imageList, shareId]);

  useEffect(() => {
    if (!product && productId) {
      selectProduct(productId);
    }
  }, [product, productId, selectProduct]);

  console.log(imageLoaded, fetchingResult, savingResult);

  return (
    <Wrapper>
      <Header title={"Stylar"} />

      {/* <ProductHeader
        fetching={fetchingProduct}
        product={product}
        customName={handleCustomName()}
        goBackAction={navigateToCover}
        disabled={fetchingResult || savingResult}
      /> */}

      <Container>
        <ImageContainer
          fetching={
            !imageLoaded ||
            fetchingResult ||
            fetchingSharedImage ||
            savingResult
          }
        >
          {(!imageLoaded || fetchingResult || savingResult) && !shareId && (
            <LoadingContainer>
              <Loading transparent />
            </LoadingContainer>
          )}

          <ImageWrapper>
            {!fetchingSharedImage && <Spinner />}
            {(selectedImages[0] || originalSharedImage) && (
              <>
                <Image
                  show={
                    !imageLoaded ||
                    showOriginal ||
                    fetchingResult ||
                    savingResult
                  }
                  src={
                    !shareId
                      ? URL.createObjectURL(selectedImages[0])
                      : shareInfo.originalImage || originalSharedImage
                  }
                  alt=""
                  onClick={toggleShowOriginal}
                />
              </>
            )}

            {(imageList[0]?.resultURL || resultSharedImage) && (
              <Image
                show={!fetchingResult && !showOriginal && !savingResult}
                src={
                  !shareId
                    ? imageList[0]?.resultURL || ""
                    : shareInfo.resultImage || resultSharedImage
                }
                alt=""
                onLoad={() => setImageLoaded(true)}
                onClick={toggleShowOriginal}
              />
            )}

            {imageLoaded && !fetchingResult && !savingResult && (
              <TapAlert onClick={toggleShowOriginal}>
                <span>Tap to toggle</span>
              </TapAlert>
            )}
          </ImageWrapper>
          {!!shareId && imageLoaded && !fetchingResult && (
            <ActionButtonContainer>
              <ActionButton onClick={tryItYourself}>
                Try it yourself
              </ActionButton>
            </ActionButtonContainer>
          )}
        </ImageContainer>

        <ActionContainer
          visible={imageLoaded && !fetchingResult && !savingResult}
        >
          <CustomTooltip id="tooltip" />
          <ButtonContainer>
            <Button
              disabled={
                (!resultId && !shareId) ||
                !imageLoaded ||
                fetchingResult ||
                savingResult
              }
              largeImage
              onClick={navigateToFullScreen}
              data-tooltip-id="tooltip"
              data-tooltip-content="Full Screen Mode"
              data-tooltip-place="top"
            >
              <img src={FullscreenIcon} alt="" />
            </Button>
          </ButtonContainer>

          <ButtonContainer>
            <Button
              disabled={
                (!resultId && !shareId) ||
                !imageLoaded ||
                fetchingResult ||
                savingResult
              }
              onClick={editImage}
              data-tooltip-id="tooltip"
              data-tooltip-content="Edit Reference Image"
              data-tooltip-place="top"
            >
              <img src={EditIcon} alt="" />
            </Button>
          </ButtonContainer>

          {!shareId && (
            <ButtonContainer>
              <Button
                onClick={handleRegenerate}
                disabled={!imageLoaded || fetchingResult || savingResult}
                data-tooltip-id="tooltip"
                data-tooltip-content="Regenerate"
                data-tooltip-place="top"
              >
                <img src={RegenerateIcon} alt="" />
              </Button>
            </ButtonContainer>
          )}

          <ButtonContainer>
            <Button
              largeImage
              onClick={toggleShareDropdown}
              disabled={!imageLoaded || fetchingResult || savingResult}
              data-tooltip-id="tooltip"
              data-tooltip-content="Share"
              data-tooltip-place="top"
            >
              <img src={ShareIcon} alt="" />
            </Button>
            <ShareDropdown
              visible={shareVisible}
              id={shareId || resultId}
              medias={imageList.map((image) => image.resultURL)}
              onClose={() => setShareVisible(false)}
              onLinkCopied={handleCopyLink}
              allowDownload={!isMobile}
            />
          </ButtonContainer>
        </ActionContainer>

        <SubActionsContainer
          visible={imageLoaded && !fetchingResult && !savingResult}
        >
          <HomeButton onClick={navigateToHome}>Back to home</HomeButton>
          <DownloadButton>Try thousands more</DownloadButton>
        </SubActionsContainer>
      </Container>

      <AlertContainer visible={showAlert}>
        <AlertText>{alertMessage}</AlertText>
      </AlertContainer>

      <EmailForm
        visible={emailModalVisible}
        onClose={() => setEmailModalVisible(false)}
      />
    </Wrapper>
  );
};

export default ShareResult;
