import styled from "styled-components";

export const SelectedImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  flex: 1;

  display: flex;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.25);

  overflow: hidden;
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;

  transition: 0.2s;
`;

export const ImageAction = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #000000;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 41px;
  cursor: pointer;

  transition: 0.1s;
  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #ffffff;
  }
`;

export const ActionContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  align-items: center;
  gap: 8px;

  :hover ~ img {
    opacity: 0.5;
  }
`;
