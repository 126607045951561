import {
  collection,
  addDoc,
  doc,
  deleteDoc,
  updateDoc,
  query,
  where,
  getDocs,
  serverTimestamp,
  getDoc,
  orderBy,
} from "firebase/firestore";

import { TryOnInterfaces } from "../interfaces";
import { firestore } from "./firebase";

export const addToSaved = async ({
  product,
  uid,
  images: { original, result },
  shareId,
  shareIndex,
}: TryOnInterfaces.SaveReq) => {
  const docRef = await addDoc(collection(firestore, "saved"), {
    product,
    uid,
    images: { original, result },
    shareId,
    shareIndex,
    createdAt: serverTimestamp(),
  });

  return docRef.id;
};

export const getSavedById = async (id: string) => {
  const docSnap = await getDoc(doc(firestore, "saved", id));

  if (docSnap.exists()) {
    return docSnap.data() as TryOnInterfaces.Saved;
  } else {
    return false;
  }
};

export const getSavedByUser = async (uid: string) => {
  const q = query(
    collection(firestore, "saved"),
    where("uid", "==", uid),
    orderBy("createdAt")
  );

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    doc.data();
  });

  return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
};

export const removeFromSaved = async (savedId: string) => {
  await deleteDoc(doc(firestore, "saved", savedId));
};

export const setShareSaved = async ({
  shareId,
  images,
}: {
  shareId: string;
  images: {
    original: string;
    result: string;
    saved: boolean;
  }[];
}) => {
  const docRef = doc(firestore, "shares", shareId);

  await updateDoc(docRef, {
    images,
  });
};
