import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { Button, Spinner } from "./styles";

// import { Container } from './styles';

interface ActionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  children: ReactNode;
  dark?: boolean;
  loading?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  onClick,
  children,
  dark,
  loading,
  ...rest
}: ActionButtonProps) => {
  return (
    <Button
      {...rest}
      onClick={onClick}
      dark={dark}
      disabled={rest.disabled || loading}
    >
      {loading ? <Spinner /> : children}
    </Button>
  );
};

export default ActionButton;
