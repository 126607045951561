import React, { useEffect, useRef } from "react";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import JSZip from "jszip";
import { saveAs } from "file-saver";

import LinkIcon from "../../assets/link.svg";
import SMSIcon from "../../assets/sms.svg";
import CloseIcon from "../../assets/close.svg";
import DownloadIcon from "../../assets/download.svg";

import {
  Container,
  Button,
  CopyLinkButton,
  AnchorButton,
  Title,
  Content,
  CloseButton,
  DropdownHeader,
  ResponsiveText,
  ButtonTitle,
} from "./styles";
import ReactGA from "react-ga4";
import { downloadImage, getMobileOS } from "../../utils";

interface ShareDropdownProps {
  id: string;
  medias: string[];
  visible: boolean;
  allowDownload?: boolean;
  onLinkCopied?: Function;
  onClose: () => void;
}

const ShareDropdown = ({
  id,
  medias,
  visible,
  allowDownload,
  onLinkCopied,
  onClose,
}: ShareDropdownProps) => {
  const url = `${window.location.origin}/fullscreen?id=${id}`;
  const zip = new JSZip();
  const photoZip = zip.folder("images");

  const ref = useRef<any>();

  const downloadImages = async () => {
    ReactGA.event({
      category: "user",
      action: "download_image",
    });
    if (medias.length === 1) {
      downloadImage(medias[0]);
      return;
    }
    await Promise.all(
      medias.map(async (media, index) => {
        const response = await fetch(media);
        const blob = await response.blob();

        photoZip?.file(`${index + 1}.png`, blob);
      })
    );

    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(content, "images");
    });
  };

  useEffect(() => {
    const checkIfClicked = (e: any) => {
      onClose();
    };

    document.addEventListener("click", checkIfClicked);

    return () => {
      document.removeEventListener("click", checkIfClicked);
    };
  }, [onClose]);

  const handleCopyLink = (e: React.MouseEvent<HTMLDivElement>) => {
    ReactGA.event({
      category: "user",
      action: "copy_link",
    });
    onClose();
    if (onLinkCopied) {
      onLinkCopied(e);
    } else {
      navigator.clipboard.writeText(url);
    }
  };

  const getSmsUrl = () => {
    return `sms:${getMobileOS() === "Android" ? "?" : "&"}body=${url}`;
  };

  return (
    <Container data-testid="main-container" ref={ref} hidden={!visible}>
      <DropdownHeader>
        <Title>Send to</Title>

        <CloseButton onClick={onClose}>
          <img src={CloseIcon} alt="" />
        </CloseButton>
      </DropdownHeader>

      <Content>
        <CopyLinkButton data-testid="copy-link-button" onClick={handleCopyLink}>
          <Button>
            <img src={LinkIcon} alt="" />
          </Button>

          <ButtonTitle>Copy Link</ButtonTitle>
        </CopyLinkButton>

        {allowDownload && (
          <AnchorButton onClick={downloadImages}>
            <Button>
              <img src={DownloadIcon} alt="" />
            </Button>

            <ButtonTitle>Save Image{medias.length > 1 && "s"}</ButtonTitle>
          </AnchorButton>
        )}

        <AnchorButton data-testid="sms" href={getSmsUrl()}>
          <img src={SMSIcon} alt="" />

          <ButtonTitle>
            <ResponsiveText>Share to </ResponsiveText>SMS
          </ButtonTitle>
        </AnchorButton>

        <WhatsappShareButton url={url}>
          <WhatsappIcon size={40} round />
          <ButtonTitle>
            <ResponsiveText>Share to </ResponsiveText>Whatsapp
          </ButtonTitle>
        </WhatsappShareButton>

        <TwitterShareButton url={url}>
          <TwitterIcon size={40} round />
          <ButtonTitle>
            <ResponsiveText>Share to </ResponsiveText>Twitter
          </ButtonTitle>
        </TwitterShareButton>
      </Content>
    </Container>
  );
};

export default ShareDropdown;
