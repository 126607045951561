import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ActionButton, BottomTab, Header, Wrapper } from "../../components";
import { Container, Description, TextContainer, Title } from "./styles";
import { useTryOn } from "../../contexts";

const Error: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { resetTryOn } = useTryOn();

  const navigateToHome = () => {
    resetTryOn();
    navigate("/share/mschf-yellow-crocs", { state: state });
  };

  const tryAgain = () => {
    navigate("/result", { state: state });
  };

  return (
    <Wrapper>
      <Header title="Virtual Try On" />
      <Container>
        <TextContainer>
          <Title>Oops!</Title>

          <Description>
            Sorry, but it looks like the fitting rooms are currently occupied.
            Please try again when the traffic calms down!
          </Description>
        </TextContainer>

        {/* <ActionButton onClick={tryAgain}>Retry</ActionButton> */}
        <ActionButton dark onClick={navigateToHome}>
          Continue to Home
        </ActionButton>
      </Container>
      <BottomTab />
    </Wrapper>
  );
};

export default Error;
