import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { firestore, firebaseStorage } from "./index";
import { UserInterfaces } from "../interfaces";

export const setUserDoc = async ({
  uid,
  displayName,
  email,
  username,
}: UserInterfaces.User) =>
  await setDoc(doc(firestore, "users", uid), {
    displayName,
    email,
    username,
    uid,
  });

export const getUserDoc = async (uid: string) => {
  const docSnap = await getDoc(doc(firestore, "users", uid));

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return false;
  }
};

export const getUserByUsername = async (username: string) => {
  const usersRef = collection(firestore, "users");
  const docSnap = await getDocs(
    query(usersRef, where("username", "==", username))
  );

  if (!docSnap.empty) {
    return docSnap.docs[0].data();
  } else {
    return false;
  }
};

export const getUserByEmail = async (email: string) => {
  const usersRef = collection(firestore, "users");
  const docSnap = await getDocs(query(usersRef, where("email", "==", email)));

  if (!docSnap.empty) {
    return docSnap.docs[0].data();
  } else {
    return false;
  }
};

export const setUserAvatarStorage = async (avatar: File, uid: string) => {
  const storageRef = ref(firebaseStorage, `users/${uid}/avatar`);
  await uploadBytes(storageRef, avatar);
};

export const getUserAvatarStorage = async (uid: string) => {
  const storageRef = ref(firebaseStorage, `users/${uid}/avatar`);

  const avatar = await getDownloadURL(storageRef)
    .then((url) => url)
    .catch(() => "");

  return avatar;
};

export const setLead = async (email: string) => {
  await setDoc(doc(firestore, "leads", email), {
    email,
  });
};

export const getLead = async (email: string) => {
  const docSnap = await getDoc(doc(firestore, "leads", email));

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return false;
  }
};

export const getUserTryOns = async (uid: string) => {
  const userShares = query(
    collection(firestore, "shares"),
    where("uid", "==", uid),
    orderBy("createdAt")
  );
  const querySnapshot = await getDocs(userShares);

  return querySnapshot.forEach((doc) => {
    return doc.data();
  });
};
