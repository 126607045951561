import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import imageCompression from "browser-image-compression";
import { v4 as uuidv4 } from "uuid";

import {
  api,
  getImageUrl,
  getRandomTryOnImage,
  postSneakerImage,
  uploadImage,
} from "../services";
interface TryOnProps {
  children: ReactNode;
}

interface ImageListElement {
  resultURL: string;
  originalURL: string;
  result: string;
  original: string;
  imageIndex: number;
  regenerating?: boolean;
}

interface CheckedItems {
  [key: number]: boolean;
}

interface TryOnPropsContextProps {
  selectedImages: File[];
  setSelectedImages: Dispatch<SetStateAction<any>>;
  showMode: "single" | "multiple";
  setShowMode: Dispatch<SetStateAction<"single" | "multiple">>;
  imageList: ImageListElement[];
  setImageList: Dispatch<SetStateAction<ImageListElement[]>>;
  fetchingResult: boolean;
  getResult: (productId: string) => Promise<void>;
  getSingleResult: (productId: string, imageIndex: number) => Promise<void>;
  checkedImagesIndex: CheckedItems;
  setCheckedImagesIndex: Dispatch<SetStateAction<CheckedItems>>;
  currentTask: {
    status: string;
    queue: number;
  };
  getRandomResult: (
    productId: string,
    modifierId?: number | undefined
  ) => Promise<{ modifierId: number; modifierName: string }[] | undefined>;
  resetTryOn: () => void;
  pendingSaving: number;
  setPendingSaving: Dispatch<SetStateAction<number>>;
  updating: boolean;
  setUpdating: Dispatch<SetStateAction<boolean>>;
}

const TryOnContext = createContext({} as TryOnPropsContextProps);

function TryOnProvider({ children }: TryOnProps) {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [imageList, setImageList] = useState<ImageListElement[]>([]);
  const [fetchingResult, setFetchingResult] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showMode, setShowMode] = useState<"single" | "multiple">("multiple");
  const [checkedImagesIndex, setCheckedImagesIndex] = useState<CheckedItems>(
    {}
  );
  const [pendingSaving, setPendingSaving] = useState(0);
  const [currentTask, setCurrentTask] = useState({
    status: "",
    queue: 0,
  });

  const imageOptions = {
    maxWidthOrHeight: 1024,
  };

  const resetTryOn = () => {
    setSelectedImages([]);
    setImageList([]);
  };

  const getResult = async (productId: string) => {
    try {
      setFetchingResult(true);
      setImageList([]);
      setPendingSaving(0);

      if (selectedImages.length) {
        await Promise.all(
          selectedImages.map(async (image, imageIndex) => {
            const compressedFile = await imageCompression(image, imageOptions);

            const filename = uuidv4();
            await uploadImage(compressedFile, filename);
            const imageUrl = await getImageUrl(filename);

            const task_output = await postSneakerImage(imageUrl, productId);

            const taskId = task_output.data.task_id;

            const originalUrl = imageUrl;
            const originalFilename = filename;

            fetchData({
              taskId,
              originalUrl,
              imageIndex,
              originalFilename,
            });
          })
        );
      }
    } catch (e) {
      setFetchingResult(false);
      console.log(e);
      throw new Error();
    }
  };

  const getSingleResult = async (productId: string, imageIndex: number) => {
    setFetchingResult(true);
    setPendingSaving((c) => c - 1);
    setUpdating(true);
    try {
      setImageList((prevState) => {
        const nextState = [...prevState];

        nextState[imageIndex] = {
          ...prevState[imageIndex],
          regenerating: true,
        };

        return nextState;
      });

      const imageUrl = await getImageUrl(imageList[imageIndex].original);

      const task_output = await postSneakerImage(imageUrl, productId);

      const taskId = task_output.data.task_id;
      const originalUrl = imageList[imageIndex].originalURL;
      const originalFilename = imageList[imageIndex].original;

      fetchData({
        taskId,
        originalUrl,
        imageIndex,
        originalFilename,
      });
    } catch (e) {
      console.log(e);
      throw new Error();
    }
  };

  const getRandomResult = async (
    productId: string,
    modifierId?: number | null
  ): Promise<{ modifierId: number; modifierName: string }[] | undefined> => {
    try {
      setFetchingResult(true);
      setImageList([]);
      setPendingSaving(0);

      if (selectedImages.length) {
        return await Promise.all(
          selectedImages.map(async (image, imageIndex) => {
            const compressedFile = await imageCompression(image, imageOptions);

            const filename = uuidv4();
            await uploadImage(compressedFile, filename);
            const imageUrl = await getImageUrl(filename);

            const request: {
              imageUrl: string;
              productId: string;
              modifierId?: number;
            } = {
              imageUrl,
              productId: productId,
            };
            request.imageUrl = imageUrl;
            request.productId = productId;

            if (modifierId) {
              request.modifierId = modifierId;
            }
            const task_output = await getRandomTryOnImage({
              ...request,
            });

            const taskId = task_output.data.task_id;
            const originalUrl = imageUrl;
            const originalFilename = filename;

            fetchData({
              taskId,
              originalUrl,
              imageIndex,
              originalFilename,
            });

            return {
              modifierId: task_output.data.modifier_id as number,
              modifierName: task_output.data.modifier_text.split(
                " boots"
              )[0] as string,
            };
          })
        );
      }
    } catch (e) {
      setFetchingResult(false);
      console.log(e);
      throw new Error();
    }
  };

  const fetchData = ({
    taskId,
    originalUrl,
    imageIndex,
    originalFilename,
  }: {
    taskId: string;
    originalUrl: string;
    imageIndex: number;
    originalFilename: string;
  }) => {
    const eventSource = new EventSource(`${api.getUri()}task/${taskId}/status`);

    eventSource.addEventListener("update", (e) => {
      const parsedData = JSON.parse(e.data);
      setCurrentTask(parsedData);
      console.log("parsedData");
      console.log(parsedData);

      if (parsedData.status === "SUCCESS") {
        fetchOutput(taskId, originalUrl, imageIndex, originalFilename).catch(
          (e) => {
            throw new Error();
          }
        );

        setPendingSaving((c) => c + 1);
        setFetchingResult(false);

        eventSource.close();
      }
    });
  };

  const fetchOutput = async (
    taskId: string,
    originalUrl: string,
    imageIndex: number,
    originalFilename: string
  ) => {
    try {
      const { data } = await api.get(`task/${taskId}/output`);

      if (data) {
        const result = data.task_result;

        if (result.url && result.url.length > 0) {
          const resultFilename = result.filename;
          setImageList((prevState) => {
            const nextState = [...prevState];

            nextState[imageIndex] = {
              resultURL: result.url as string,
              originalURL: originalUrl,
              result: resultFilename,
              original: originalFilename,
              imageIndex,
              regenerating: false,
            };

            return nextState;
          });
        }

        if (result.error) {
          console.log(data.task_result.error);
          throw new Error();
        }
      }
    } catch (e) {
      console.log(e);
      throw new Error();
    }
  };

  return (
    <TryOnContext.Provider
      value={{
        selectedImages,
        setSelectedImages,
        imageList,
        setImageList,
        fetchingResult,
        getResult,
        currentTask,
        showMode,
        setShowMode,
        checkedImagesIndex,
        setCheckedImagesIndex,
        getRandomResult,
        resetTryOn,
        getSingleResult,
        pendingSaving,
        setPendingSaving,
        updating,
        setUpdating,
      }}
    >
      {children}
    </TryOnContext.Provider>
  );
}

function useTryOn() {
  const context = useContext(TryOnContext);

  return context;
}

export { TryOnProvider, useTryOn };
