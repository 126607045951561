import styled from "styled-components";

interface ProfilePictureProps {
  empty: boolean;
}

interface InputContainerProps {
  error?: boolean;
}

interface ErrorContainerProps {
  visible: boolean;
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 400px;

  flex: 1;

  padding: 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  form {
    width: 100%;
    padding-top: 24px;
  }
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: left;
  flex: 0.5;
  color: #ffffff;
`;

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  max-width: 230px;
  height: 40px;
  flex: 1;

  display: flex;
  justify-content: center;

  background: #111111;
  border: 1px solid
    ${({ error }) => (error ? "#FF5A5F" : "rgba(255, 255, 255, 0.1)")};
  border-radius: 4px;

  outline: none;

  img {
    min-width: 24px;
    height: 24px;
    padding-right: 16px;

    margin: auto;
  }

  :focus-within {
    border: 1px solid #ffffff;

    img {
      opacity: 1;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;

  background: #111111;
  border: none;
  outline: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;

  padding: 12px 12px 12px 16px;
`;

export const FormField = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  padding-bottom: 8px;
`;

export const PictureFrame = styled.div`
  margin: auto;

  width: 64px;
  height: 64px;
  left: 0px;
  top: 0px;

  background: #a2a2a2;
  border: 4px solid #111111;
  border-radius: 64px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  overflow: hidden;

  input {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;

    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const ProfilePicture = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProfileIcon = styled.img`
  opacity: 0.25;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

export const Logout = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  margin-top: 20px;

  border: none;
  background-color: transparent;

  color: #ff5a5f;
`;

export const ErrorContainer = styled.div<ErrorContainerProps>`
  height: 35px;

  padding: 10px 14px;

  background: #2c2c2c;
  border-radius: 80px;

  position: absolute;
  bottom: 40px;

  transition: 0.25s ease-in-out;
  opacity: ${({ visible }) => !visible && 0};
  visibility: ${({ visible }) => !visible && "hidden"};
`;

export const ErrorText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;

  color: #ff5050;
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 18px;
`;

export const NavigateButton = styled.button`
  position: relative;
  max-width: 230px;
  height: 40px;
  flex: 1;

  display: flex;
  justify-content: center;

  border-radius: 4px;
  background: rgba(255, 255, 255, 0.05);
  border: 0;

  outline: none;

  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 16px;
  padding-right: 8px;

  img {
    opacity: 0.25;
    height: 16px;
    width: 16px;
  }
`;
