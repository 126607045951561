import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 20px 0px 20px;

  background: #222222;

  overflow-y: auto;
`;

export const ProductContainer = styled.div`
  width: 100%;
  min-height: 112px;

  padding: 16px 26px;

  display: flex;
  align-items: center;
`;

export const ProductImage = styled.img`
  min-width: 60px;
  width: 60px;
  height: 60px;

  border-radius: 8px;
`;

export const ProductInfo = styled.div`
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1,
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
`;

export const ProductName = styled.h1`
  color: #fff;

  font-size: 13px;

  font-weight: 700;
  line-height: normal;

  margin: 0;

  color: #ffffff;

  @media (max-width: 399px) {
    font-size: 12px;
  }
`;

export const ProductDescription = styled.span`
  overflow: visible;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  /* identical to box height */

  padding-top: 8px;

  color: #ffffff;

  @media (max-width: 399px) {
    font-size: 14px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 8px;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  height: 100%;
  flex: 1;

  padding: 0px 24px;

  background: rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

export const Title = styled.h1`
  width: 100%;

  font-weight: 800;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  margin: 0;
  padding-bottom: 8px;

  color: rgba(255, 255, 255, 0.5);

  overflow: visible;
`;

export const Description = styled.span`
  width: 100%;

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  padding: 8px 0px 16px 0px;

  margin: 0;

  text-align: center;

  color: rgba(255, 255, 255, 0.5);

  overflow: visible;
  * {
    overflow: visible;
  }
`;

export const InputPlaceholder = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-top: 8px;

  display: block;

  text-align: center;

  color: #ffffff;

  opacity: 0.5;

  overflow: visible;
`;

export const ExampleNavigation = styled.strong`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;

  text-decoration: underline;
  cursor: pointer;
  z-index: 999;
`;

export const DarkText = styled.span`
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;

  opacity: 0.5;

  padding-top: 16px;
  padding-bottom: 8px;
`;

export const ImagesRow = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;

  padding-bottom: 16px;

  img {
    cursor: pointer;
    width: 48px;
    height: 48px;

    border-radius: 4px;

    object-fit: cover;
    background: rgba(0, 0, 0, 0.25);
  }
`;

export const SelectedImageContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  display: flex;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.25);
`;

export const Image = styled.img`
  height: auto;
  width: 100%;
  object-fit: contain;

  transition: 0.2s;
`;

export const ActionContainer = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;

  display: flex;
  align-items: center;
  gap: 8px;

  :hover ~ img {
    opacity: 0.5;
  }
`;

export const ImageAction = styled.div`
  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #000000;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 41px;
  cursor: pointer;

  transition: 0.1s;

  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    border: 1px solid #ffffff;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  padding: 0px 20px 20px 20px;
`;
