import styled from "styled-components";

interface InputPlaceholderType {
  light?: boolean;
}

interface ButtonProps {
  disabled?: boolean;
}

interface TabItemProps {
  active: boolean;
}

export const Container = styled.div`
  width: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 16px 20px 0px 20px;

  background: #222222;

  overflow-y: auto;
`;

export const TitleContainer = styled.div``;

export const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  opacity: 0.5;
`;

export const Description = styled.span`
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding-top: 16px;
`;

export const UploadContainer = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0px 24px;

  background: rgba(0, 0, 0, 0.25);

  cursor: pointer;
`;

export const InputPlaceholder = styled.span<InputPlaceholderType>`
  font-weight: ${({ light }) => (light ? 600 : 400)};
  font-size: 14px;
  line-height: 17px;
  padding-top: 8px;

  display: block;

  text-align: center;

  color: #ffffff;

  opacity: ${({ light }) => (light ? 0.75 : 0.5)};

  overflow: visible;
`;

export const Button = styled.button<ButtonProps>`
  text-decoration: none;
  :hover {
    text-decoration: none;
    color: #d9d9d9;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;
  width: 100%;
  max-width: 352px;
  min-height: 40px;
  height: 40px;

  background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%);
  border-radius: 4px;
  cursor: ${({ disabled }) => !disabled && "pointer"};

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #d9d9d9;

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const ExampleNavigation = styled.strong`
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;

  text-decoration: underline;
  cursor: pointer;
  z-index: 999;
`;

export const ActionButtonContainer = styled.div`
  width: 100%;

  background: #222222;

  padding: 16px 24px 16px 24px;

  display: flex;
  justify-content: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  padding-top: 24px;

  input[type="checkbox"] {
    accent-color: #6117ff;
  }

  label {
    padding-left: 8px;

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    color: #ffffff;

    opacity: 0.5;
  }

  /* input {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    border-radius: 4px;
  }
  input:checked {
    background: #6117ff;
    border: 1px solid #ffffff;
    border-radius: 4px;
  } */
`;

export const TabContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TabItem = styled.button<TabItemProps>`
  background: transparent;
  flex: 1;
  width: 100%;

  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;

  margin: auto;
  padding: 12px 0px;

  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: ${({ active }) => active && "2px solid #6117ff"};
  opacity: ${({ active }) => (active ? 1 : 0.25)};

  transition: 0.25s;
`;

export const BackgroundImage = styled.img`
  position: absolute;

  inset: 0;

  margin: auto;
`;
