import styled from "styled-components";

interface SecurityIndicatorProps {
  strength?: number;
}

interface ErrorContainerProps {
  visible: boolean;
}

interface InputContainerProps {
  error?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding: 40px;

  background: #222222;
`;

export const Title = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin: 0;
  padding: 0;

  color: #ffffff;
`;

export const EmailIconImage = styled.img`
  width: 18px;
  height: 18px;

  margin-right: 12px;
`;

export const LightText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  padding-top: 24px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const DarkText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;

  color: #ffffff;

  opacity: 0.5;

  position: absolute;
  bottom: 40px;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Form = styled.form`
  width: 100%;

  padding-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const PasswordVisibility = styled.img``;

export const InputContainer = styled.div<InputContainerProps>`
  position: relative;
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;

  background: #111111;
  border: 1px solid
    ${({ error }) => (error ? "#FF5A5F" : "rgba(255, 255, 255, 0.1)")};
  border-radius: 4px;

  outline: none;

  ${PasswordVisibility} {
    min-width: 24px;
    height: 24px;
    padding-right: 16px;

    margin: auto;

    opacity: 0.25;

    cursor: pointer;
  }
  img {
    min-width: 24px;
    height: 24px;
    padding-right: 16px;

    margin: auto;
  }

  :focus-within {
    border: 1px solid #ffffff;

    ${PasswordVisibility} {
      opacity: 1;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;

  background: #111111;
  border: none;
  outline: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;

  padding: 12px 12px 12px 16px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
`;

export const SecurityContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;

  padding: 16px 0px;

  width: 100%;
`;

export const SecurityIndicator = styled.div<SecurityIndicatorProps>`
  width: 100%;
  flex: 1;
  height: 4px;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;

  background: ${({ strength }) => {
    switch (strength) {
      case 1:
        return "#FF5A5F";
      case 2:
        return "#FBBC05";
      case 3:
        return "#6DE28D";
      default:
        return "rgba(255, 255, 255, 0.1)";
    }
  }};
`;

export const ErrorContainer = styled.div<ErrorContainerProps>`
  height: 35px;

  padding: 10px 14px;

  background: #2c2c2c;
  border-radius: 80px;

  position: absolute;
  bottom: 40px;

  transition: 0.25s ease-in-out;
  opacity: ${({ visible }) => !visible && 0};
  visibility: ${({ visible }) => !visible && "hidden"};
`;

export const ErrorText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;

  color: #ff5050;
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 18px;
`;
