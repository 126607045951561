import { ReactNode, createContext, useContext, useState } from "react";

interface RandomizerProps {
  children: ReactNode;
}

interface RandomizerContextProps {
  randomizer: boolean;
  enableRandomizer: () => void;
  disableRandomizer: () => void;
  modifierId: number | null;
  handleModifierId: (id: number | null) => void;
  modifierName: string;
  handleModifierName: (name: string) => void;
}

const RandomizerContext = createContext({} as RandomizerContextProps);

function RandomizerProvider({ children }: RandomizerProps) {
  const [randomizer, setRandomizer] = useState(false);
  const [modifierId, setModifierId] = useState<number | null>(null);
  const [modifierName, setModifierName] = useState("");

  const enableRandomizer = () => {
    setRandomizer(true);
  };

  const disableRandomizer = () => {
    setRandomizer(false);
  };

  const handleModifierId = (id: number | null) => {
    setModifierId(id);
  };

  const handleModifierName = (name: string) => {
    setModifierName(name);
  };

  return (
    <RandomizerContext.Provider
      value={{
        randomizer,
        enableRandomizer,
        disableRandomizer,
        modifierId,
        handleModifierId,
        modifierName,
        handleModifierName,
      }}
    >
      {children}
    </RandomizerContext.Provider>
  );
}

function useRandomizer() {
  const context = useContext(RandomizerContext);

  return context;
}

export { RandomizerProvider, useRandomizer };
