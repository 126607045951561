import styled from "styled-components";
import { Tooltip } from "react-tooltip";

import ExampleImage from "../../assets/FullBodyExamples/full-body3.png";
import { ReactComponent as FavoriteIcon } from "../../assets/favorite.svg";
import { getImageUrl } from "../../services";

interface ActionButtonProps {
  largeImage?: boolean;
}

interface ActionButtonsContainerProps {
  visible: boolean;
}

interface ItemProps {
  backgroundImage: string;
}

interface BackgroundImageProps {
  isLoading: boolean;
}

export const Container = styled.div`
  height: 100%;
  width: 100%;

  overflow: hidden;

  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: 40px;

  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(17, 17, 17, 0.25);

  padding: 0px 8px 0px 12px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  input {
    width: 100%;
    height: 100%;

    background: transparent;

    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: 0;
    border: 0;
  }

  svg {
    width: 20px;
    height: 20px;

    opacity: 0.25;
    margin-right: 4px;
  }
`;

export const SearchContainer = styled.div`
  display: inline-flex;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 16px;

  background: #222;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

  position: relative;

  > svg {
    min-width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const Content = styled.div`
  overflow-y: scroll;

  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: 180px 180px;
  grid-template-rows: 200px;
  justify-content: center;
  gap: 8px;

  position: relative;
`;

export const ActionButtonsContainer = styled.div<ActionButtonsContainerProps>`
  visibility: hidden;
  opacity: 0;
  transition: 0.25s;
  top: 0;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 0 auto;

  width: 100%;
  max-width: 1200px;
  height: 100%;
  z-index: 2;

  position: absolute;
`;

export const Item = styled.div<ItemProps>`
  width: 180px;
  height: 200px;
  background-image: linear-gradient(180deg, #111 0%, rgba(17, 17, 17, 0) 50%);

  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;

  border-radius: 4px;
  border: 2px solid #111;

  padding: 12px;
  z-index: 2;

  position: relative;

  :hover ${ActionButtonsContainer} {
    opacity: 1;
    visibility: visible;
  }
`;

export const ShoeIcon = styled.img`
  min-width: 24px;
  width: 24px;
  height: 24px;

  border-radius: 4px;
`;

export const ProductContainer = styled.div`
  display: flex;
  z-index: 2;
  position: relative;

  > div {
    padding-left: 8px;
  }
`;

export const ProductCollection = styled.h1`
  margin: 0;
  padding: 0;

  color: #fff;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const ProductName = styled.span`
  align-self: stretch;
  color: #fff;
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding-top: 4px;

  display: block;
`;

export const DateContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  bottom: 12px;
  left: 12px;
  z-index: 1;

  * {
    cursor: default;
  }
`;

export const Day = styled.span`
  color: #fff;
  text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  font-family: Inter;
  font-size: 8px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const Month = styled.span`
  color: #fff;
  font-family: Inter;
  font-size: 6px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export const FavoriteButton = styled(FavoriteIcon)`
  position: absolute;
  cursor: pointer;

  bottom: 12px;
  right: 12px;
  z-index: 3;
`;

export const Button = styled.a<ActionButtonProps>`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;

  background: rgba(51, 51, 51, 0.5);
  border-radius: 100px;

  z-index: 3;

  cursor: pointer;

  img {
    width: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
    height: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
  }

  &:hover {
    background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%), #000000;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  max-width: 200px;
  text-align: center;

  color: #d9d9d9;
  z-index: 3;
`;

export const FilterContainer = styled.div`
  border-radius: 8px;
  background: #191919;
  box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.5);

  display: flex;
  width: 200px;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  position: absolute;
  right: 0px;
  top: 56px;
  z-index: 3;

  li {
    text-decoration: none;
    list-style-type: none;
    padding: 8px 24px;

    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
    width: 100%;
    text-align: left;
    transition: 0.1s;
    cursor: pointer;

    :hover {
      background: rgba(255, 255, 255, 0.05);
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

export const FilterTitle = styled.span`
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: block;
  padding: 8px 24px;
`;

export const BackgroundImage = styled.img<BackgroundImageProps>`
  position: absolute;

  z-index: -1;

  width: 100%;
  height: 100%;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;

  opacity: ${({ isLoading }) => (isLoading ? 0 : 1)};

  /* :hover ${ActionButtonsContainer} {
    opacity: 1;
    visibility: visible;
  } */
`;

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(17, 17, 17, 0.25);
  z-index: 1;
`;

export const FadeContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  background-image: linear-gradient(180deg, #111 0%, rgba(17, 17, 17, 0) 50%);
  z-index: 1;
`;
