import { ReactNode, createContext, useContext, useState } from "react";

interface OnboardingProps {
  children: ReactNode;
}

interface OnboardingContextProps {
  onboarding: boolean;
  enableOnboarding: () => void;
  disableOnboarding: () => void;
}

const OnboardingContext = createContext({} as OnboardingContextProps);

function OnboardingProvider({ children }: OnboardingProps) {
  const [onboarding, setOnboarding] = useState(false);

  const enableOnboarding = () => {
    setOnboarding(true);
  };

  const disableOnboarding = () => {
    setOnboarding(false);
  };

  return (
    <OnboardingContext.Provider
      value={{ onboarding, enableOnboarding, disableOnboarding }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

const useOnboarding = () => {
  const context = useContext(OnboardingContext);

  return context;
};

export { OnboardingProvider, useOnboarding };
