import React, { useEffect, useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import { ActionButton, ErrorTip, Header, Wrapper } from "../../components";

import InvisibleIcon from "../../assets/invisible.svg";
import VisibleIcon from "../../assets/visible.svg";
import CheckIcon from "../../assets/check.svg";

import {
  Container,
  DarkText,
  Form,
  Input,
  InputContainer,
  LightText,
  PasswordVisibility,
  Row,
  SecurityContainer,
  SecurityIndicator,
  Spinner,
  Title,
} from "./styles";
import { useNavigate } from "react-router-dom";
import { getUserByEmail, getUserByUsername, setUserDoc } from "../../services";
import { validateEmail } from "../../utils";
import { useUser } from "../../contexts";

const SignUpWithEmail: React.FC = () => {
  const auth = getAuth();
  const { setUser, saveAccessToken } = useUser();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState("");
  const [username, setUsername] = useState("");
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTipVisible, setErrorTipVisible] = useState(false);
  const [inputError, setInputError] = useState({
    displayName: false,
    email: false,
    username: false,
    password: false,
  });

  const togglePasswordVisibility = () => {
    setVisiblePassword((visible) => !visible);
  };

  const signUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFetching(true);
    setInputError({
      displayName: false,
      email: false,
      username: false,
      password: false,
    });

    const validPassword = validatePassword(password);
    const validEmail = validateEmail(email);

    const emailExists = await getUserByEmail(email);

    if (
      validPassword &&
      validEmail &&
      username &&
      displayName &&
      !emailExists
    ) {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;

          await setUserDoc({
            uid: user.uid,
            displayName,
            email: user.email,
            username,
          });
          setUser({
            uid: user.uid,
            displayName,
            email: user.email,
            username,
          });

          saveAccessToken(await user.getIdToken(), user.uid);

          setFetching(false);
          navigate("/onboarding");
          // ...
        })
        .catch((error) => {
          setFetching(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
    } else {
      setFetching(false);

      if (!validPassword) {
        showError("Please enter a valid password");
        setInputError((prevState) => ({ ...prevState, password: true }));
      }
      if (emailExists) {
        showError("Email already exists");
        setInputError((prevState) => ({ ...prevState, email: true }));
      }
      if (!password) {
        showError("Please enter a password");
        setInputError((prevState) => ({ ...prevState, password: true }));
      }
      if (!validEmail) {
        showError("Please enter a valid email address");
        setInputError((prevState) => ({ ...prevState, email: true }));
      }
      if (!displayName) {
        showError("Please enter a name");
        setInputError((prevState) => ({ ...prevState, displayName: true }));
      }
      if (!username) {
        showError("Please enter a username");
        setInputError((prevState) => ({ ...prevState, username: true }));
      }
    }
  };

  const validatePassword = (newPassword: string) => {
    const upperAndLowercaseRule = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).*$").test(
      newPassword
    )
      ? 1
      : 0;
    const lengthRule = new RegExp(".{8,}").test(newPassword) ? 1 : 0;
    const numberAndSymbolRule = new RegExp("(?=.*\\d)(?=.*[\\W_]).+").test(
      newPassword
    )
      ? 1
      : 0;

    const result = upperAndLowercaseRule + lengthRule + numberAndSymbolRule;

    setPasswordStrength(result);

    return result >= 0;
  };

  const onChangePassword = (value: string) => {
    setPassword(value);
    validatePassword(value);
    setInputError((prevState) => ({ ...prevState, password: false }));
  };

  const onChangeEmail = (value: string) => {
    setEmail(value);
    setInputError((prevState) => ({ ...prevState, email: false }));
  };

  const navigateToLogin = () => {
    navigate("/signin");
  };

  const showError = (message: string) => {
    setErrorTipVisible(true);
    setErrorMessage(message);

    setTimeout(() => setErrorTipVisible(false), 5000);
  };

  const checkUsername = async (newUsername: string) => {
    setCheckingUsername(true);
    if (!newUsername) {
      showError("Please enter a username");
      setInputError((prevState) => ({ ...prevState, username: true }));
      setCheckingUsername(false);
      return;
    }

    const usernameExists = await getUserByUsername(newUsername);

    if (usernameExists) {
      showError(
        "That username has already been taken. Please use a different one and try again."
      );
      setInputError((prevState) => ({ ...prevState, username: true }));
    } else {
      setInputError((prevState) => ({ ...prevState, username: false }));
    }
    setCheckingUsername(false);
  };

  const changeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);

    checkUsername(e.target.value);
  };

  return (
    <Wrapper>
      <Header back />

      <Container>
        <Title>Join with Email</Title>

        <Form onSubmit={signUp}>
          <Row>
            <InputContainer error={inputError.displayName}>
              <Input
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                type="text"
                placeholder="Name"
              />
            </InputContainer>
            <InputContainer error={inputError.username}>
              <Input
                value={username}
                onChange={changeUsername}
                type="text"
                placeholder="Username"
              />
              {checkingUsername && <Spinner />}
              {!inputError.username && !checkingUsername && username && (
                <img src={CheckIcon} alt="" />
              )}
            </InputContainer>
          </Row>

          <InputContainer error={inputError.email}>
            <Input
              value={email}
              onChange={(e) => onChangeEmail(e.target.value)}
              type="text"
              placeholder="Email"
            />
          </InputContainer>

          <InputContainer error={inputError.password}>
            <Input
              value={password}
              onChange={(e) => onChangePassword(e.target.value)}
              type={visiblePassword ? "text" : "password"}
              placeholder="Password"
            />
            <PasswordVisibility
              src={visiblePassword ? VisibleIcon : InvisibleIcon}
              alt=""
              onClick={togglePasswordVisibility}
            />
          </InputContainer>

          <SecurityContainer>
            <SecurityIndicator
              strength={password ? passwordStrength || 1 : 0}
            />
            <SecurityIndicator
              strength={passwordStrength > 1 ? passwordStrength : 0}
            />
            <SecurityIndicator
              strength={passwordStrength > 2 ? passwordStrength : 0}
            />
          </SecurityContainer>

          <ActionButton loading={fetching} type="submit">
            Create Account
          </ActionButton>
        </Form>

        <LightText>
          Already a member? <span onClick={navigateToLogin}>Log in</span>
        </LightText>

        <DarkText>
          Questions? Contact <span>Support</span>
        </DarkText>
      </Container>

      <ErrorTip visible={errorTipVisible} message={errorMessage} />
    </Wrapper>
  );
};

export default SignUpWithEmail;
