import React from "react";

import CloseIcon from "../../../../assets/close.svg";

import {
  SelectedImageContainer,
  Image,
  ImageAction,
  ActionContainer,
} from "./styles";

interface SelectedImageProps {
  selectedImage: File;
  onClick: () => void;
}

const SelectedImage = ({ selectedImage, onClick }: SelectedImageProps) => {
  return (
    <SelectedImageContainer>
      <ActionContainer>
        <ImageAction onClick={onClick}>
          <img src={CloseIcon} alt="" />
        </ImageAction>
      </ActionContainer>
      <Image src={`${URL.createObjectURL(selectedImage)}`} alt="" />
    </SelectedImageContainer>
  );
};

export default SelectedImage;
