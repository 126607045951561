import React, { useState } from "react";
import { ActionButton, Header, Spinner, Wrapper } from "../../components";

import {
  Container,
  DarkText,
  Description,
  Input,
  InputContainer,
  SubmitContainer,
  Title,
} from "./styles";
import { validateEmail } from "../../utils";
import { sendPasswordRecoveryEmail } from "../../services/auth";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEditEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    handleEmailValidation(e.target.value);
  };

  const handleEmailValidation = (email: string) => {
    const isValid = validateEmail(email);
    if (isValid) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };

  const handleSendEmail = async () => {
    try {
      setLoading(true);

      const response = await sendPasswordRecoveryEmail(email);

      console.log(response);

      if (response.status === 200) {
        setSubmitted(true);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Header back />
      {submitted ? (
        <Container>
          <Title>Password reset email sent</Title>
          <Description pb={40}>
            We sent instructions to pick a new password to <span>{email}</span>
          </Description>

          <SubmitContainer>
            <ActionButton disabled={loading} dark onClick={handleSendEmail}>
              {loading ? <Spinner /> : "Resend email"}
            </ActionButton>

            <DarkText>
              Not your email address?{" "}
              <span onClick={() => setSubmitted(false)}>Try another</span>
            </DarkText>
          </SubmitContainer>
        </Container>
      ) : (
        <Container>
          <Title>Let’s find your Stylar account</Title>
          <Description>What’s your email?</Description>

          <SubmitContainer>
            <InputContainer>
              <Input
                type="text"
                placeholder="Email"
                onChange={handleEditEmail}
                value={email}
              />
            </InputContainer>

            <ActionButton
              disabled={!validEmail || loading}
              onClick={handleSendEmail}
            >
              {loading ? <Spinner /> : "Send a password reset email"}
            </ActionButton>
          </SubmitContainer>
        </Container>
      )}
    </Wrapper>
  );
};

export default ForgotPassword;
