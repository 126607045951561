import React, { useEffect, useState } from "react";
import { Header, ProductHeader, Wrapper } from "../../components";

import {
  Button,
  ButtonContainer,
  CarouselContainer,
  CarouselImage,
  Container,
  Content,
  FlashingButton,
  Title,
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { getProduct } from "../../services";
import { useProduct, useRandomizer } from "../../contexts";

import DirectionIcon from "../../assets/chevron-right.svg";
import CarouselImage0 from "../../assets/YellowBootsCover/Anna YLW.jpg";
import CarouselImage1 from "../../assets/YellowBootsCover/Ariel YLW.jpg";
import CarouselImage2 from "../../assets/YellowBootsCover/Barbie YLW.jpg";
import CarouselImage3 from "../../assets/YellowBootsCover/Bear YLW.jpg";
import CarouselImage4 from "../../assets/YellowBootsCover/Edna YLW.jpg";
import CarouselImage5 from "../../assets/YellowBootsCover/Elvis YLW.jpg";
import CarouselImage6 from "../../assets/YellowBootsCover/Goku YLW.jpg";
import CarouselImage7 from "../../assets/YellowBootsCover/Hulk YLW.jpg";
import CarouselImage8 from "../../assets/YellowBootsCover/Kanye Elon YLW.jpg";
import CarouselImage9 from "../../assets/YellowBootsCover/Mark YLW.jpg";
import CarouselImage10 from "../../assets/YellowBootsCover/Queen YLW.jpg";
import CarouselImage11 from "../../assets/YellowBootsCover/Taylor YLW.jpg";
import CarouselImage12 from "../../assets/YellowBootsCover/V YLW.jpg";

const ShareCover = () => {
  const { productId } = useParams();
  const { enableRandomizer, disableRandomizer } = useRandomizer();
  const { product, setProduct } = useProduct();
  const [fetching, setFetching] = useState<boolean>(false);
  const carouselImages = [
    CarouselImage0,
    CarouselImage1,
    CarouselImage2,
    CarouselImage3,
    CarouselImage4,
    CarouselImage5,
    CarouselImage6,
    CarouselImage7,
    CarouselImage8,
    CarouselImage9,
    CarouselImage10,
    CarouselImage11,
    CarouselImage12,
  ];
  const navigate = useNavigate();

  const navigateToStart = () => {
    disableRandomizer();
    navigate(`/share/${productId}/start`);
  };

  const navigateToRandomizer = () => {
    enableRandomizer();
    navigate(`/share/${productId}/start`);
  };

  useEffect(() => {
    const selectProduct = async (productId: string) => {
      try {
        setFetching(true);
        const response = await getProduct(productId);

        if (response.data) setProduct(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        setFetching(false);
      }
    };

    if (productId) {
      selectProduct(productId);
    }
  }, [productId, setProduct]);

  return (
    <Wrapper>
      <Container $bgImg={product?.share_banner}>
        <Header title="Virtual Try On" />
        <ProductHeader disabled fetching={fetching} product={product} />

        <Content>
          <Title>
            Slap these onto
            <br />
            literally anyone.
          </Title>

          <ButtonContainer>
            <Button onClick={navigateToStart}>
              Try ‘em on <img src={DirectionIcon} alt="" />{" "}
            </Button>

            <FlashingButton onClick={navigateToRandomizer}>
              Surprise Me
            </FlashingButton>
          </ButtonContainer>
        </Content>

        {product && product.id === "mschf-yellow-crocs" && (
          <CarouselContainer>
            {carouselImages.map((image) => (
              <CarouselImage src={image} />
            ))}
          </CarouselContainer>
        )}
      </Container>
    </Wrapper>
  );
};

export default ShareCover;
