import styled, { keyframes } from "styled-components";

import { ReactComponent as FavoriteIcon } from "../../assets/favorite.svg";
import { ReactComponent as FavoriteLineIcon } from "../../assets/favorite-line.svg";

const imageMaxWidth = 284;

interface SlideProps {
  index: number;
}

interface StyledImageProps {
  show: boolean;
  fetching?: boolean;
}

export const SlideContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  position: relative;

  height: 100%;
  overflow-x: hidden;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  min-width: ${imageMaxWidth}px;
  width: 90vw;

  background: rgba(0, 0, 0, 0.25);

  cursor: pointer;
  position: relative;
`;

export const Slide = styled.div<SlideProps>`
  height: 100%;

  display: flex;

  gap: 2vw;
  padding-left: 4vw;

  transform: translateX(-${({ index }) => 92 * index}vw);

  transition: 500ms;
`;

export const StyledImage = styled.img<StyledImageProps>`
  height: 100%;
  width: auto;
  object-fit: cover;
  transition: 0.5s;

  position: absolute;
  bottom: 0;
  top: 0;

  opacity: ${({ show }) => (show ? 1 : 0)};
  filter: ${({ fetching }) => fetching && "blur(8px)"};

  cursor: pointer;
  z-index: 1;

  overflow: hidden;
  object-fit: cover;

  background: rgba(0, 0, 0, 0.25);
`;

export const SwitchImageLeftButton = styled.a`
  width: 48px;
  height: 48px;

  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 12px;
  top: calc(50% - 24px);

  cursor: pointer;

  img {
    transform: rotate(180deg);
  }
`;

export const SwitchImageRightButton = styled.a`
  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 0px;
  bottom: 0px;

  cursor: pointer;
`;

export const IndexIndicator = styled.span`
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 36px;

  padding: 6px 8px;

  border-radius: 80px;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);

  color: #fff;
  font-size: 10px;

  font-weight: 800;
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  margin: auto;
`;

export const FavoriteButton = styled(FavoriteIcon)`
  position: absolute;

  width: 20px;
  height: 20px;

  right: 16px;
  bottom: 16px;
`;

export const FavoriteLineButton = styled(FavoriteLineIcon)`
  position: absolute;

  width: 20px;
  height: 20px;

  right: 16px;
  bottom: 16px;
  z-index: 2;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  flex: 1;

  left: 0;
  z-index: 2;
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    display: none;
  }
`;

export const TapAlert = styled.div`
  position: absolute;
  margin: auto;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 6px 32px;
  opacity: 1;

  animation: ${fadeOut} 3s ease-in-out forwards;

  span {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
  }
`;
