import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;

  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;

  z-index: 999;

  background: transparent;

  backdrop-filter: blur(8px);
`;

export const Form = styled.form`
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 200px;
  width: 100%;
  max-width: 360px;
  padding: 0px 16px;
  border-radius: 8px;
  gap: 24px;

  background: #131313;

  h1 {
    color: #d9d9d9;
    text-align: center;

    font-size: 14px;

    font-weight: 700;
    line-height: normal;

    margin: 0px;
    padding: 0px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.03);

  display: flex;
  align-items: center;

  padding: 8px 16px;

  input {
    padding: 0px 16px;

    width: 100%;
    height: 100%;
    flex: 1%;

    border: 0px;
    background: transparent;

    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }

  input:focus {
    outline: none;
  }
`;
