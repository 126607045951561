import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: space-around;

  padding: 24px;

  > div {
    width: 100%;
    flex: 1;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Description = styled.span`
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding-top: 40px;
  padding-bottom: 24px;
`;

export const Input = styled.div`
  width: 100%;
  height: 40px;

  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #111;

  padding: 0px 16px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;

    color: #fff;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  input:focus {
    outline-width: 0;
  }

  img {
    opacity: 0.25;
  }
`;

export const ClickableText = styled.span`
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;

  cursor: pointer;
`;

export const SubTitle = styled.h2`
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 16.8px */
`;

export const SuccessContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
