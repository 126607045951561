import styled from "styled-components";

interface ImageTagProps {
  isGood?: boolean;
}

interface CarouselNavButtonProps {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;
  flex: 1;
  padding: 40px 24px;
`;

export const Title = styled.h1`
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;

  text-align: center;

  color: #ffffff;

  margin: 0;
`;

export const SubTitle = styled.h2`
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

export const Description = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: #ffffff;

  padding: 4px 0px 16px 0px;
`;

export const Row = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  gap: 4px;

  padding: 24px 0;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const ExampleImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const ImageTag = styled.span<ImageTagProps>`
  position: absolute;
  right: 8px;
  bottom: 8px;

  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: ${({ isGood }) => (isGood ? "#2DCB57" : "#eb4335")};

  background: rgba(0, 0, 0, 0.75);
  border-radius: 100px;
  padding: 8px 12px;
`;

export const CarouselNavContainer = styled.div`
  display: flex;
  gap: 8px;

  padding-bottom: 26px;
`;

export const CarouselNavButton = styled.button<CarouselNavButtonProps>`
  width: 8px;
  height: 8px;

  border: none;
  padding: 0px;
  margin: 0px;

  background: #ffffff;

  border-radius: 8px;

  opacity: ${({ active }) => (active ? 1 : 0.25)};
`;
