import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

interface ButtonProps {
  dark?: boolean;
}

export const Button = styled.button<ButtonProps>`
  text-decoration: none;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;
  width: 100%;
  min-height: 40px;
  height: 40px;

  background: ${({ dark }) =>
    dark
      ? `rgba(255, 255, 255, 0.10)`
      : `linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%)`};
  border-radius: 4px;
  cursor: ${({ disabled }) => !disabled && "pointer"};

  opacity: ${({ disabled }) => disabled && 0.5};
  transition: 0.25s;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;

  :hover {
    text-decoration: none;
  }
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  width: 20px;
  height: 20px;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
`;
