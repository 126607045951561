import {
  useState,
  useEffect,
  DetailedHTMLProps,
  ImgHTMLAttributes,
} from "react";
import Spinner from "../Spinner";
import { LoadingContainer, StyledImage } from "./styles";
import { getImageUrl } from "../../services";

const AsyncImage = (
  props:
    | DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    | any
) => {
  const [loadedSrc, setLoadedSrc] = useState<string | undefined | null>();
  const [loading, setLoading] = useState(true);

  const loadImage = async (imageName: string) => {
    setLoading(true);
    const response = await getImageUrl(imageName);
    setLoadedSrc(response);
    setLoading(false);
  };

  useEffect(() => {
    loadImage(props.src);
  }, [props.src]);

  console.log(loadedSrc);

  if (loading || !loadedSrc) {
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );
  }
  return <StyledImage {...props} src={loadedSrc} alt={props.alt} />;
};

export default AsyncImage;
