import styled from "styled-components";

interface DescriptionProps {
  pb?: number;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 80px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 38.4px */
  margin: 0;
  padding: 0;
`;

export const Description = styled.span<DescriptionProps>`
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  text-shadow: 0px 4px 24px rgba(0, 0, 0, 0.5);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */

  padding-top: 24px;
  padding-bottom: ${({ pb }) => pb && pb}px;

  > span {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
`;

export const InputContainer = styled.div`
  padding-top: 40px;
  padding-bottom: 8px;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;

  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #111;

  padding: 12px 16px;

  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  :focus {
    outline-width: 0;
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 352px;
`;

export const DarkText = styled.div`
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 16.8px */

  padding-top: 32px;

  > span {
    color: #fff;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-decoration-line: underline;
    cursor: pointer;
  }
`;
