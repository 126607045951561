import { ReactNode } from "react";

import { TryOnProvider } from "./TryOnContext";
import { UserProvider } from "./UserContext";
import { ProductProvider } from "./ProductContext";
import { RandomizerProvider } from "./RandomizerContext";
import { OnboardingProvider } from "./OnboardingContext";
import { SaveResultProvider } from "./SaveResultContext";
import { SavedProvider } from "./SavedContext";

export { useTryOn } from "./TryOnContext";
export { useUser } from "./UserContext";
export { useProduct } from "./ProductContext";
export { useRandomizer } from "./RandomizerContext";
export { useOnboarding } from "./OnboardingContext";
export { useSaveResult } from "./SaveResultContext";
export { useSaved } from "./SavedContext";

export function ContextProvider({ children }: { children: ReactNode }) {
  return (
    <OnboardingProvider>
      <RandomizerProvider>
        <ProductProvider>
          <TryOnProvider>
            <UserProvider>
              <SaveResultProvider>
                <SavedProvider>{children}</SavedProvider>
              </SaveResultProvider>
            </UserProvider>
          </TryOnProvider>
        </ProductProvider>
      </RandomizerProvider>
    </OnboardingProvider>
  );
}
