export const downloadImage = async (url: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], "result.png", { type: "image/png" });

  const a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  a.download = "stylar-result.png";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
