import React, { useEffect, useState } from "react";

import { BottomTab, Header, ItemCard, Wrapper } from "../../components";

import { Container, Spinner } from "./styles";
import { api } from "../../services/api";
import { ProductInterfaces } from "../../interfaces";

// import { Container } from './styles';

const Discovery = () => {
  const [productList, setProductList] = useState<ProductInterfaces.Product[]>(
    []
  );
  const [fetching, setFetching] = useState(true);

  const getProducts = async () => {
    try {
      const sneakers = (await api.get("all-products")).data;
      if (sneakers) setProductList(sneakers);
    } catch (e) {
      console.log(e);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Wrapper>
      <Header title="Discover" />
      <Container>
        {fetching ? (
          <Spinner />
        ) : (
          productList.map((product) => (
            <ItemCard
              key={product.id}
              title={product.collection}
              description={product.name}
              image={product.icon}
              store={product.store}
              url={product.url}
            />
          ))
        )}
      </Container>
      <BottomTab />
    </Wrapper>
  );
};

export default Discovery;
