import React from "react";
import { AlertContainer, AlertText } from "./styles";

// import { Container } from './styles';

interface AlertProps {
  visible: boolean;
  message: string;
}

const Alert = ({ visible, message }: AlertProps) => {
  return (
    <AlertContainer visible={true}>
      <AlertText>{message}</AlertText>
    </AlertContainer>
  );
};

export default Alert;
