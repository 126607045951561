import React, { SyntheticEvent, useEffect, useState } from "react";
import { BottomTab, Header, Spinner, Wrapper } from "../../components";

import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as FilterIcon } from "../../assets/filter-icon.svg";
import LinkIcon from "../../assets/link.svg";
import ShareIcon from "../../assets/share.svg";
import FullScreenIcon from "../../assets/fullscreen.svg";

import {
  Container,
  Content,
  Item,
  ProductContainer,
  ProductCollection,
  SearchContainer,
  ShoeIcon,
  ProductName,
  Day,
  Month,
  DateContainer,
  InputContainer,
  FavoriteButton,
  Button,
  CustomTooltip,
  ActionButtonsContainer,
  FilterContainer,
  FilterTitle,
  BackgroundImage,
  LoadingContainer,
  FadeContainer,
} from "./styles";

import ExampleShoe from "../../assets/ProductImage/big-red-boots.svg";
import { useSaved } from "../../contexts";
import { getMonthName } from "../../utils";
import { TryOnInterfaces } from "../../interfaces";
import { getImageUrl } from "../../services";
import ShareDropdown from "./components/ShareDropdown";

// import { Container } from './styles';

const Saved = () => {
  const [searchValue, setSearchValue] = useState("");

  const [showFilter, setShowFilter] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [selectedItem, setSelectedItem] =
    useState<TryOnInterfaces.Saved | null>(null);
  const {
    userSavedPhotos,
    orderSavedByName,
    orderByMostRecent,
    orderByMostTried,
    removePhoto,
  } = useSaved();

  const [loadingImage, setLoadingImage] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleCopyLink = (shareId: string) => {
    setAlertMessage("Link Copied!");

    setShowAlert(true);

    const url = `${window.location.origin}/fullscreen?id=${shareId}&category=saved`;
    navigator.clipboard.writeText(url);
    setTimeout(() => setShowAlert(false), 3000);
  };

  const toggleFilter = () => {
    setShowFilter((prevState) => !prevState);
  };

  const toggleShareDropdown = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    index: number
  ) => {
    e.stopPropagation();
    if (shareVisible) {
      setShareVisible(false);
      setSelectedItem(null);
    } else {
      setSelectedItem(userSavedPhotos[index]);
      setShareVisible(true);
    }
  };

  const hideShareDropdown = () => {
    setShareVisible(false);
    setSelectedItem(null);
  };

  const retryImage = async (
    event: SyntheticEvent<HTMLImageElement, Event>,
    imageName: string,
    id: string
  ) => {
    setLoadingImage((prevState) => ({
      ...prevState,
      [id]: true,
    }));

    const response = await getImageUrl(imageName);

    if ((event.nativeEvent.target as HTMLImageElement)?.src) {
      (event.nativeEvent.target as HTMLImageElement).src = response;
    }

    setLoadingImage((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const navigateToFullScreen = async (index: number, productId: string) => {
    await localStorage.setItem(
      "imageList",
      JSON.stringify([
        {
          result: userSavedPhotos[index].images.result,
          original: userSavedPhotos[index].images.original,
        },
      ])
    );
    await localStorage.setItem("productId", JSON.stringify(productId));
    await localStorage.setItem("resultId", "");
    window.open("/fullscreen?category=saved", "_blank", "noreferrer");
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const searchByName = () => {
    const regex = new RegExp(searchValue, "i");

    return userSavedPhotos.filter(
      (item) =>
        (regex.test(item.product.collection) ||
          regex.test(item.product.name)) &&
        item
    );
  };

  const onDeleteSavePhoto = ({
    shareIndex,
    shareId,
  }: {
    shareIndex: number;
    shareId: string;
  }) => {
    removePhoto({ shareId, shareIndex });
  };

  useEffect(() => {
    searchByName();
  }, [searchValue]);

  return (
    <Wrapper>
      <Header />

      <Container>
        <SearchContainer>
          <InputContainer>
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchChange}
            />

            <SearchIcon />
          </InputContainer>

          <FilterIcon onClick={toggleFilter} />
          <FilterContainer hidden={!showFilter}>
            <FilterTitle>Sort by:</FilterTitle>
            <ul>
              <li onClick={orderByMostRecent}>Most recent</li>
              <li onClick={orderSavedByName}>Alphabetical</li>
              <li onClick={() => orderByMostTried("asc")}>Most Tried On</li>
              <li onClick={() => orderByMostTried("desc")}>Least Tried On</li>
            </ul>
          </FilterContainer>
        </SearchContainer>

        <Content>
          {searchByName().map((item, index) => (
            <Item backgroundImage={item.images.result}>
              <ProductContainer>
                <ShoeIcon src={item.product.icon} alt="" />

                <div>
                  <ProductCollection>
                    {item.product.collection}
                  </ProductCollection>
                  <ProductName>{item.product.name}</ProductName>
                </div>
              </ProductContainer>

              <DateContainer>
                <Day>{item.createdAt?.toDate().getDate()}</Day>
                <Month>
                  {getMonthName(item.createdAt?.toDate().getMonth())
                    .substring(0, 3)
                    .toUpperCase()}
                </Month>
              </DateContainer>

              <FavoriteButton
                onClick={() =>
                  onDeleteSavePhoto({
                    shareId: item.shareId,
                    shareIndex: item.shareIndex,
                  })
                }
              />

              <ActionButtonsContainer visible={true}>
                <Button
                  data-tooltip-id="link-tooltip"
                  data-tooltip-content="Fullscreen"
                  data-tooltip-place="top"
                  largeImage
                  onClick={() => navigateToFullScreen(index, item.product.id)}
                >
                  <img src={FullScreenIcon} alt="" />
                </Button>
                <Button
                  data-tooltip-id="link-tooltip"
                  data-tooltip-content="Copy Link"
                  data-tooltip-place="top"
                  largeImage
                  onClick={() => handleCopyLink(item.id)}
                >
                  <img src={LinkIcon} alt="" />
                  <CustomTooltip id="link-tooltip" />
                </Button>

                <Button
                  data-tooltip-id="link-tooltip"
                  data-tooltip-content="Share"
                  data-tooltip-place="top"
                  largeImage
                  onClick={(e) => toggleShareDropdown(e, index)}
                >
                  <img src={ShareIcon} alt="" />
                </Button>
              </ActionButtonsContainer>

              <BackgroundImage
                src={item.images.result}
                isLoading={!!loadingImage[item.id]}
                alt=""
                onError={(e) => retryImage(e, item.images.result, item.id)}
              />

              {loadingImage[item.id] && (
                <LoadingContainer>
                  <Spinner />
                </LoadingContainer>
              )}
              <FadeContainer />
            </Item>
          ))}
          {selectedItem && (
            <ShareDropdown
              onClose={hideShareDropdown}
              visible={shareVisible}
              id={selectedItem.shareId}
              medias={[selectedItem.images.result]}
              onLinkCopied={() => handleCopyLink(selectedItem.shareId)}
            />
          )}
        </Content>
      </Container>

      <BottomTab />
    </Wrapper>
  );
};

export default Saved;
