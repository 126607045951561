import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { ProductInterfaces } from "../interfaces";
import { api, getProduct } from "../services";

interface ProductProps {
  children: ReactNode;
}

interface ProductPropsContextProps {
  product?: ProductInterfaces.Product;
  productList: ProductInterfaces.Product[];
  setProduct: Dispatch<SetStateAction<ProductInterfaces.Product | undefined>>;
  fetchingProduct: boolean;
  selectProduct: (productId: string) => Promise<void>;
  getProducts: () => Promise<void>;
}

const ProductContext = createContext({} as ProductPropsContextProps);

function ProductProvider({ children }: ProductProps) {
  const [product, setProduct] = useState<ProductInterfaces.Product>();
  const [productList, setProductList] = useState<ProductInterfaces.Product[]>(
    []
  );
  const [fetchingProduct, setFetchingProduct] = useState(false);

  const getProducts = async () => {
    try {
      const sneakers = (await api.get("all-products")).data;
      if (sneakers) setProductList(sneakers);
    } catch (e) {
      console.log(e);
    } finally {
      setFetchingProduct(false);
    }
  };

  const selectProduct = useCallback(
    async (productId: string) => {
      try {
        setFetchingProduct(true);
        const response = await getProduct(productId);
        if (response.data) setProduct(response.data);
      } catch (e) {
        console.log(e);
      } finally {
        setFetchingProduct(false);
      }
    },
    [setProduct]
  );

  return (
    <ProductContext.Provider
      value={{
        product,
        setProduct,
        fetchingProduct,
        selectProduct,
        getProducts,
        productList,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

function useProduct() {
  const context = useContext(ProductContext);

  return context;
}

export { ProductProvider, useProduct };
