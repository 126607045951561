import React, { useCallback, useEffect, useState } from "react";
import {
  ActionButton,
  Alert,
  BottomTab,
  ErrorTip,
  Header,
  Wrapper,
} from "../../components";

import InvisibleIcon from "../../assets/invisible.svg";
import VisibleIcon from "../../assets/visible.svg";

import {
  ClickableText,
  Container,
  Description,
  Form,
  Input,
  SubTitle,
  SuccessContainer,
  Title,
} from "./styles";
import { useNavigate } from "react-router-dom";

import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { validatePassword } from "../../utils";

const EditProfilePassword = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visibleOldPassword, setVisibleOldPassword] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const filledFields =
    !!oldPassword.length && !!password.length && !!confirmPassword.length;

  const handleEditOldPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  const handleEditPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleEditConfirmPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const toggleOldPasswordVisibility = () => {
    setVisibleOldPassword((visibility) => !visibility);
  };

  const togglePasswordVisibility = () => {
    setVisiblePassword((visibility) => !visibility);
  };

  const toggleConfirmPasswordVisibility = () => {
    setVisibleConfirmPassword((visibility) => !visibility);
  };

  const navigateToForgotPassword = () => {
    navigate("/forgot-password");
  };

  const navigateBack = () => {
    navigate("/account/edit");
  };

  const handleAlert = (message: string) => {
    setAlertMessage(message);
    setShowAlert(true);

    setTimeout(() => setShowAlert(false), 3000);
  };

  const passwordValidation = (): boolean => {
    if (password !== confirmPassword) {
      handleAlert("The passwords don't match");
      return false;
    }

    if (password === oldPassword) {
      handleAlert("The old and new password need to be different");
      return false;
    }

    const validRules = validatePassword(password);
    if (!validRules) {
      handleAlert("Password doesn't meet security requirements");
      return false;
    }

    return true;
  };

  const handleNewPassword = async () => {
    const validPassword = passwordValidation();

    if (user && user.email && validPassword) {
      const credentials = EmailAuthProvider.credential(user.email, oldPassword);
      reauthenticateWithCredential(user, credentials)
        .then(() => {
          updatePassword(user, password)
            .then(() => {
              setSubmitted(true);
            })
            .catch((error) => {
              const message =
                error.message.split(": ")[1].split("(auth")[0] ||
                "Something went wrong";
              handleAlert(message);
            });
        })
        .catch((error) => {
          if (String(error.message).includes("auth/wrong-password")) {
            const message = "Invalid password";
            handleAlert(message);
          } else {
            const message =
              error.message.split(": ")[1].split("(auth")[0] ||
              "Something went wrong";
            handleAlert(message);
          }
        });
    }
  };

  return (
    <Wrapper>
      <Header back />

      {submitted ? (
        <Container>
          <SuccessContainer>
            <SubTitle>Password changed!</SubTitle>

            <Description>
              Your password has been changed successfully
            </Description>
          </SuccessContainer>
          <ActionButton onClick={navigateBack}>Okay</ActionButton>
        </Container>
      ) : (
        <Container>
          <div>
            <Title>Change password</Title>

            <Description>
              Your password should be at least 8 characters and use a
              combination of upper and lower case letters, numbers, and special
              symbols (!@$%)
            </Description>

            <Form onSubmit={handleNewPassword}>
              <Input>
                <input
                  type={visibleOldPassword ? "text" : "password"}
                  placeholder="Current password"
                  onChange={handleEditOldPassword}
                  value={oldPassword}
                />
                <img
                  src={visibleOldPassword ? VisibleIcon : InvisibleIcon}
                  alt=""
                  onClick={toggleOldPasswordVisibility}
                />
              </Input>
              <Input>
                <input
                  type={visiblePassword ? "text" : "password"}
                  placeholder="New password"
                  onChange={handleEditPassword}
                  value={password}
                />
                <img
                  src={visiblePassword ? VisibleIcon : InvisibleIcon}
                  alt=""
                  onClick={togglePasswordVisibility}
                />
              </Input>
              <Input>
                <input
                  type={visibleConfirmPassword ? "text" : "password"}
                  placeholder="Re-type new password"
                  onChange={handleEditConfirmPassword}
                  value={confirmPassword}
                />
                <img
                  src={visibleConfirmPassword ? VisibleIcon : InvisibleIcon}
                  alt=""
                  onClick={toggleConfirmPasswordVisibility}
                />
              </Input>
            </Form>

            <ClickableText onClick={navigateToForgotPassword}>
              Forgot your password?
            </ClickableText>
          </div>
          <ActionButton disabled={!filledFields} onClick={handleNewPassword}>
            Change Password
          </ActionButton>
        </Container>
      )}

      <ErrorTip visible={showAlert} message={alertMessage} />

      <BottomTab />
    </Wrapper>
  );
};

export default EditProfilePassword;
