import styled from "styled-components";
import { Tooltip } from "react-tooltip";

interface ActionButtonProps {
  largeImage?: boolean;
}

interface SwitchButtonProps {
  visible?: boolean;
}

interface AlertContainerProps {
  visible: boolean;
}

interface MiniatureProps {
  active: boolean;
}

interface StyledImageProps {
  show: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: #222222;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  height: 100%;
  overflow: hidden;

  border-radius: 8px;

  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 24px;

  /* position: relative; */
`;

export const LargeImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 28px;
  margin-right: 8px;
`;

export const StyledImage = styled.img<StyledImageProps>`
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: 0.5s;

  position: absolute;
  bottom: 0;
  top: 0;

  border-radius: 8px;

  opacity: ${({ show }) => (show ? 1 : 0)};

  cursor: pointer;
  z-index: 1;
`;

export const MiniatureContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  min-width: 64px;
  min-height: 48px;
  padding: 4px;
  margin-left: 16px;

  gap: 16px;
`;

export const Miniature = styled.img<MiniatureProps>`
  width: 48px;
  height: 48px;

  border-radius: 4px;
  object-fit: contain;

  opacity: ${({ active }) => (active ? 1 : 0.25)};
  box-shadow: ${({ active }) => active && "0 0 0 4px #fff"};
  transition: 0.5s;

  cursor: pointer;
`;

export const SwitchImageLeftButton = styled.button<SwitchButtonProps>`
  width: 40px;
  height: 40px;

  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin-right: auto;

  opacity: 0.5;
  background: transparent;
  visibility: ${({ visible }) => !visible && "hidden"};

  transition: 0.25s;

  img {
    transform: rotate(180deg);

    width: 12px;
    height: 20px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const SwitchImageRightButton = styled.button<SwitchButtonProps>`
  width: 40px;
  height: 40px;

  border-radius: 100px;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.5;
  background: transparent;

  margin-left: auto;

  visibility: ${({ visible }) => !visible && "hidden"};

  transition: 0.25s;

  cursor: pointer;

  img {
    width: 12px;
    height: 20px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 0 auto;

  width: 100%;
  max-width: 1200px;

  /* position: relative; */
  overflow: visible;
`;

export const Button = styled.a<ActionButtonProps>`
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 0;

  background: rgba(51, 51, 51, 0.5);
  border-radius: 100px;

  cursor: pointer;

  img {
    width: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
    height: ${({ largeImage }) => (largeImage ? 35 : 20)}px;
  }

  &:hover {
    background: linear-gradient(266.57deg, #7000ff 0%, #6117ff 100%), #000000;
  }
`;

export const CustomTooltip = styled(Tooltip)`
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;

  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  max-width: 200px;
  text-align: center;

  color: #d9d9d9;
  z-index: 2;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 100%;
  flex: 1;
  overflow: hidden;

  background: #191919;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0px;
`;

export const InfoContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 16px;

  overflow: visible;

  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const Info = styled.img`
  width: 25px;
  height: 25px;

  z-index: 2;
`;

export const ImageTitle = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 12px;
  gap: 8px;
  background: rgba(51, 51, 51, 0.5);

  border: 2px solid #ffffff;
  border-radius: 80px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #ffffff;
`;

export const ImageDescription = styled.span`
  font-weight: 400;

  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
  padding: 16px;
  z-index: 2;
`;

export const ImageDescriptionBold = styled.span`
  font-weight: 700;

  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #ffffff;
`;

export const AlertContainer = styled.div<AlertContainerProps>`
  max-width: 112px;
  padding: 10px 14px;

  background: #2c2c2c;
  border-radius: 80px;

  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;

  transition: 0.25s ease-in-out;
  opacity: ${({ visible }) => !visible && 0};
  visibility: ${({ visible }) => !visible && "hidden"};
`;

export const AlertText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`;

export const Spinner = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;

  position: absolute;
  left: 0px;
  right: 0px;
  margin: auto;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const ActionButtonContainer = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 16px 8px;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
`;
