import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";

import {
  Header,
  ShareDropdown,
  ProductHeader,
  ActionButton,
  AsyncImage,
} from "../../components";

import { pathToFile } from "../../utils";

import RegenerateIcon from "../../assets/regenerate.svg";
import LinkIcon from "../../assets/link.svg";
import ShareIcon from "../../assets/share.svg";
import DirectionIcon from "../../assets/chevron-right.svg";

import {
  Container,
  Button,
  CustomTooltip,
  StyledImage,
  SwitchImageLeftButton,
  SwitchImageRightButton,
  ImageContainer,
  ActionButtonsContainer,
  Miniature,
  MiniatureContainer,
  Wrapper,
  ImageTitle,
  InfoContainer,
  AlertContainer,
  AlertText,
  Spinner,
  ActionButtonContainer,
  ImageWrapper,
} from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { getImageUrl, getSavedById, getShareInfo } from "../../services";
import { useProduct, useTryOn } from "../../contexts";

interface ShareInfo {
  displayName?: string;
  collectionName?: string;
  productName?: string;
  productId?: string;
  uid?: string;
}

const FullScreen = () => {
  const { product, fetchingProduct, selectProduct } = useProduct();
  const { setSelectedImages, getSingleResult, imageList, setImageList } =
    useTryOn();
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  const [shareInfo, setShareInfo] = useState<ShareInfo>();
  const [shareVisible, setShareVisible] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [showOriginal, setShowOriginal] = useState<{ [key: number]: boolean }>(
    {}
  );
  const location = useLocation();
  const shareId = new URLSearchParams(location.search).get("id");

  const localShareId = localStorage.getItem("resultId");
  const category =
    new URLSearchParams(location.search).get("category") || "shares";

  const url = `${window.location.href.split("?id")[0]}?id=${
    shareId || localShareId
  }`;

  const tryItYourself = () => {
    if (product) {
      setSelectedImages([]);
      navigate(`/share/${product.id}/start`);
    }
  };

  const handleCopyLink = () => {
    setAlertMessage("Link Copied!");

    setShowAlert(true);

    setTimeout(() => setShowAlert(false), 3000);
    navigator.clipboard.writeText(url);
  };

  const navigateSlide = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        if (index < imageList.length - 1) {
          setIndex((i) => i + 1);
        }
      }
      if (e.key === "ArrowLeft") {
        if (index > 0) {
          setIndex((i) => i - 1);
        }
      }
    },
    [index, imageList.length]
  );

  const navigateToHome = () => {
    navigate(`/try/${product?.id}`);
  };

  const getSharedImages = async () => {
    try {
      if (shareId && category === "shares") {
        const shareData = await getShareInfo(shareId);

        if (shareData) {
          setShareInfo(shareData);
          selectProduct(shareData.productId);
          setImageList(
            shareData.images.map(
              (image: {
                result: string;

                original: string;
              }) => ({
                resultURL: image.result,
                originalURL: image.original,
                result: image.result,
                original: image.original,
                imageIndex: index,
                regenerating: false,
              })
            )
          );
        }
      }

      if (shareId && category === "saved") {
        const shareData = await getSavedById(shareId);

        if (shareData) {
          setShareInfo(shareData);
          selectProduct(shareData.product.id);

          setImageList([
            {
              resultURL: shareData.images.result,
              originalURL: shareData.images.original,
              result: shareData.images.result,
              original: shareData.images.original,
              imageIndex: index,
              regenerating: false,
            },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleShowOriginal = (index: number) => {
    const aux = { ...showOriginal };
    if (aux[index]) {
      aux[index] = !aux[index];
    } else {
      aux[index] = true;
    }
    setShowOriginal({ ...aux });
  };

  const getSingleData = async () => {
    try {
      if (product) {
        const { id } = product;

        await getSingleResult(id, index);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const retryImage = async (
    event: SyntheticEvent<HTMLImageElement, Event>,
    imageName: string
  ) => {
    const response = await getImageUrl(imageName);

    if ((event.nativeEvent.target as HTMLImageElement)?.src) {
      (event.nativeEvent.target as HTMLImageElement).src = response;
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", navigateSlide);

    return () => {
      window.removeEventListener("keydown", navigateSlide);
    };
  }, [navigateSlide]);

  useEffect(() => {
    if (!!shareId) {
      getSharedImages();
    } else {
      const productId = JSON.parse(localStorage.getItem("productId") ?? "");
      console.log(productId);
      if (productId) {
        selectProduct(productId);
      }
      const images: any = [];

      const storedImages = JSON.parse(localStorage.getItem("imageList") ?? "");

      storedImages.forEach((image: any) => {
        images.push({
          result: image.result,
          original: image.original,
          imageIndex: index,
          regenerating: false,
        });
      });

      setImageList(images);
    }
  }, [shareId]);

  useEffect(() => {
    const convertImages = async () => {
      try {
        const images = await Promise.all(
          imageList.map(async (image) => {
            const originalFile = await pathToFile(image.originalURL);

            return originalFile;
          })
        );

        setSelectedImages(images);
      } catch (e) {
        console.log(e);
      }
    };

    convertImages();
  }, [imageList, setSelectedImages]);

  return (
    <Container>
      <Header title="Virtual Try On" />

      <ProductHeader
        fetching={fetchingProduct}
        product={product}
        goBackAction={navigateToHome}
      />

      <ImageContainer onClick={(e) => e.stopPropagation()}>
        <Wrapper onClick={() => handleShowOriginal(index)}>
          <InfoContainer>
            <ImageTitle>{`Outfit ${index + 1}`}</ImageTitle>
          </InfoContainer>

          {!!imageList[index] && !imageList[index]?.regenerating && (
            <ImageWrapper>
              <AsyncImage
                show={!showOriginal[index]}
                src={imageList[index].result}
              />
              <AsyncImage
                show={showOriginal[index]}
                src={imageList[index].original}
              />
            </ImageWrapper>
          )}

          {imageList[index]?.regenerating && <Spinner />}

          {category === "shares" && product && !!shareId && (
            <ActionButtonContainer>
              <ActionButton onClick={tryItYourself}>
                Try it yourself
              </ActionButton>
            </ActionButtonContainer>
          )}
        </Wrapper>

        {imageList.length > 1 && (
          <ActionButtonsContainer>
            <SwitchImageLeftButton
              visible={index > 0}
              onClick={() => setIndex((i) => i - 1)}
            >
              <img src={DirectionIcon} alt="" />
            </SwitchImageLeftButton>

            <MiniatureContainer>
              {imageList.map((image, i) => (
                <Miniature
                  key={`${image.resultURL}`}
                  onClick={() => setIndex(i)}
                  active={index === i}
                  src={image.resultURL}
                />
              ))}
            </MiniatureContainer>

            <SwitchImageRightButton
              visible={index < imageList.length - 1}
              onClick={() => setIndex((i) => i + 1)}
            >
              <img src={DirectionIcon} alt="" />
            </SwitchImageRightButton>
          </ActionButtonsContainer>
        )}

        <ActionButtonsContainer>
          {!shareId && (
            <Button
              onClick={getSingleData}
              data-tooltip-id="result-tooltip"
              data-tooltip-content="Regenerate"
              data-tooltip-place="top"
            >
              <img src={RegenerateIcon} alt="" />
            </Button>
          )}

          <Button
            data-tooltip-id="link-tooltip"
            data-tooltip-content="Copy Link"
            data-tooltip-place="top"
            largeImage
            onClick={handleCopyLink}
          >
            <img src={LinkIcon} alt="" />
            <CustomTooltip id="link-tooltip" />
          </Button>

          <Button
            data-tooltip-id="share-tooltip"
            data-tooltip-content="Share"
            data-tooltip-place="top"
            largeImage
            onClick={() => setShareVisible((value) => !value)}
          >
            <img src={ShareIcon} alt="" />
            <CustomTooltip id="share-tooltip" />
          </Button>
          <ShareDropdown
            onClose={() => setShareVisible(false)}
            visible={shareVisible}
            id={shareId || ""}
            medias={imageList.map((image) => image && image.resultURL)}
            onLinkCopied={handleCopyLink}
          />
        </ActionButtonsContainer>
      </ImageContainer>

      <AlertContainer visible={showAlert}>
        <AlertText>{alertMessage}</AlertText>
      </AlertContainer>
    </Container>
  );
};

export default FullScreen;
