import styled from "styled-components";

interface ProductContainerProps {
  disabled?: boolean;
}

export const ProductContainer = styled.div<ProductContainerProps>`
  width: 100%;
  min-height: 100px;

  padding: 16px 26px;

  display: flex;
  align-items: center;

  background: #1a1a1a;

  cursor: ${({ disabled }) => !disabled && "pointer"};
`;

export const ProductImage = styled.img`
  min-width: 60px;
  width: 60px;
  height: 60px;

  border-radius: 8px;
  object-fit: cover;
`;

export const ProductInfo = styled.div`
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 14px;

  h1,
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
`;

export const ProductCollection = styled.h1`
  color: #fff;

  font-size: 13px;

  font-weight: 700;
  line-height: normal;

  margin: 0;

  color: #ffffff;

  @media (max-width: 399px) {
    font-size: 12px;
  }
`;

export const ProductName = styled.span`
  overflow: visible;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  /* identical to box height */

  padding-top: 8px;

  color: #ffffff;

  @media (max-width: 399px) {
    font-size: 14px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > img {
    height: 20px;
    width: 20px;
  }
`;

export const TrendingIconContainer = styled.div`
  width: 60px;
  height: 60px;

  background: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;

  span {
    font-size: 40px;
  }
`;
