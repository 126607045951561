import styled from "styled-components";

interface RowProps {
  clickable?: boolean;
}

export const Container = styled.div`
  background: #111111;
  width: 100%;
  min-height: 56px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;

  padding-left: 8px;
  margin: 0;
`;

export const CloseButton = styled.img`
  padding-right: 21px;
  cursor: pointer;
`;

export const Row = styled.div<RowProps>`
  display: flex;
  align-items: center;
  padding-left: 16px;
  flex: 1;

  ${({ clickable }) => clickable && `cursor: pointer;`}

  img {
    height: ${({ clickable }) => (clickable ? 20 : 24)}px;
    width: ${({ clickable }) => (clickable ? 20 : 24)}px;
    cursor: pointer;
  }
`;

export const BackIcon = styled.img`
  width: 18px;
  height: 18px;

  transform: rotate(180deg);
`;
