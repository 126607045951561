import styled from "styled-components";

interface AlertContainerProps {
  visible: boolean;
}

export const AlertContainer = styled.div<AlertContainerProps>`
  max-width: 90%;
  padding: 10px 14px;

  background: #2c2c2c;
  border-radius: 80px;

  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: auto;

  display: flex;
  align-items: center;
  text-align: center;

  transition: 0.25s ease-in-out;
  opacity: ${({ visible }) => !visible && 0};
  visibility: ${({ visible }) => !visible && "hidden"};
`;

export const AlertText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;
  margin: auto;

  color: #ffffff;
`;
