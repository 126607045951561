import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Saved } from "../interfaces/TryOn";
import { addToSaved, getSavedByUser, removeFromSaved } from "../services";
import { useUser } from "./UserContext";

import { TryOnInterfaces } from "../interfaces";

interface SavedContextProps {
  savePhoto: ({
    images,
    product,
    shareId,
    shareIndex,
    uid,
  }: TryOnInterfaces.SaveReq) => Promise<void>;
  userSavedPhotos: Saved[];
  removePhoto: ({
    shareId,
    shareIndex,
  }: {
    shareId: string;
    shareIndex: number;
  }) => Promise<void>;
  orderByMostRecent: () => void;
  orderSavedByName: () => void;
  orderByMostTried: (order: "asc" | "desc") => void;
}

const SavedContext = createContext({} as SavedContextProps);

const SavedProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();
  const [userSavedPhotos, setUserSavedPhotos] = useState<Saved[]>([]);
  const collectionMap: { [key: string]: number } = {};

  const savedPhotoExists = ({
    shareId,
    shareIndex,
    uid,
  }: {
    uid: string;
    shareId: string;
    shareIndex: number;
  }) => {
    const exists = userSavedPhotos.some(
      (item) =>
        item.shareId === shareId &&
        item.shareIndex === shareIndex &&
        item.uid === uid
    );

    return exists;
  };

  const getSavedPhoto = ({
    shareId,
    shareIndex,
    uid,
  }: {
    uid: string;
    shareId: string;
    shareIndex: number;
  }) => {
    const exists = userSavedPhotos.find(
      (item) =>
        item.shareId === shareId &&
        item.shareIndex === shareIndex &&
        item.uid === uid
    );

    return exists || null;
  };

  const removePhoto = async ({
    shareId,
    shareIndex,
  }: {
    shareId: string;
    shareIndex: number;
  }) => {
    try {
      if (user) {
        const uid = user.uid;
        const itemExists = getSavedPhoto({ uid, shareId, shareIndex });

        if (itemExists?.id) {
          await removeFromSaved(itemExists.id);

          getUserSavesPhotos(uid);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const savePhoto = async ({
    images,
    product,
    shareId,
    shareIndex,
    uid,
  }: TryOnInterfaces.SaveReq) => {
    try {
      const itemExists = savedPhotoExists({ uid, shareId, shareIndex });

      if (!itemExists) {
        await addToSaved({
          images,
          product,
          shareId,
          shareIndex,
          uid,
        });

        getUserSavesPhotos(uid);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserSavesPhotos = async (uid: string) => {
    try {
      const result = (await getSavedByUser(uid)) as Saved[];
      setUserSavedPhotos(result);
    } catch (e) {
      console.log(e);
    }
  };

  const orderByMostRecent = () => {
    const saved = [...userSavedPhotos];
    saved.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());

    setUserSavedPhotos([...saved]);
  };

  const orderSavedByName = () => {
    const saved = [...userSavedPhotos];
    saved.sort((a, b) =>
      a.product.collection.localeCompare(b.product.collection)
    );

    setUserSavedPhotos([...saved]);
  };

  const orderByMostTried = (order: "asc" | "desc") => {
    userSavedPhotos.forEach((item) => {
      if (collectionMap[item.product.collection]) {
        collectionMap[item.product.collection] =
          collectionMap[item.product.collection] + 1;
      } else {
        collectionMap[item.product.collection] = 1;
      }
    });

    if (order === "asc") {
      const saved = [...userSavedPhotos];
      saved.sort(
        (a, b) =>
          collectionMap[b.product.collection] -
          collectionMap[a.product.collection]
      );

      setUserSavedPhotos([...saved]);
    } else {
      const saved = [...userSavedPhotos];
      saved.sort(
        (a, b) =>
          collectionMap[a.product.collection] -
          collectionMap[b.product.collection]
      );

      setUserSavedPhotos([...saved]);
    }
  };

  useEffect(() => {
    if (user.uid) {
      getUserSavesPhotos(user.uid);
    }
  }, [user.uid]);

  return (
    <SavedContext.Provider
      value={{
        savePhoto,
        userSavedPhotos,
        removePhoto,
        orderSavedByName,
        orderByMostRecent,
        orderByMostTried,
      }}
    >
      {children}
    </SavedContext.Provider>
  );
};

const useSaved = () => {
  const context = useContext(SavedContext);

  return context;
};

export { SavedProvider, useSaved };
