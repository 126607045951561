import styled from "styled-components";

export const Container = styled.div<{ $bgImg?: string }>`
  background-image: url(${(props) => props.$bgImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const ProductContainer = styled.div`
  width: 100%;
  min-height: 112px;

  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);

  padding: 16px 26px;

  display: flex;
  align-items: center;
`;

export const ProductImage = styled.img`
  min-width: 60px;
  width: 60px;
  height: 60px;

  border-radius: 8px;
`;

export const ProductInfo = styled.div`
  padding-left: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1,
  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
`;

export const ProductName = styled.h1`
  color: #fff;

  font-size: 13px;

  font-weight: 700;
  line-height: normal;

  margin: 0;

  color: #ffffff;

  @media (max-width: 399px) {
    font-size: 12px;
  }
`;

export const ProductDescription = styled.span`
  overflow: visible;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  /* identical to box height */

  padding-top: 8px;

  color: #ffffff;

  @media (max-width: 399px) {
    font-size: 14px;
  }
`;

export const Button = styled.button`
  height: 40px;
  width: 100%;

  border-radius: 80px;
  border: 2px solid #fff;
  background: #fff;
  backdrop-filter: blur(2px);
  padding: 6px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  color: #000;
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;

  img {
    filter: invert(100%);

    height: 12px;
    width: 12px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h1`
  background: #000;

  color: #fff;
  text-align: center;
  font-size: 28px;
  font-weight: 800;
  line-height: normal;

  margin-bottom: 42px;
  padding: 0px 4px;
`;

export const ButtonContainer = styled.div`
  width: 124px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const FlashingButton = styled.button`
  @keyframes flash {
    0% {
      border: 2px solid #fe0501;
      background: #f00;
      color: #ffc704;
    }
    50% {
      border: 2px solid #ffc500;
      background: #ffc704;
      color: #f00;
    }
  }
  animation: flash 1.5s steps(1) infinite;

  height: 40px;
  width: 100%;

  border-radius: 80px;
  border: 2px solid #fe0501;
  background: #f00;
  backdrop-filter: blur(2px);
  padding: 6px 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  color: #ffc704;
  text-align: center;
  font-size: 14px;
  font-weight: 800;
  line-height: normal;
`;

export const CarouselContainer = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;

  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  padding-bottom: 7px;
`;

export const CarouselImage = styled.img`
  min-height: 105px;
  max-height: 105px;
  min-width: 84px;
  max-width: 84px;

  object-fit: contain;
`;
