import styled from "styled-components";

interface ImagesContainerProps {
  empty: boolean;
}

interface SelectImageButtonProps {
  checked: boolean;
}

export const Container = styled.div`
  width: 100%;
  overflow: visible;
`;

export const Frame = styled.div`
  position: relative;

  overflow: visible;

  width: 108px;
  height: 108px;

  background: rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  transition: 0.25s;

  display: flex;

  :hover {
    background: rgba(0, 0, 0, 0.5);
  }
`;

export const ImagesContainer = styled.div<ImagesContainerProps>`
  position: relative;
  overflow: visible;
  width: 100%;

  display: grid;
  justify-content: space-between;
  gap: 2px;

  grid-template-columns: repeat(auto-fit, 108px);

  ${Frame} {
    opacity: ${({ empty }) => empty && 0.25};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-bottom: 16px;
`;

export const DarkButton = styled.button`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border: none;
  background: transparent;
  /* identical to box height */

  text-decoration-line: underline;
  text-align: center;

  color: #ffffff;

  opacity: 0.5;
`;

export const InputPlaceholder = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding-top: 8px;

  display: block;

  text-align: center;

  color: #ffffff;

  opacity: 0.75;

  overflow: visible;
`;

export const SelectImageButton = styled.button<SelectImageButtonProps>`
  width: 16px;
  height: 16px;
  border-radius: 16px;

  position: absolute;
  top: -8px;
  right: -8px;

  background: ${({ checked }) => (checked ? "#2DCB57" : "#c1c1c1")};

  border: 2px solid ${({ checked }) => (checked ? "#2DCB57" : "#ffffff")};

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    min-width: 10px;
    min-height: 10px;
    background: transparent;
  }
`;

export const GalleryImage = styled.img`
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;

  margin: auto;

  background: rgba(0, 0, 0, 0.25);

  border-radius: 8px;

  cursor: pointer;
`;

export const UserImagesTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 8px;

  overflow: visible;

  button {
    opacity: 0.5;
    border: 2px solid #ffffff;
    border-radius: 80px;
    padding: 5px 10px;
    gap: 8px;
    background: transparent;

    font-weight: 800;
    font-size: 10px;
    line-height: 12px;

    color: #ffffff;
  }
`;

export const DarkTitle = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;

  opacity: 0.5;

  overflow: visible;
`;
