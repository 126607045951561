import React, { useState } from "react";
import {
  ActionButton,
  BottomTab,
  ErrorTip,
  Header,
  Wrapper,
} from "../../components";

import UserIcon from "../../assets/user-default.png";
import CheckIcon from "../../assets/check.svg";
import ChevronIcon from "../../assets/chevron-right.svg";

import {
  ButtonsContainer,
  Container,
  FormField,
  Input,
  InputContainer,
  Label,
  Logout,
  NavigateButton,
  PictureFrame,
  ProfileIcon,
  ProfilePicture,
  Spinner,
} from "./styles";
import { useUser } from "../../contexts";
import { validateEmail } from "../../utils";
import {
  getUserByUsername,
  setUserAvatarStorage,
  setUserDoc,
} from "../../services";
import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import { getAuth } from "firebase/auth";

const EditProfile: React.FC = () => {
  const { user, userAvatar, setUserAvatar, setUser, logout } = useUser();
  const navigate = useNavigate();
  const [changeHappened, setChangeHappened] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [checkingUsername, setCheckingUsername] = useState(false);
  const [displayName, setDisplayName] = useState(user.displayName);
  const [username, setUsername] = useState(user.username);
  const [email, setEmail] = useState(user.email || "");
  const [avatarFile, setAvatarFile] = useState<File>();
  const [avatar, setAvatar] = useState<string>(userAvatar || "");
  const [inputError, setInputError] = useState({
    displayName: false,
    email: false,
    username: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [errorTipVisible, setErrorTipVisible] = useState(false);

  const auth = getAuth();

  const imageOptions = {
    maxWidthOrHeight: 512,
  };

  const showError = (message: string) => {
    setErrorTipVisible(true);
    setErrorMessage(message);

    setTimeout(() => setErrorTipVisible(false), 5000);
  };

  const checkEmail = () => {
    const validEmail = validateEmail(email);

    if (!validEmail) {
      showError("Please enter a valid email address");
      setInputError((prevState) => ({ ...prevState, email: true }));
    } else {
      setInputError((prevState) => ({ ...prevState, email: false }));
    }
  };

  const checkUsername = async (newUsername: string) => {
    setCheckingUsername(true);
    if (!newUsername) {
      showError("Please enter a username");
      setInputError((prevState) => ({ ...prevState, username: true }));
      return;
    }

    const usernameExists = await getUserByUsername(newUsername);

    if (usernameExists && usernameExists.uid !== user.uid) {
      showError(
        "That username has already been taken. Please use a different one and try again."
      );
      setInputError((prevState) => ({ ...prevState, username: true }));
    } else {
      setInputError((prevState) => ({ ...prevState, username: false }));
    }
    setCheckingUsername(false);
  };

  const checkDisplayName = () => {
    if (!displayName) {
      showError("Please enter a name");
      setInputError((prevState) => ({ ...prevState, displayName: true }));
    } else {
      setInputError((prevState) => ({ ...prevState, displayName: false }));
    }
  };

  const changeDisplayName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayName(e.target.value);
    setChangeHappened(true);
  };

  const changeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);

    checkUsername(e.target.value);
    setChangeHappened(true);
  };

  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setChangeHappened(true);
  };

  const saveChanges = async () => {
    try {
      setFetching(true);
      if (avatarFile) {
        await setUserAvatarStorage(avatarFile, user.uid);
        setAvatar(URL.createObjectURL(avatarFile));
        setUserAvatar(URL.createObjectURL(avatarFile));
      }
      await setUserDoc({
        displayName,
        username,
        email,
        uid: user.uid,
      });

      setUser({
        displayName,
        username,
        email,
        uid: user.uid,
      });

      setFetching(false);
      navigate("/account");
    } catch (e) {
      setFetching(false);
      console.log(e);
    }
  };

  const changeAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      const compressedFile = await imageCompression(
        e.target.files?.[0],
        imageOptions
      );
      setAvatarFile(compressedFile);
      setAvatar(URL.createObjectURL(compressedFile));
      setChangeHappened(true);
    }
  };

  const navigateToChangePassword = () => {
    navigate("/account/edit/password");
  };

  return (
    <Wrapper>
      <Header title="Account" back />

      <Container>
        <div>
          <PictureFrame>
            <input
              type="file"
              name="avatar"
              accept="image/png image/jpg image/jpeg"
              onChange={changeAvatar}
            />

            {avatar ? (
              <ProfilePicture src={avatar ? avatar : UserIcon} alt="" />
            ) : (
              <ProfileIcon src={UserIcon} alt="" />
            )}
          </PictureFrame>

          <form action="">
            <FormField>
              <Label>Name</Label>
              <InputContainer error={inputError.displayName}>
                <Input
                  defaultValue={user.displayName || ""}
                  onChange={changeDisplayName}
                  onBlur={checkDisplayName}
                />
              </InputContainer>
            </FormField>
            <FormField>
              <Label>Username</Label>
              <InputContainer error={inputError.username}>
                <Input
                  defaultValue={user.username || ""}
                  onChange={changeUsername}
                />
                {checkingUsername && <Spinner />}
                {!inputError.username && !checkingUsername && (
                  <img src={CheckIcon} alt="" />
                )}
              </InputContainer>
            </FormField>
            <FormField>
              <Label>Email</Label>
              <InputContainer error={inputError.email}>
                <Input
                  defaultValue={user.email || ""}
                  onChange={changeEmail}
                  onBlur={checkEmail}
                />
              </InputContainer>
            </FormField>
            {auth.currentUser?.providerData.some(
              (provider) => provider.providerId === "password"
            ) && (
              <FormField>
                <Label>Password</Label>
                <NavigateButton onClick={navigateToChangePassword}>
                  Change Password <img src={ChevronIcon} alt="" />
                </NavigateButton>
              </FormField>
            )}
          </form>
        </div>

        <ButtonsContainer>
          <ActionButton
            loading={fetching}
            disabled={
              inputError.displayName ||
              inputError.username ||
              inputError.email ||
              !changeHappened
            }
            onClick={saveChanges}
          >
            Save & Update
          </ActionButton>
          <Logout onClick={logout}>Logout</Logout>
        </ButtonsContainer>
      </Container>

      <ErrorTip visible={errorTipVisible} message={errorMessage} />

      <BottomTab />
    </Wrapper>
  );
};

export default EditProfile;
