import React from "react";

import {
  ProductCollection,
  ProductContainer,
  ProductImage,
  ProductInfo,
  ProductName,
  Row,
  TrendingIconContainer,
  Wrapper,
} from "./styles";

import { ProductInterfaces } from "../../../interfaces";

interface ProductHeaderProps {
  product: ProductInterfaces.Product | null;
}

const ProductHeader = ({ product }: ProductHeaderProps) => {
  return (
    <ProductContainer>
      <Wrapper>
        <Row>
          {product ? (
            <ProductImage src={product?.icon} alt="product banner" />
          ) : (
            <TrendingIconContainer>
              <span>🔥</span>
            </TrendingIconContainer>
          )}
          <ProductInfo>
            <ProductCollection>
              {product ? product?.collection : "Trending now"}
            </ProductCollection>
            <ProductName data-testid="product-name">
              {product ? product?.name : "Select a pair to try on:"}
            </ProductName>
          </ProductInfo>
        </Row>
      </Wrapper>
    </ProductContainer>
  );
};

export default ProductHeader;
