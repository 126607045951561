import { api } from "./api";
import {
  serverTimestamp,
  collection,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";

import { firebaseStorage, firestore } from "./index";
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";

export const uploadImage = async (file: any, name: string) => {
  const response = await api.get("images/presigned-upload", {
    params: {
      name: name,
    },
  });
  const url = response.data.url;
  const uploadResponse = await api.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });

  return uploadResponse;
};

export const getImageUrl = async (name: string) => {
  const response = await api.get("images/presigned-url", {
    params: {
      name: name,
    },
  });
  // console.log("dl from " + response.data.url);
  return response.data.url;
};

export const postSneakerImage = async (file: any, productId: string) => {
  const response = await api.post(
    "wear/sneakers",
    { image_url: file, product_id: productId },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return response;
};

export const saveTryOnImages = async ({
  uid,
  displayName,
  productId,
  productName,
  collectionName,
  modifierId,
  modifierName,
  images,
}: {
  uid: string;
  displayName: string;
  productId: string;
  productName: string;
  collectionName: string;
  modifierId?: number | null;
  modifierName?: string | null;
  images?: {
    result: string;
    original: string;
  }[];
}) => {
  return await addDoc(collection(firestore, "shares"), {
    uid,
    displayName,
    productId,
    productName,
    collectionName,
    createdAt: serverTimestamp(),
    modifierId: modifierId ?? null,
    modifierName: modifierName ?? null,
    images,
  });
};
export const updateTryOnImages = async ({
  documentId,

  images,
}: {
  documentId: string;

  images?: {
    result: string;
    original: string;
  }[];
}) => {
  return await updateDoc(doc(firestore, `shares/${documentId}`), {
    images,
  });
};

export const storeImagesResult = async ({
  original,
  result,
  id,
  index,
}: {
  original: Blob;
  result: File | Blob;
  id: string;
  index: number;
}) => {
  const storageRefOriginal = ref(
    firebaseStorage,
    `shares/${id}/${index}/original`
  );
  const storageRefResult = ref(firebaseStorage, `shares/${id}/${index}/result`);

  await uploadBytes(storageRefOriginal, original);
  await uploadBytes(storageRefResult, result);
};

export const listImagesResultLength = async (id: string) => {
  const resultRef = ref(firebaseStorage, `shares/${id}`);

  return listAll(resultRef).then((res) => res.prefixes.length);
};
export const getImagesResult = async (id: string, index: number) => {
  const originalImage = ref(firebaseStorage, `shares/${id}/${index}/original`);
  const resultImage = ref(firebaseStorage, `shares/${id}/${index}/result`);

  const original = await getDownloadURL(originalImage)
    .then((url) => url)
    .catch(() => "");

  const result = await getDownloadURL(resultImage)
    .then((url) => url)
    .catch(() => "");

  return { original, result };
};

export const storeProfileTryOnPhoto = async ({
  uid,
  fileName,
  image,
}: {
  uid: string;
  fileName: string;
  image: File | Blob;
}) => {
  const storageRef = ref(firebaseStorage, `users/${uid}/photos/${fileName}`);

  await uploadBytes(storageRef, image);
};

export const removeProfileTryOnPhoto = async ({
  uid,
  fileName,
}: {
  uid: string;
  fileName: string;
}) => {
  const storageRef = ref(firebaseStorage, `users/${uid}/photos/${fileName}`);

  await deleteObject(storageRef);
};

export const listProfileTryOnPhotos = async (uid: string) => {
  const listRef = ref(firebaseStorage, `users/${uid}/photos`);

  return await listAll(listRef);
};

export const getProfileTryOnPhotos = async (path: string) => {
  const imageRef = ref(firebaseStorage, path);

  return await getDownloadURL(imageRef);
};

export const getRandomTryOnImage = async ({
  imageUrl,
  productId,
  modifierId,
}: {
  imageUrl: string;
  productId: string;
  modifierId?: number;
}) => {
  const response = await api.post("/wear/modified-boots/", {
    image_url: imageUrl,
    product_id: productId,
    modifier_id: modifierId,
  });

  return response;
};
