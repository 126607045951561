import { Link } from "react-router-dom";
import styled from "styled-components";

interface StyledLinkProps {
  active: boolean;
}

export const Container = styled.nav`
  width: 100%;

  min-height: 60px;
  height: 60px;
  min-height: 60px;

  background: #222;

  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.25);

  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const StyledLink = styled.button<StyledLinkProps>`
  text-decoration: none;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 60px;
  border-top: 2px solid ${({ active }) => (active ? "#7000FF" : "transparent")};
  opacity: ${({ active }) => (active ? 1 : 0.25)};
  display: flex;
  justify-content: center;
  align-items: baseline;

  padding: 0;

  svg {
    height: 100%;
  }
`;
