import React, { useCallback, useEffect, useState } from "react";
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  getAuth,
} from "firebase/auth";

import { ActionButton, Alert, Header, Wrapper } from "../../components";

import {
  Container,
  Description,
  Input,
  InputContainer,
  Label,
  SubmitContainer,
  Title,
} from "./styles";

import InvisibleIcon from "../../assets/invisible.svg";
import VisibleIcon from "../../assets/visible.svg";
import { useNavigate } from "react-router-dom";
import { validatePassword } from "../../utils";

const ChangePassword = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const auth = getAuth();

  const validPassword = password.length && confirmPassword.length;
  const urlParams = new URLSearchParams(window.location.search);
  const actionCode = urlParams.get("oobCode");

  const handleEditPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleEditConfirmPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setVisiblePassword((visibility) => !visibility);
  };
  const toggleConfirmPasswordVisibility = () => {
    setVisibleConfirmPassword((visibility) => !visibility);
  };

  const passwordValidation = (): boolean => {
    if (password !== confirmPassword) {
      handleAlert("The passwords don't match");
      return false;
    }

    const validRules = validatePassword(password);
    if (!validRules) {
      handleAlert("Password doesn't meet security requirements");
      return false;
    }

    return true;
  };

  const handleResetPassword = (actionCode: string) => {
    const validPassword = passwordValidation();

    if (validPassword) {
      verifyPasswordResetCode(auth, actionCode)
        .then(() => {
          const newPassword = password;

          confirmPasswordReset(auth, actionCode, newPassword)
            .then((resp) => {
              setSubmitted(true);
            })
            .catch((error) => {
              handleAlert("Something went wrong. Try again later.");
            });
        })
        .catch((error) => {
          handleAlert("Something went wrong. Try again later.");
        });
    }
  };

  const handleAlert = (message: string) => {
    setAlertMessage(message);
    setShowAlert(true);

    setTimeout(() => setShowAlert(false), 3000);
  };

  const navigateBack = () => {
    navigate("/signin/email");
  };

  return (
    <Wrapper>
      <Header />
      {submitted ? (
        <Container>
          <Title>Password changed!</Title>
          <Description>Your password has been changed successfully</Description>

          <SubmitContainer>
            <ActionButton onClick={navigateBack}>Okay</ActionButton>
          </SubmitContainer>
        </Container>
      ) : (
        <Container>
          <Title>Pick a new password</Title>

          <SubmitContainer>
            <InputContainer>
              <Label>New password</Label>
              <Input>
                <input
                  type={visiblePassword ? "text" : "password"}
                  placeholder="New password"
                  onChange={handleEditPassword}
                  value={password}
                />
                <img
                  src={visiblePassword ? VisibleIcon : InvisibleIcon}
                  alt=""
                  onClick={togglePasswordVisibility}
                />
              </Input>
            </InputContainer>

            <InputContainer>
              <Label>Re-enter password</Label>
              <Input>
                <input
                  type={visibleConfirmPassword ? "text" : "password"}
                  placeholder="Re-enter new password"
                  onChange={handleEditConfirmPassword}
                  value={confirmPassword}
                />
                <img
                  src={visibleConfirmPassword ? VisibleIcon : InvisibleIcon}
                  alt=""
                  onClick={toggleConfirmPasswordVisibility}
                />
              </Input>
            </InputContainer>

            <ActionButton
              disabled={!validPassword && !actionCode}
              onClick={() => actionCode && handleResetPassword(actionCode)}
            >
              Change password
            </ActionButton>
          </SubmitContainer>
        </Container>
      )}

      <Alert visible={showAlert} message={alertMessage} />
    </Wrapper>
  );
};

export default ChangePassword;
