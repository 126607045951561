import React from "react";
import { ActionButton } from "..";

import { Container, Description, ModalWrapper, Title } from "./styles";

interface ModalProps {
  visible: boolean;
  title: string;
  description: string;
  buttonTitle: string;
  onClickButton: () => void;
}

const Modal = ({ visible, onClickButton }: ModalProps) => {
  return (
    <ModalWrapper visible={visible}>
      <Container>
        <h1>🎉</h1>
        <Title>Looking good!</Title>
        <Description>
          You’re all set. Generate, save, and share your outfits with Stylar.
          Have fun exploring and trying new things on – the world is your
          oyster!
        </Description>

        <ActionButton onClick={onClickButton}>View results</ActionButton>
      </Container>
    </ModalWrapper>
  );
};

export default Modal;
