import React from "react";

import {
  ProductCollection,
  ProductContainer,
  ProductImage,
  ProductInfo,
  ProductName,
  Row,
  Wrapper,
} from "./styles";
import Spinner from "../Spinner";
import { ProductInterfaces } from "../../interfaces";

import DirectionIcon from "../../assets/chevron-right.svg";

interface ProductHeaderProps {
  fetching: boolean;
  product: ProductInterfaces.Product | undefined;
  customName?: string;
  goBackAction?: () => void;
  disabled?: boolean;
}

const ProductHeader = ({
  fetching,
  product,
  customName,
  goBackAction,
  disabled,
}: ProductHeaderProps) => {
  const handleProductName = () => {
    if (customName) {
      const regex = /\(([^)]+)\)/;

      return product?.name.replace(regex, `(${customName})`);
    } else {
      return product?.name;
    }
  };

  const handleAction = () => {
    if (!disabled && goBackAction) {
      goBackAction();
    }
  };

  return (
    <ProductContainer
      disabled={disabled || !goBackAction}
      onClick={handleAction}
      data-testid="container"
    >
      {fetching || !product ? (
        <Spinner data-testid={"spinner"} />
      ) : (
        <Wrapper>
          <Row>
            <ProductImage src={product?.icon} alt="product banner" />
            <ProductInfo>
              <ProductCollection>{product?.collection}</ProductCollection>
              <ProductName data-testid="product-name">
                {handleProductName()}
              </ProductName>
            </ProductInfo>
          </Row>
          {!!goBackAction && !disabled && (
            <img src={DirectionIcon} alt="chevron right" />
          )}
        </Wrapper>
      )}
    </ProductContainer>
  );
};

export default ProductHeader;
